import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { TextField, Divider, Button, IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: [[ '1em' ]],
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginBottom: '2em'
    }
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
  }
});

export function formatPriceStringForInputValue(string){
  const dividedBy100 = Number(string) / 100;
  return dividedBy100.toString()
}

export function formatPriceInputValueIntoStateString(string) {
  const times100 = Number(string) * 100;
  return times100.toString()
}

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={true}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

export default function ItemEdit({item, saveEdit, removeItemIdFromEdit, deleteItem, mergeAsExtra}) {
  const classes = useStyles();
  const [itemToEdit, setItem] = useState({
    id: '',
    name: '',
    qty: '',
    price: '',
    extras: {
      descriptionsById: {},
      descriptionIds: [],
      chargesById: {},
      chargeIds: [],
    }
  });


  // const memoizedItem = useMemo(() => {

  // }, [item])

  useEffect(() => {
    setItem({...item})
  }, [item])

  const handleAddDescription = useCallback(() => {
    setItem(state => {
      const newId = uuidv4();
      const newState = {
        descriptionsById: {
          ...state.extras.descriptionsById,
          [newId]: '',
        },
        descriptionIds: [ ...state.extras.descriptionIds, newId ],
      }
      return {
        ...state,
        extras: {
          ...state.extras,
          ...newState,
        },
      };
    })
  }, [setItem])

  const handleAddCharge = useCallback(() => {
    setItem(state => {
      const newId = uuidv4();
      const newState = {
        chargesById: {
          ...state.extras.chargesById,
          [newId]: {
            chargeDescription: '',
            chargePrice: '',
          },
        },
        chargeIds: [ ...state.extras.chargeIds, newId ],
      }
      return {
        ...state,
        extras: {
          ...state.extras,
          ...newState,
        },
      };
    })
  }, [setItem])

  const handleRemoveCharge = useCallback((id) => {
    setItem(state => {
      const newChargeIds = state.extras.chargeIds.filter(chargeId => chargeId !== id);
      const newChargesById = newChargeIds.reduce((result, id) => {
        return {
          ...result,
          [id]: state.extras.chargesById[id],
        };
      }, {})
      return {
        ...state,
        extras: {
          ...state.extras,
          chargeIds: newChargeIds,
          chargesById: newChargesById,
        },
      }
    })
  }, [setItem])

  const handleRemoveDescription = useCallback((id) => {
    setItem(state => {
      const newDescriptionIds = state.extras.descriptionIds.filter(chargeId => chargeId !== id);
      const newDescriptionsById = newDescriptionIds.reduce((result, id) => {
        return {
          ...result,
          [id]: state.extras.descriptionsById[id],
        };
      }, {})
      return {
        ...state,
        extras: {
          ...state.extras,
          descriptionIds: newDescriptionIds,
          descriptionsById: newDescriptionsById,
        },
      }
    })
  }, [setItem])

  const handleNameChange = useCallback((event) => {
    event.persist();
    const { value } = event.target;
    console.log('EVENT ', value)
    setItem( item => ({
      ...item,
      name: event.target.value
    }))
  }, [setItem])

  const handlePriceChange = useCallback((e) => {
    const { value } = e.target;
    setItem(item => ({
      ...item,
      price: formatPriceInputValueIntoStateString(value),
    }))
  }, [setItem])

  const handleMakePriceNeg = useCallback(() => {
    setItem(itemToEdit => {
      const isEmpty = itemToEdit.price === '';
      const splitStr = itemToEdit.price.split('');
      const isNeg = splitStr[0] === '-';
      const newString = isNeg || isEmpty ? itemToEdit.price : ['-', ...splitStr].join('');
      return {
        ...itemToEdit,
        price: newString,
      }
    })
  }, [setItem])

  const handleMakePricePos = useCallback(() => {
    setItem(itemToEdit => {
      const isEmpty = itemToEdit.price === '';
      const splitStr = itemToEdit.price.split('');
      const isNeg = splitStr[0] === '-';
      const newString = !isNeg || isEmpty ? itemToEdit.price : splitStr.filter(char => char !== '-').join('');
      return {
        ...itemToEdit,
        price: newString,
      }
    })
  }, [setItem])
  
  const handleQtyChange = useCallback((e) => {
    const { value } = e.target;
    const isNum = value === '' || /^\d+$/.test(value)
    console.log(typeof Number(value))
    // console.log('VALUE ', value)
    setItem(item => ({
      ...item,
      qty: isNum ? value : item.qty,
    }))
  }, [setItem])

  const handleDescriptionChange = useCallback((event, id) => {
    event.persist();
    const { value } = event.target;
    setItem( state => ({
      ...state,
      extras: {
        ...state.extras,
        descriptionsById: {
          ...state.extras.descriptionsById,
          [id]: value,
        },
      }
    }))
  }, [setItem])

  const handleChargeDescriptionChange = useCallback((event, id) => {
    event.persist();
    const { value } = event.target;
    setItem( state => ({
      ...state,
      extras: {
        ...state.extras,
        chargesById: {
          ...state.extras.chargesById,
          [id]: {
            ...state.extras.chargesById[id],
            chargeDescription: value,
          },
        },
      }
    }))
  }, [setItem])

  const handleChargePriceChange = useCallback((event, id) => {
    const { value } = event.target;
    setItem( state => ({
      ...state,
      extras: {
        ...state.extras,
        chargesById: {
          ...state.extras.chargesById,
          [id]: {
            ...state.extras.chargesById[id],
            chargePrice: formatPriceInputValueIntoStateString(value),
          },
        },
      }
    }))
  }, [setItem])

  const handleMakeChargePriceNeg = useCallback((id) => {
    setItem( state => {
      const price = state.extras.chargesById[id].chargePrice;
      const isEmpty = state.extras.chargesById[id].chargePrice === '';
      const splitStr = price.split('');
      const isNeg = splitStr[0] === '-';
      const newString = isNeg || isEmpty ? price : ['-', ...splitStr].join('');
      return {
        ...state,
        extras: {
          ...state.extras,
          chargesById: {
            ...state.extras.chargesById,
            [id]: {
              ...state.extras.chargesById[id],
              chargePrice: newString,
            },
          },
        }
      }
    })
  }, [setItem])
  
  const handleMakeChargePricePos = useCallback((id) => {
    setItem( state => {
      const price = state.extras.chargesById[id].chargePrice;
      const isEmpty = state.extras.chargesById[id].chargePrice === '';
      const splitStr = price.split('');
      const isNeg = splitStr[0] === '-';
      const newString = !isNeg || isEmpty ? price : splitStr.filter(char => char !== '-').join('');
      return {
        ...state,
        extras: {
          ...state.extras,
          chargesById: {
            ...state.extras.chargesById,
            [id]: {
              ...state.extras.chargesById[id],
              chargePrice: newString,
            },
          },
        }
      }
    })
  }, [setItem])
  
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div className={classes.row} tabIndex={0}>
      <TextField label="Name" variant="outlined" value={itemToEdit.name} onChange={handleNameChange} />
      <TextField label="Qty" variant="outlined" value={itemToEdit.qty} onChange={handleQtyChange} />
      <FormControl size="small" className={classes.priceRow} variant="outlined">
        <Button onClick={handleMakePriceNeg}><RemoveIcon /> </Button>
        <Button onClick={handleMakePricePos}><AddIcon /> </Button>
        <InputLabel shrink htmlFor="outlined-adornment-amount">Price</InputLabel>
        <OutlinedInput
          value={formatPriceStringForInputValue(itemToEdit.price)}
          onChange={handlePriceChange}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          labelWidth={60}
          inputComponent={NumberFormatCustom}
        />
      </FormControl>
      {
        itemToEdit.extras.descriptionIds.length > 0 && itemToEdit.extras.descriptionIds.map(descId => (
          <div key={descId}>
            <TextField
              label="description"
              variant="outlined"
              value={itemToEdit.extras.descriptionsById[descId]}
              onChange={(e) => handleDescriptionChange(e, descId)}
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={() => handleRemoveDescription(descId)}
            >
              Delete Description
            </Button>
          </div>
        ))
      }
      {
        itemToEdit.extras.chargeIds.length > 0 && itemToEdit.extras.chargeIds.map(charId => (
          <div key={charId}>  
            <TextField
              label="extra charge description"
              variant="outlined"
              value={itemToEdit.extras.chargesById[charId].chargeDescription}
              onChange={(e) => handleChargeDescriptionChange(e, charId)}
            />
            <FormControl size="small" className={classes.priceRow} variant="outlined">
              <Button onClick={() => handleMakeChargePriceNeg(charId)}><RemoveIcon /> </Button>
              <Button onClick={() => handleMakeChargePricePos(charId)}><AddIcon /> </Button>
              <InputLabel shrink htmlFor="outlined-adornment-amount">Price</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                value={formatPriceStringForInputValue(itemToEdit.extras.chargesById[charId].chargePrice)}
                onChange={(e) => handleChargePriceChange(e, charId)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                labelWidth={60}
                inputComponent={NumberFormatCustom}
              />
            </FormControl>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={() => handleRemoveCharge(charId)}
            >
              Delete Extra Charge
            </Button>
          </div>
        ))
      }
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={() => mergeAsExtra(item.id, itemToEdit)}
      >
        Merge with Previous Item As SubItem
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleAddDescription}
      >
        Add Description
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={handleAddCharge}
      >
        Add Extra Charge
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={() =>{
          setItem({...item})
          removeItemIdFromEdit(item.id)
        }}
      >
        Cancel Changes
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={() =>saveEdit(itemToEdit)}
      >
        Save
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<DeleteIcon />}
        onClick={() => deleteItem(item.id)}
      >
        Delete Item
      </Button>
    </div>
  );
}
