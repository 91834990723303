import { range } from "lodash-es";
import { v4 as uuidv4 } from "uuid";
import { setGuestAvatar, setGuestColor } from '../API/dbUtils';
import { IMAGE_STRINGS, COLOR_STRINGS } from '../API/users';

const divideStringPriceByQty = ( stringPrice, qty ) => {
  const intTimes100 = Number(stringPrice);
  const portion = Math.floor(intTimes100 / qty);
  return portion.toString();
}

export const formatDataForSaveFromVerifyToFirebase = ( state, subtotal, total, moderatorName ) => {
  /* take lineItems with qty of more than one and create separate line items for each one.*/ /* return whole state with new itemsbyId and itemIds*/
  const result =  state.itemIds.reduce(
    (acc, id) => {
      const item = state.itemsById[id];
      const arr = range(item.qty);
      let tempById = {};
      let tempIds = [];
      arr.forEach((num) => {
        const newId = uuidv4();
        const newItem = {
          ...item,
          qty: 1,
          price: divideStringPriceByQty(item.price, item.qty)
        };
        tempById = {
          ...tempById,
          [newId]: newItem,
        };
        tempIds = [...tempIds, newId];
      });
      return {
        ...acc,
        itemsById: {
          ...acc.itemsById,
          ...tempById
        },
        itemIds: [...acc.itemIds, ...tempIds]
      };
    },
    {
      ...state,
      totalInfo: {
        ...state.totalInfo,
        subtotal,
        total,
      },
      itemsById: {},
      itemIds: [],
      guests: {
        [state.moderatorId]: {
          name: moderatorName || 'Hero',
          avatar: setGuestAvatar(1, IMAGE_STRINGS),
          color: setGuestColor(1, COLOR_STRINGS),
        },
      },
    }
  );
  
  return result;
};