import React from 'react';
import Item from "../Item/Item";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { guestShape, selectedItemsShape, itemShape } from '../../../utils/propTypesShapes';
import { sortItemsByType } from './utils';

const useStyles = makeStyles( theme => ({
  root: {},
  list: {
    listStyleType: "none",
    padding: [[ 0,'1em' ]],
    marginBottom: '1em',
    opacity: (props) => props.didCheckout ? '.5' : '',
    '& li:not(last-of-type)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const ItemsList = ({ids, selectedItems, guests, itemsById, myItemsIds = [], handleClickItem, myId, sharedArray, didCheckout, isSuggestionsShowing}) => {
  const classes = useStyles({didCheckout});

  const handleClick = (isLocked, id) => {
    if (isLocked) {
      return;
    } else {
      handleClickItem(id)
    }
  }
  
  return (
    <ul className={classes.list}>
      {
        ids.sort((a, b) => sortItemsByType(itemsById[a], itemsById[b])).map((id, i) => {
          // todo should be memo
          const selectedByArray = selectedItems?.[id] ? selectedItems?.[id]
            .filter(id => id !== myId)
            .map(guestId => {
              const guest = guests[guestId];
              return guest?.name ?? '';
            }) : null;
          
          const selectedGuests = selectedItems?.[id] ? selectedItems?.[id]
            // .filter(id => id !== myId) commenting this out since we need all the guest id's
            .map(guestId => {
              const guest = guests[guestId];
              return guest;
            }) : null;
          
          const isSelectedByMe = myItemsIds.includes(id);

          // todo should be memo
          const sharedWithArrayOfNames = sharedArray.includes(id) ? selectedByArray : null;
          return (
            <li key={id}>
              <Item
                item={itemsById[id]}
                isSelected={isSelectedByMe}
                handleClick={() => handleClick(null, id)}
                sharedWith={sharedWithArrayOfNames}
                selectedBy={selectedByArray}
                didCheckout={didCheckout}
                selectedGuests={selectedGuests}
                isSuggestionsShowing={isSuggestionsShowing}
              />
            </li> 
          )})
      }
    </ul>
  )
}

const { arrayOf, string, shape, objectOf, func, bool} = PropTypes;

ItemsList.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  selectedItems: selectedItemsShape,
  guests: objectOf(guestShape),
  selectedBy: arrayOf(string),
  sharedWith: arrayOf(string),
  sharedArray: arrayOf(string),
  itemsAlreadyPaidFor: objectOf(shape({ paidForBy: arrayOf(string) })),
  itemsById: objectOf(itemShape),
  myId: string,
  myItemsIds: arrayOf(string),
  handleClickItem: func,
  didCheckout: bool,
};

export default ItemsList;

