import React, {  } from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import * as analytics from "../../API/mixpanel";
import CountdownTimer from './../Countdown/CountdownTimer';

export default function CountDownBanner({ countdownEndDate }: { countdownEndDate: number | null }) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <CountdownTimer countdownEndDate={countdownEndDate}/>
      <Typography>Save your transaction history!</Typography>
      <div style={{width: '92%'}}>
        <Button 
          className={styles.button}
          variant="contained"
          color="primary"
          fullWidth={true}
          href="https://apps.apple.com/app/apple-store/id1605675055?pt=123962411&ct=partic%20actv&mt=8"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => analytics.clickedDownloadIOSApp('participant summary countdown banner')}
        >
          download the app
        </Button>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#D5D2FF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/background-gradient7.jpeg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    padding: 16,
  },
  button: {
    backgroundColor: '#5349f9',
    borderRadius: '16px'
  },
}));
