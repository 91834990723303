import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../assets/images/download-icon.svg";
import { lightPurple, powder } from "../style/colors";
import * as analytics from "../API/mixpanel";

function isUserOnIphone() {
  const userAgent = window.navigator.userAgent;
  var isIOS = /iPad|iPhone|iPod/.test(userAgent)
  return isIOS;
}
export default function SummaryDownloadBanner() {
  const styles = useStyles();
  const link = isUserOnIphone() ? "https://splytpay.page.link/37sP" : "https://play.google.com/store/apps/details?id=com.app.splytpay";
  return (
    <div className={styles.root}>
      <img src="/splyt-secondary-icon.png" height="68" width="52" alt="Splyt Logo" />
      <Button
        href={link}
        rel="noopener noreferrer"
        target="_blank"
        onClick={() => analytics.clickedDownloadIOSApp('participant summary top banner')}
        variant="outlined"
        className={styles.button}
      >
        <DownloadIcon />&nbsp;&nbsp;Splyt
      </Button>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: powder,
    padding: '8px 24px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
    position: 'absolute',
    right: '24px',
    top: '50%',
    transform: 'translateY(-50%)',
    borderRadius: '50px',
    textTransform: 'initial',
    fontWeight: 600,
    color: lightPurple,
    borderColor: lightPurple,
    padding: '4px 8px',
  },
}));