import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

/**
 * @desc Creates a screen reader only progress bar.
 * To be used in conjunction with LoadingMask's
 * 
 * Don't forget to set an aria-busy role to the most appropriate
 * region to specify that a group is loading.
 * Ideally, there should only be one of these per **group**
 */

const propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  // if not provided, this is an indeterminate progress bar
  current: PropTypes.number,
  label: PropTypes.string,
};

const useStyles = makeStyles({
  root: {
    height: 0,
    width: 0,
  },
});

const ScreenReaderLoadingIndicator = ({
  min = 0,
  max = 100,
  current,
  label = 'Loading',
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <progress
      { ...otherProps }
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={current}
      aria-valuetext={label}
      className={classes.root}
    />
  );
};
  
ScreenReaderLoadingIndicator.propTypes = propTypes;
export default ScreenReaderLoadingIndicator;
  