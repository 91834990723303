import React from 'react';
import Button from '@material-ui/core/Button';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { blue, payGreen } from '../../../style/colors';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  checked: {},
}));

function CustomButton({ text, handleClick, variant='contained', color ='primary', ...buttonProps }) {
  const classes = useStyles();

  return (
    <Button
      fullWidth={true}
      onClick={handleClick}
      variant={variant}
      color={color}
      classes={{
        root: classes.root,
      }}
      {...buttonProps}
    >
      {text}
    </Button>
  );
}

CustomButton.propTypes = {
  text: PropTypes.node,
  handleClick: PropTypes.func,
  variant: PropTypes.string,
  color: PropTypes.string,
};

const theme = createTheme({
  palette: {
    primary: {
      main: blue,
    },
    secondary: {
      main: payGreen,
    },
  },
  typography: {
    fontFamily: [
      'poppins',
      'Verdana',
      'sans-serif',
      'Helvetica Neue',
    ].join(','),
  }
});

export default function LightBlueButton({text, handleClick, ...buttonProps}) {
  return (
    <ThemeProvider theme={theme}>
      <CustomButton text={text} handleClick={handleClick} {...buttonProps}/>
    </ThemeProvider>
  );
}

LightBlueButton.propTypes = {
  text: PropTypes.node,
  handleClick: PropTypes.func,
};