import { isArray, isFunction } from 'lodash';

export function listify(
  list: string[],
  cap: number,
  overflowTextFn: (x: number) => string,
  separator = ',',
  and = '&',
) {
  if (!isArray(list)) return '';

  let text = list
    .slice(0, cap)
    .reduce((agg, item, i) => (
      agg
        .concat(i > 0 
          ? i === list.length - 1 
            ? i === 1 ? ` ${and} ` : `${separator} ${and} ` 
            : `${separator} `
          : ''
        ).concat(item)
    ), '');
  
  if (isFunction(overflowTextFn) && list.length > cap) {
    text = `${text}${overflowTextFn(list.length - cap)}`;
  }
    
  return text;
}

export function basicPlurify(word: string, count: number) {
  return count === 1 ? word : `${word}s`;
}

export function displayFraction(numerator: number, denominator: number) {
  // NaN
  if (denominator === 0) return '0';
  // whole number
  if (numerator % denominator === 0) return numerator / denominator;
  
  let divisor = gcd(numerator, denominator);
  return `${numerator / divisor}/${denominator / divisor}`;
}

function gcd(x: number, y: number) {
  x = Math.abs(x);
  y = Math.abs(y);
  while(y) {
    let t = y;
    y = x % y;
    x = t;
  }
  return x;
}

export function removeKey<T extends Record<string, unknown>>(
  key: keyof T,
  obj: T,
) {
  return Object.keys(obj).reduce((resultObj, currentKey) => {
    if (currentKey !== key) {
      return {
        ...resultObj,
        [currentKey]: obj[currentKey],
      };
    }

    return resultObj;
  }, {} as Omit<T, typeof key>);
}

export function resizeImage(
  lengthOfSmallestDimension: number,
  base64Image: string,
) {
  return new Promise((resolve, reject) => {
    const img = new Image();
            
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
        
      if (img.width > lengthOfSmallestDimension && img.height > lengthOfSmallestDimension) {
        const ratio = img.width / img.height;
        let newWidth, newHeight;
        if (ratio > 1) {
          newHeight = lengthOfSmallestDimension;
          newWidth = lengthOfSmallestDimension * ratio;
        } else {
          newWidth = lengthOfSmallestDimension;
          newHeight = lengthOfSmallestDimension / ratio;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
      }
        
      const context = canvas.getContext('2d');
      context?.drawImage(img, 0, 0, canvas.width, canvas.height);
        
      resolve(canvas.toDataURL());
    };

    img.onerror = (err) => { console.error(err); reject(err); };
    img.src = base64Image;
  });
}
