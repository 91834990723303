import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Typography, Button, makeStyles } from "@material-ui/core";
import GuestAvatarsV2 from './ocrComponents/guestAvatars/GuestAvatarsV2';
import AvatarV2 from './ocrComponents/guestAvatars/AvatarV2';
import { GroupAdd as GroupAddIcon } from '@material-ui/icons';
import { darkGreen, primaryBorder } from '../style/colors';

const propTypes = {
  restaurantName: PropTypes.string,
  guests: PropTypes.objectOf(PropTypes.object),
  moderator: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
  adornments: PropTypes.object,
  onInvite: PropTypes.func,
  openGuestList: PropTypes.func,
  orderBoost: PropTypes.arrayOf(PropTypes.string),
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1em',
  },
  restaurantName: {
    padding: theme.spacing(2),
  },
  guestListContainer: {
    padding: theme.spacing(2),
    borderBottom: primaryBorder,
  },
  splytTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flexGrow: 1,
  },
  splytWith: {
    fontWeight: 600,
    fontSize: '.75rem',
    color: darkGreen,
    marginBottom: theme.spacing(1),
  },
  inviteBar: {
    backgroundColor: theme.palette.primary.main,
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
  horizontalScroll: {
    overflowY: 'auto',
    paddingTop: theme.spacing(1.5),
  },
}));

const useInviteButtonClasses = makeStyles(theme => ({
  root: {
    borderRadius: '2rem',
    backgroundColor: 'white',
  },
  label: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '.75rem',
    marginLeft: theme.spacing(1),
  },
  startIcon: {
    transform: 'scale(1.25)',
    marginLeft: theme.spacing(-1),
  },
}));


const ReceiptHeader = ({ restaurantName, guests, moderator, onInvite, openGuestList, adornments, orderBoost }) => {
  const classes = useStyles();
  const inviteButtonClasses = useInviteButtonClasses();
  const mappedGuests = Object.keys(guests).filter(val => val !== 'initial').map( (val, i) => {
    return { ...guests[val], id: val };
  });

  let nameWithPossessive = '';
  if (moderator?.name) {
    const firstName = moderator.name.split(' ')[0];
    nameWithPossessive = `${firstName}'${firstName.toLowerCase().slice(-1) === 's' ? '' : 's'}`;
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" className={classes.inviteBar}>
        <Toolbar>
          <AvatarV2
            uid={moderator?.id || ''}
            defaultImage={`/emojis/${moderator?.avatar}.png`} 
            name={moderator?.name || ''}
            size="extraSmall"
          />
          <Typography className={classes.splytTitle} component="h2" noWrap>
            { nameWithPossessive } splyt
          </Typography>
          <Button
            disableElevation
            classes={inviteButtonClasses}
            color="tertiary"
            variant="contained"
            startIcon={<GroupAddIcon />}
            onClick={onInvite}
          >
            Invite
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.guestListContainer}>
        <Typography className={classes.splytWith} component="h3">
          splyt with
        </Typography>
        <div className={classes.horizontalScroll}>
          <GuestAvatarsV2
            guests={mappedGuests}
            size="medium"
            openGuestList={openGuestList}
            orderBoost={orderBoost}
            adornmentsByUser={adornments}
          />
        </div>
      </div>
      <div className={classes.restaurantName}>
        <Typography variant="h3" component="h3">{restaurantName}</Typography>
      </div>
    </div>
  )
}

ReceiptHeader.propTypes = propTypes;
export default ReceiptHeader;
