import mixpanel from 'mixpanel-browser';

export const userSignedInFromUpload = () => {

}

/**
 * @param {Object} properties 
 * @param {String} properties.page
 * @param {String} properties.url
 */
export const pageViewed = (properties) => {
  mixpanel.track("page viewed", properties);
}

/**
 * @param {Object} properties 
 * @param {String} properties.buttonName
 * @param {String} [properties.source]
 * @param {Object} [properties.meta]
 */
export const buttonPressed = (properties) => {
  mixpanel.track("button pressed", properties);
}

export const clickedUploadReceipt = () => {
  mixpanel.track("clicked upload receipt");
}

export const confirmedUploadInstructions = () => {
  mixpanel.track("confirmed upload instruction modal");
}

export const clickedTakeAPicture = () => {
  mixpanel.track("clicked take a picture ");
}

export const submittedPaymentInfo = ( paymentInfo ) => {
  mixpanel.track("submitted payment info", { paymentInfo }  );
}

export const modifiedLineItem = ( payload ) => {
  mixpanel.track('Modified line item', { ...payload })
}

export const receiptSuccessfullySaved = ( payload ) => {
  mixpanel.track('receipt successfully saved', { ...payload })
}

export const landedOnSplytPage = (payload) => {
  mixpanel.track("landed on splyt page", { ...payload }  );
}

export const clickedInviteYourGroupFromModal = (checkId) => {
  mixpanel.track("clicked invite your group from modal", { checkId }  );
}

export const clickedDoneSelecting = () => {
  mixpanel.track("clicked done selecting");
}

export const landedOnSummaryPage = () => {
  mixpanel.track("landed on summary page");
}

export const clickedHamburgerMenu = () => {
  mixpanel.track("Clicked Hamburger menu");
}

export const clickedUpdateUsername = () => {
  mixpanel.track("Clicked update username");
}

export const clickedUpdatePaymentsFromHeader = () => {
  mixpanel.track("Clicked update payments from header");
}

export const clickedMySplytsFromMenu = () => {
  mixpanel.track("Clicked my splyts");
}

export const clickedSplytLogo = () => {
  mixpanel.track("clicked Splyt Logo");
}

export const clickedLogOut = () => {
  mixpanel.track("clicked log out");
}

export const clickedPreviousSplyt = (id) => {
  mixpanel.track("clicked previous splyt", { id });
}

export const clickedViewMore = () => {
  mixpanel.track("clicked view more");
}

export const clickedDownloadIOSApp = (location = 'unspecified') => {
  mixpanel.track("clicked download ios app", { source: location });
}

export const clickedDownloadAndroidApp = (location = 'unspecified') => {
  mixpanel.track("clicked download android app", { source: location });
}

export const identify = (uid) => {
  mixpanel.identify(uid)
}

export const getDistinctId = () => {
  return mixpanel.get_distinct_id();
}

export const failedToAddEmoji = ( error ) => {
  mixpanel.track('error occurred', { funcName: 'failedToAddEmoji', error })
}