import React, { useMemo, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import { formatPriceStringForDisplay } from '../../../utils/NumberUtils';
import PropTypes from 'prop-types';
import { white, darkGreen, darkBlue, black } from '../../../style/colors'
import SelectButton from './SelectButton/SelectButton';
import { Typography } from "@material-ui/core";
import GuestAvatars from "../guestAvatars/GuestAvatarsV2";

const useStyles = makeStyles((theme) => ({
  root: ({ isSelected, isDisabled }) => {
    let backgroundColor = white;
    let opacity = '1';
    if (isSelected) { 
      backgroundColor = darkBlue;
    }
    if (isDisabled) {
      backgroundColor = '#e0e0e0';
      opacity = '.7'
    }
    return {
      display: 'flex',
      boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)',
      borderRadius: '10px',
      backgroundColor: backgroundColor,
      minHeight: '3rem',
      padding: '1rem',
      opacity: opacity,
    }
  },
  selectButton: {
    marginRight: theme.spacing(1),
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'scroll',
  },
  rowWrapper: {
    display: 'flex',
  },
  namePriceRow: ({isSelected}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: `${isSelected ? white : black}`,
  }),
  itemName: ({isSelected}) => ({
    ...theme.customType.semiBold,
    color: `${isSelected ? white : black}`,
    // fontWeight: theme.customType.normalTextSemiBold,
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // width: '90%',
  }),
  descriptionAndExtrasRow: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  perPersonRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(.25),
  },
  description: ({ isSelected }) => ({
    color: `${isSelected ? white : darkGreen}`,
    ...theme.customType.smallText,
    ...theme.customType.semiBold,
  }),
  charge: ({ isSelected }) => ({
    color: `${isSelected ? white : darkGreen}`,
    ...theme.customType.smallText,
  }),
  extraCharge: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatars: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
    minHeight: '48px',
  },
  avatarsPrefix: {
    marginRight: '0.8em',
    width: '95px'
  },
}));

export default function Item(props) {
  const {
    item,
    handleClick,
    isSelected,
    selectedGuests,
    isSuggestionsShowing,
  } = props;
  const { name, price, extras, suggestion } = item;
  let displayName = name;
  if (isSuggestionsShowing && suggestion) {
    displayName = suggestion;
  }

  const getFormattedDividedByPrice = useCallback(() => {
    const splitByHowManyPeople = item?.splitByHowManyPeople ?? 1;
    const dividedByPrice = ((Number(price) / splitByHowManyPeople) / 100).toFixed(2);
    return dividedByPrice;
  }, [item, price]);

  const getFormattedClickedSplitDividedByPrice = useCallback(() => {
    const splitByHowManyPeople = selectedGuests?.length ?? 1;
    const dividedByPrice = ((Number(price) / splitByHowManyPeople) / 100).toFixed(2);
    return dividedByPrice;
  }, [price, selectedGuests?.length]);

  const isDisabled = useMemo(() => {
    const splitByHowManyPeople = item?.splitByHowManyPeople ?? 1;
    const amountSelected = selectedGuests?.length ?? 0;
    if (splitByHowManyPeople > 1) {
      if (isSelected) return false;
      return splitByHowManyPeople === amountSelected;
    } return false;

  }, [item, selectedGuests, isSelected]);

  const onClick = useCallback(() => {
    if (isDisabled) return;
    if (handleClick) handleClick();
  }, [handleClick, isDisabled]);

  const classes = useStyles({...props, isDisabled});

  return (
    <Paper elevation={3} className={classes.root} onClick={onClick}>
      <div className={classes.selectButton}>
        <SelectButton isSelected={isSelected} className={classes.selectButton} isDisabled={isDisabled}/>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.namePriceRow}>
          <Typography className={classes.itemName}>{displayName}</Typography>
          <Typography >{`$${formatPriceStringForDisplay(price)}`}</Typography>
        </div>
        {!!extras?.descriptionIds && extras.descriptionIds.length > 0 && extras?.descriptionIds.map(id => (
          <div key={id} className={classes.descriptionAndExtrasRow}>
            <Typography key={`description-${id}`} className={classes.description}>{extras?.descriptionsById[id]}</Typography>
          </div>
        ))}
        { !!item?.splitByHowManyPeople && item?.splitByHowManyPeople > 1 && <div className={classes.descriptionAndExtrasRow}>
          <Typography className={classes.charge}>{`pre-split ${item?.splitByHowManyPeople ?? 1} ways`}</Typography>
          <Typography className={classes.charge}>{`$${getFormattedDividedByPrice()} / person`}</Typography>
        </div>
        }
        { (!item?.splitByHowManyPeople || item?.splitByHowManyPeople <= 1) && ((selectedGuests?.length ?? 1) > 1) && isSelected && <div className={classes.perPersonRow}>
          <Typography className={classes.charge}>{`$${getFormattedClickedSplitDividedByPrice()} / person`}</Typography>
        </div>
        }
        {!!extras?.chargeIds && extras?.chargeIds.length > 0 && extras?.chargeIds.map(id => (
          <div key={id} className={classes.descriptionAndExtrasRow}>
            <Typography key={`chargeDescription-${id}`} className={classes.charge}>{extras?.chargesById[id].chargeDescription}</Typography>
            <Typography key={`chargePrice-${id}`} className={classes.charge}>{`+$${formatPriceStringForDisplay(extras?.chargesById[id].chargePrice)}`}</Typography>
          </div>
        ))}
        <div className={classes.avatars}>
          {!!selectedGuests && (
            <GuestAvatars
              guests={selectedGuests}
              size="extraSmall"
              textColor={isSelected ? white : darkGreen}
            />
          )}
        </div>
      </div>
    </Paper>
  );
}
const {shape, string, arrayOf, bool, func, objectOf} = PropTypes;

Item.propTypes = {
  item: shape({
    name: string,
    price: string,
    extras: shape({
      descriptionIds: arrayOf(string),
      descriptionsById: objectOf(string),
      chargesById: arrayOf(string),
      chargeIds: objectOf(string),
    })
  }),
  selectedBy: arrayOf(string),
  sharedWith: arrayOf(string),
  isSelected: bool,
  handleClick: func,
}

