import { CircularProgress, makeStyles } from '@material-ui/core';
import { fromPairs, isEmpty, isNil, toPairs } from "lodash-es";
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  markItemAsPaidFor,
  setHasSeenInstructions,
  subscribeToCheck,
  toggleCheckFinalized,
  toggleClaimOnLineItem,
  acknowledgeMessageOnCheck,
  markGhostMutualUserAsJoined,
  appendGuestV2,
  claimGhostContactUser,
  setNudgeOnJoin,
} from '../API/checks';
import * as analytics from '../API/mixpanel';
import AuthContext from "../context/AuthContext";
import AppStoreButtons from "./AppStoreButtons";
import { useMySplytTotals } from "../utils/hooks/useMySplytTotals";
import SplytCreatedInstructionModal from './InstructionModal/SplytCreatedInstructionModal';
import NameDialog from './NameDialog';
import ItemsEditedWarningModal from './ItemsEditedWarningModal';
import ReceiptHeader from './ReceiptHeader';
import SummaryModal, { useSummaryModalWithHistory } from './SummaryModal/SummaryModal';
import SignUpModal from './authComponents/SignUpModal';
import CheckInfoButton from './ocrComponents/CheckInfoButton/CheckInfoButton';
import CheckInfoDrawer from './ocrComponents/CheckInfoDrawer/CheckInfoDrawer';
import CheckoutButton from './ocrComponents/CheckoutButton/CheckoutButton';
import CheckoutButtonPayCard from './ocrComponents/CheckoutButton/CheckoutButtonPayCard';
import ModeratorCompletionText from "./ocrComponents/ModeratorCompletionText/ModeratorCompletionText";
import OpenSummaryButton from './ocrComponents/OpenSummaryButton/OpenSummaryButton';
import Receipt from "./ocrComponents/Receipt";
import ShareModal from './ocrComponents/shareModal';
import GuestList from './ocrComponents/GuestList/GuestList';
import PropTypes from 'prop-types';
import ActivityOverlay from './ActivityOverlay';
// import { signOut } from '../API/users';
import SplytItemsInstructionModal from "./InstructionModal/SplytItemsInstructionModal";

const propTypes = { isDemo: PropTypes.bool };

export default function ReceiptContainer({ isDemo }) {
  const classes = useStyles();
  const { checkId } = useParams();
  const location = useLocation();
  const id = checkId;
  const [ isFetching, setIsFetching ] = useState(true);
  const [ data, setData ] = useState({ items: [] });
  const [ me, setMe ] = useState({ myId: null, name: ''});
  const [ guests, setGuests ] = useState({});
  const [ tip, setTip ] = useState({ isCustom: false, amount: 0 });
  const [ nameDialogConfig, setNameDialogConfig ] = useState({open: false, ref: null, tempId: null});
  const [ nameDialogVal, setNameDialogVal ] = useState('');
  const [ readyToCheckOutLoading, setReadyLoading  ] = useState(false);
  const [ shareIsOpen, setShareIsOpen  ] = useState(false);
  const [ areModeratorInstructionsOpen, setAreModeratorInstructionsOpen ] = useState(false);
  const [ areParticipantInstructionsOpen, setAreParticipantInstructionsOpen ] = useState(false);
  const { isSummaryOpen, openSummaryModal, closeSummaryModal } = useSummaryModalWithHistory();
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ showGuestList, setShowGuestList ] = useState(false);
  const bottomOfPageRef = useRef({});
  const { myId, user, profile, isAuthenticating }  = useContext(AuthContext);
  const [selectedEmoji, setSelectedEmoji] = useState(null)
  // get initial data
  useEffect(function fetchData() {
    setIsFetching(true);

    return subscribeToCheck(id, checkData => {
      setData(checkData);
      setIsFetching(false);
    });
  }, [id]);

  const selectEmoji = useCallback((emoji) => {
    setSelectedEmoji(emoji)
  }, [])

  useEffect(function trackPageView() {
    if (checkId && data?.moderatorId) {
      // analytics dispatch
      analytics.landedOnSplytPage({
        checkId: id,
        moderatorId: data.moderatorId,
        userPaymentInfo: data?.userPaymentInfo ?? {},
      });
    }
    // we don't care to rerun this if the userPayment details change but everything else stays the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkId, data?.moderatorId]);

  const moderatorInfo = useMemo(() => {
    const guests = data?.guests ?? null;
    const modId = data?.moderatorId ?? null;
    let result = { id: modId, name: '', avatar: '' };
    if (guests && modId && Object.keys(guests).includes(modId)) {
      result.name = guests[modId].name ?? ''
      result.avatar = guests[modId].avatar ?? ''
    }
    return result;
  }, [data]);

  const clearNameFormData = useCallback((event, reason) => {
    if (reason && reason === "backdropClick") return;
    setNameDialogConfig({open: false, ref: null, tempId: null});
  }, [setNameDialogConfig]);

  const setNudge = useCallback(function setNudgeWhenUserJoins(name){
    const payments = data?.payments ?? {};
    const didViewingUserPay =
      Object.keys(payments).includes(myId) &&
      (payments[myId].status === 'success' || payments[myId].status === 'processing');
    if (myId === moderatorInfo.id || didViewingUserPay) return

    setNudgeOnJoin(id, myId, user?.phoneNumber, data?.restaurantInfo?.restaurantName ?? null, moderatorInfo?.name ?? null, name);
  }, [data?.payments, data?.restaurantInfo?.restaurantName, id, moderatorInfo.id, moderatorInfo?.name, myId, user?.phoneNumber])
  

  const addGuestToCheck = useCallback(async (name, profileEmoji) => {
    const guests = data?.guests ?? {};
    const nameToUse = name ? name : nameDialogVal
    const savedEmoji =  selectedEmoji || profileEmoji;
    try {
      if (user?.phoneNumber && Object.keys(guests).includes(user?.phoneNumber)) {
        await claimGhostContactUser(id, user.phoneNumber, myId, nameToUse);
      } else {
        await appendGuestV2(id, myId, nameToUse, savedEmoji);
      }
      mixpanel.track("joined new splyt", { splytId: id });
      setMe({ myId, name: nameToUse });
      setNudge(nameToUse || '');
      clearNameFormData();
    } catch (err) {
      console.error(err);
    }
		
  }, [data?.guests, nameDialogVal, selectedEmoji, user?.phoneNumber, id, myId, setNudge, clearNameFormData])
  /*
    Auth flow. On page load, check if user is logged in.  Or, if user has anonymous login in localStorage.
  */
  // check for and set guest ref in local storage 
  useEffect(function onLogin() {
    const userName = profile?.name ?? null;
    const guests = data?.guests ?? {};
    const guestsIds = Object.keys(guests);
		
    if (!isNil(myId) && !isEmpty(profile) && !isFetching && user?.phoneNumber) {
      if (guestsIds.includes(myId)) {
        setMe({ myId, name: data.guests[myId].name })
        if (guests[myId]?.isGhost) markGhostMutualUserAsJoined(id, myId);
      } else if (userName) {
        addGuestToCheck(userName, profile?.emoji);
        setMe({ myId, name: userName ?? null })
      } else {
        setNameDialogConfig({open: true, ref: null, tempId: myId});
      }
    }
  }, [myId, id, data, isFetching, profile, addGuestToCheck, user?.phoneNumber]);
  
  useEffect(function openInstructionsIfApplicable() {
    const userName = profile?.name ?? null;
    const hasSeenInstructions = data?.seenInstructions?.[myId] ?? false;
    setAreModeratorInstructionsOpen(userName && !hasSeenInstructions && !nameDialogConfig.open);
  }, [data, myId, nameDialogConfig.open, profile?.name]);

  const handleCloseInstructions = useCallback(() => {
    setAreModeratorInstructionsOpen(false);
    setHasSeenInstructions(id, myId, true);
  }, [id, myId]);

  const isModerator = useMemo(() => {
    const { moderatorId = null } = data;
    return myId === moderatorId;
  }, [myId, data]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClickItem = async itemId => {
    mixpanel.track("splyt clicked item", {itemId, userId: myId});
    console.log('CLICKED ')
    const { didCheckout = false } = data;
    if (didCheckout) {
      // maybe alert them here
    } else {
      const { myId: myGuestId } = me;

      try {
        await toggleClaimOnLineItem(id, itemId, myGuestId);
      } catch (err) {
        console.log('Failed to toggle line item claim', err);
        return;
      }

      // localStorage.removeItem('splyt:seenParticipantInstructions');
      if (!localStorage.getItem('splyt:seenParticipantInstructions')) {
        setAreParticipantInstructionsOpen(true);
      }
    }
  }

  const mySelections = useMemo(() => {
    const { myId = null} = me;
    const { selections = {} } = data || {};
    const selectedIds = selections ? Object.keys(selections): [];

    if (selectedIds.length > 0) {
      const arrayOfMyItemsIds = selectedIds.reduce((resultArr, itemId) => {
        if( selections[itemId].includes(myId)) {
          const item = data.itemsById[itemId];
          const { price } = item;
          let extrasTotal = 0;
          const chargeIds = item?.extras?.chargeIds ?? null;

          if ( !!chargeIds && chargeIds.length > 0 ) {
            const chargesById = item.extras.chargesById;
            extrasTotal = chargeIds.reduce((acc, chargeId) => {
              const chargePriceString = chargesById[chargeId].chargePrice;
              const chargePrice = Number(chargePriceString)
              return acc + chargePrice;
            }, 0)
          }

          const totalPriceString = (Number(price) + extrasTotal).toString();

          return [
            ...resultArr,
            {
              itemId,
              price: totalPriceString,
              numberOfPeopleWhoSelected: selections[itemId].length,
            }
          ]
        }
        return resultArr;
      }, [])
      return arrayOfMyItemsIds
    }
    
    return [];
  }, [data, me ]);

  const handleSelectTipPercent = useCallback((e, value)=> {
    setTip({ isCustom: false, amount: value });
  }, [setTip]);

  const handleClickCustomTip = useCallback(() => {
    setTip({ isCustom: true, amount: 0 });
  }, [setTip]);

  const handleInputCustomTip = useCallback(e => {
    // const val = e.target.value;
  }, []);

  const isTotalMet = useMemo(() => {
    const { selections = {} } = data;
    const selectedIds = Object.keys(selections).filter(id => id !== 'initial');
    const ids = data?.itemIds ?? null;
    if (ids) return selectedIds.length === ids.length;
    return false;
  }, [data]);

  const itemsNotSelected = useMemo(() => {
    const { selections = {} } = data;
    const selectedIds = Object.keys(selections).filter(id => id !== 'initial');
    const ids = data?.itemIds ?? null;
    if( ids ) return ids.length - selectedIds.length;
    return null;
  }, [data]);

  const [ mySubtotal, myTip, myTax, myTotal, myPercentageOfSubtotal, itemizedFeesAndDiscounts ] = useMySplytTotals(data, me?.myId);

  const handleReadyToCheckoutClick = useCallback(() => {
    const { myId } = me;
    setReadyLoading(true);
    toggleCheckFinalized(id, myId)
      .then(({ committed }) => {
        if (!committed) console.log('Couldn\'t add guest db');
      })
      .catch(error => console.log('Transaction failed abnormally DB !', error))
      .finally(() => setReadyLoading(false));
  }, [setReadyLoading, me, id ]);

  const readyToPay = useMemo(() => {
    if ( data && data?.guests && data?.idsReadyToCheckout) {
      return data?.idsReadyToCheckout.filter(id => id !== 'initial').length === Object.keys(guests).filter(id => id !== 'initial').length;
    }
    return false;
  },[data, guests]);

  const numberOfPeopleStillSelecting = useMemo(() => {
    if ( data && data?.guests && data?.idsReadyToCheckout) {
      return Object.keys(guests).filter(id => id !== 'initial').length - data?.idsReadyToCheckout.filter(id => id !== 'initial').length;
    }
    return null
  }, [ data, guests ]);

  const amIReadyToCheckOut = useMemo(() => {
    const { myId } = me;
    if( !data?.idsReadyToCheckout ) return false;
    return data.idsReadyToCheckout.includes(myId);
  }, [ data, me ]);

  const total = useMemo(() => {
    const tot = Number(mySubtotal) < 0 ? '000' : Number(mySubtotal) + Number(myTip) + Number(myTax);
    return (tot / 100).toFixed(2).toString();
  }, [mySubtotal, myTip, myTax]);

  const disableCheckoutButton = useMemo(() => {
    return Number(mySubtotal) <= 0;
  }, [mySubtotal]);


  // redirect to checkout page when everyone has selected.
  const venmoName = data?.userPaymentInfo?.venmo ?? null;
  const cashAppName = data?.userPaymentInfo?.cashApp ?? null;
  const restaurantName = data?.restaurantInfo?.restaurantName ?? '';

  const paymentLinks = useMemo(() => {
    const formattedTotal = myTotal ? (Number(myTotal) / 100).toFixed(2).toString() : '0.00';
    const venmoDeepLink = venmoName ? `venmo://paycharge?charge=pay&recipients=${venmoName}&amount=${formattedTotal}&note=${
      restaurantName} sent via splyt.co` : null;

    const removeDollarSignFromCashtag = (cashtag) => {
      if(cashtag?.length > 0 && cashtag[0] === '$') return cashtag.slice(1);
      return cashtag;
    }

    const cleanedCashAppName = removeDollarSignFromCashtag(cashAppName)
    const cashAppDeepLink = cashAppName ? `https://cash.app/$${cleanedCashAppName}/${formattedTotal}` : null;
    return { venmoDeepLink, cashAppDeepLink };
  }, [myTotal, venmoName, restaurantName, cashAppName]);

  const handleAddFriendsClick = useCallback(() => {
    mixpanel.track('clicked add friends to receipt button');
    setShareIsOpen(true);
  }, [setShareIsOpen]);

  const mySelectedIds = useMemo(() => {
    return mySelections.length ? mySelections.map(item => item.itemId) : []
  }, [mySelections]);


  const handleCheckoutButtonClick = useCallback((paymentMethod) => {
    const formattedTotal = myTotal ? (Number(myTotal) / 100).toFixed(2).toString() : '0.00';
    mixpanel.track(`clicked pay with ${paymentMethod} button`);
    mixpanel.people.increment(`paid_with_${paymentMethod}`);
    mixpanel.people.increment('total_paid', Number(formattedTotal));

    const selections = data?.selections ?? {};
    markItemAsPaidFor(id, mySelectedIds, selections, myId)
      .then(({ committed }) => {
        if (!committed) console.log('Couldn\'t mark items as paid for db');
      })
      .catch(error => console.log('Transaction failed abnormally DB !', error));
  }, [myTotal, data.selections, id, mySelectedIds, myId]);

  const smartGuests = fromPairs(toPairs(data?.guests ?? {}).map(([id, guest]) => ([id, { id, ...guest }])));
  const adornments = {
    [myId]: [{ type: 'ring', width: 4, color: 'rainbow' }],
  };
  if (data?.moderatorId) {
    adornments[data.moderatorId] ||= [];
    adornments[data.moderatorId].push({ type: 'crown', tilt: -33.86 });
  }

  const itemsForWarningModal = useMemo(() => {
    if (!myId || !data?.messages) return [];
    return data.messages?.[myId] ?? [];
  }, [myId, data?.messages])

  const closeItemEditWarningModal = useCallback(() => {
    acknowledgeMessageOnCheck(myId, checkId)
  }, [myId, checkId])

  const didViewingUserConfirmPayment = useMemo(() => {
    const payments = data?.payments ?? {};
    return Object.keys(payments).includes(myId) && payments[myId].status === 'success'
  }, [data?.payments, myId])

  const shouldShowConfirmPaymentModalOnReturnToSummary = useMemo(() => {
    const payments = data?.payments ?? {};
    if (data.moderatorId === myId) return { show: false };
    if (Object.keys(payments).includes(myId) && payments[myId].status === 'processing') return { show: true, method: payments[myId].method }
    return { show: false };
  }, [data.moderatorId, data?.payments, myId])

  const hasOpenedSummaryAfterNudge = useRef(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search ?? '');
    const utm_source = searchParams.get('utm_source');
    if (utm_source && utm_source === 'nudge' && (mySelectedIds || []).length > 0  && !hasOpenedSummaryAfterNudge.current) {
      hasOpenedSummaryAfterNudge.current = true;
      openSummaryModal();
    }
  }, [location?.search, mySelectedIds, openSummaryModal])
  return (
    <div>
      {isFetching ? (
        <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <ActivityOverlay
          isFullScreen
          isLoading={!isModerator && data.isModeratorEditing}
          color="white"
          text={`${moderatorInfo.name.split(' ')[0]} is making changes.\nPlease wait.`}
        >
          <div style={{maxWidth: '500px'}}>
            <ReceiptHeader
              restaurantName={data?.restaurantInfo?.restaurantName}
              guests={smartGuests}
              moderator={moderatorInfo}
              onInvite={handleAddFriendsClick}
              orderBoost={data.moderatorId ? [myId, data.moderatorId] : [myId]}
              adornments={adornments}
              openGuestList={() => setShowGuestList(true)}
            />
            <div>
              <Receipt
                myId={me.myId}
                myItemsIds={mySelectedIds}
                data={data}
                handleClickItem={handleClickItem}
                isTotalMet={isTotalMet}
                id={id}
                checkSubTotal={data.totalInfo.subtotal}
                selectedItems={data.selections}
                isSuggestionsShowing={data.isShowingSuggestions}
                subTotal={mySubtotal}
                tax={myTax}
                guests={smartGuests}
                restaurantName={data.restaurantName}
                handleSelectTipPercent={handleSelectTipPercent}
                handleInputCustomTip={handleInputCustomTip}
                handleClickCustomTip={handleClickCustomTip}
                tipDollarValue={myTip}
                tip={tip}
                readyToCheckOutLoading={readyToCheckOutLoading}
                handleReadyToCheckoutClick={handleReadyToCheckoutClick}
                numberOfPeopleStillSelecting={numberOfPeopleStillSelecting}
                readyToPay={readyToPay}
                amIReadyToCheckOut={amIReadyToCheckOut}
                itemsNotSelected={itemsNotSelected}
                total={total}
                itemsAlreadyPaidFor={data?.itemsAlreadyPaidFor ?? {'initial': []}}
                isModerator={isModerator}
                tipFromCheck={data.totalInfo.tip}
                showSubtotals={false}
              /> 

              <div className={classes.sticky}>
                { isModerator && <CheckInfoButton onClick={toggleDrawer(true)}/> }
                { mySelections.length > 0 && (
                  <OpenSummaryButton
                    total={myTotal}
                    recipientName={moderatorInfo?.name ?? ''}
                    numSelected={mySelections.length}
                    isModerator={isModerator}
                    onClick={() => { openSummaryModal(); analytics.clickedDoneSelecting() }}
                  />
                )}
              </div>
              <NameDialog 
                open={nameDialogConfig.open} 
                submitFunc={addGuestToCheck} 
                dbRef={nameDialogConfig.ref}
                val={nameDialogVal} 
                setVal={setNameDialogVal}
                tempId={nameDialogConfig.tempId}
                clearNameFormData={clearNameFormData}
                setGuests={setGuests}
                setMe={setMe}
              />
              <ItemsEditedWarningModal open={itemsForWarningModal.length > 0} items={itemsForWarningModal} close={closeItemEditWarningModal}/>
              <ShareModal 
                isOpen={shareIsOpen} 
                closeShareModal={() => setShareIsOpen(false)}
                restaurantName={data?.restaurantInfo?.restaurantName}
              />
              <CheckInfoDrawer
                open={drawerOpen}
                totalInfo={data?.totalInfo}
                itemizedFeesAndDiscounts={itemizedFeesAndDiscounts}
                toggleDrawer={toggleDrawer}
                checkTipDollarValue={data?.totalInfo?.tip}
                tipDollarValue={myTip}
                tipPercent={data?.totalInfo?.tipPercent}
                checkTax={data?.totalInfo?.tax}
                tax={myTax}
                total={total}
                checkSubTotal={data?.totalInfo?.subtotal}
              />
              <SummaryModal
                isOpen={isSummaryOpen}
                mySubtotal={mySubtotal}
                myTip={myTip}
                myTax={myTax}
                myTotal={myTotal}
                myPercentageOfSubtotal={myPercentageOfSubtotal}
                itemizedFeesAndDiscounts={itemizedFeesAndDiscounts}
                totalInfo={data?.totalInfo}
                onClose={closeSummaryModal}
                countdownEndDate={profile?.countdownEndDate ?? null}
                checkTotal={data?.totalInfo?.total}
                total={isModerator ? total : null}
                restaurantName={data?.restaurantInfo?.restaurantName}
                itemIds={data?.itemIds}
                itemsById={data?.itemsById}
                selections={data?.selections}
                myId={myId}
                guests={smartGuests}
                checkTipDollarValue={data?.totalInfo?.tip}
                tipDollarValue={myTip}
                tipPercent={data?.totalInfo?.tipPercent}
                checkTax={data?.totalInfo?.tax}
                tax={myTax}
                checkSubTotal={data?.totalInfo?.subtotal}
                subTotal={mySubtotal}
                showFullCheckDetails={isModerator}
                isShowingSuggestions={data?.isShowingSuggestions}
                additionalContent={isModerator ? (
                  <ModeratorCompletionText
                    createDate={data?.createDate}
                    updateDate={data?.updateDate}
                  />
                ) : (
                  <AppStoreButtons source="participant summary" />
                )}
                action={!isModerator ? data?.userPaymentInfo?.card ? (
                  <CheckoutButtonPayCard
                    amount={+myTotal}
                    splytId={id}
                    modName={moderatorInfo?.name ?? 'Host'}
                    payment={data?.payments?.[myId]}
                  />
                ) : (
                  <CheckoutButton 
                    splytId={id}
                    handleClick={handleCheckoutButtonClick}
                    disabled={disableCheckoutButton}
                    modName={moderatorInfo?.name ?? ''}
                    paymentLinks={paymentLinks}
                    total={myTotal}
                    countdownEndDate={profile?.countdownEndDate ?? null}
                    didConfirmPayment={didViewingUserConfirmPayment}
                    shouldShowConfirmPaymentModalOnReturnToSummary={shouldShowConfirmPaymentModalOnReturnToSummary}
                  />
                ) : null}
              />
              <GuestList isOpen={showGuestList} onClose={() => setShowGuestList(false)} guests={smartGuests} />
              { data.moderatorId === myId && (
                <SplytCreatedInstructionModal
                  isOpen={areModeratorInstructionsOpen}
                  onOpen={() => setAreModeratorInstructionsOpen(true)}
                  onClose={handleCloseInstructions}
                  onOpenInvite={() => { handleCloseInstructions(); setShareIsOpen(true); analytics.clickedInviteYourGroupFromModal(checkId) }}
                />
              )}
              <SplytItemsInstructionModal
                isOpen={areParticipantInstructionsOpen}
                onClose={() => {
                  localStorage.setItem('splyt:seenParticipantInstructions', 'true');
                  setAreParticipantInstructionsOpen(false);
                }}
              />
            </div>
          </div>
        </ActivityOverlay>
      )}
      <div ref={bottomOfPageRef}></div>
      { !isDemo && (
        <SignUpModal
          title={`Join ${moderatorInfo.name}'s Splyt`}
          open={!isAuthenticating && !myId}
          subtitle=""
          selectEmoji={selectEmoji}
          selectedEmoji={selectedEmoji}
          isModSubscriber={data?.isModSubscriber ?? false}
        />
      )}
    </div>
  );
}

ReceiptContainer.propTypes = propTypes;

const useStyles = makeStyles((theme) => ({
  sticky: {
    position: 'sticky',
    width: '91.5%',
    maxWidth: '469px',
    bottom: theme.spacing(2),
    margin: [[ 0, '1em' ]],
    marginTop: '0.5em',
    boxShadow: 'rgba(0, 0, 0, .15) 0 15px 30px',
  },
}));
