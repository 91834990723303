import firebase from '../services/firebase';

const verifiClientId = 'vrfvWPZeHW2aoK5HjOZbow65s63DjLYymeAKxNj';

export async function scanReceipt(id, base64Image) {
  const STAGING_CLOUD_FUNCTIONS_URL='https://us-central1-splyt-staging.cloudfunctions.net/splyt';
  const PROD_CLOUD_FUNCTIONS_URL='https://us-central1-splyt-9f6cc.cloudfunctions.net/splyt';
  const CLOUD_FUNCTIONS_URL = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? PROD_CLOUD_FUNCTIONS_URL
    : STAGING_CLOUD_FUNCTIONS_URL;

  console.log({ CLOUD_FUNCTIONS_URL });

  const response = await fetch(`${CLOUD_FUNCTIONS_URL}/post-to-verifi`, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'apikey eliot1:f1634d992fe1ac317a68bd79c1293839',
      'Access-Control-Allow-Origin': '*',
      'CLIENT-ID': verifiClientId,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({
      file_name: 'invoice.png',
      file_data: base64Image,
      external_id: id,
    }),
  });
  return response.json();
}

/**
 * Get the scanned receipt data
 * @param {String} receiptId 
 * @returns {Promise}
 */
export function getOCRData(receiptId) {
  return firebase.database().ref().child(`ocrData/byId/${receiptId}`).once('value')
    .then(snapshot => snapshot.val());
}

/**
 * Creates a scanned check object
 * @param {Object} receipt 
 * @returns {Promise}
 */
export function saveOCRData(id, receipt) {
  return firebase.database().ref(`ocrData/byId/${id}`).set(receipt);
}
