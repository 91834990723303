import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from '../services/firebase';
import SuccessReceipt from './SuccessReceipt';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

// const TEST_URL = 'http://localhost:5000/splyt-9f6cc/us-central1/splyt'
const BASE_URL = 'https://us-central1-splyt-9f6cc.cloudfunctions.net/splyt';

const useStyles = makeStyles({
  root: {
    '& > *': {
      marginBottom: '1em',
      marginTop: '1em',
    },
    padding: [[0, 10]]
  },
  successHeader: {
    fontSize: '2em',
    textAlign: 'center',
    marginBottom: '1em',
  },
})

const Success = () => {
  const [session, setSession] = useState({});
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');
  const [ isLoading, setIsLoading ] = useState(true);
  const [data, setData] = useState({})
  const [myId, setMyId] = useState(null)

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    async function fetchSession() {
      setSession(
        await fetch(`${BASE_URL}/checkout-session?sessionId=${sessionId}`).then((res) => {
          console.log('RES ', res.json)
          return res.json();

        })
      );
    }
    fetchSession();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const checkId = urlParams.get('check_id') ?? null;
    const userId = urlParams.get('user_Id') ?? null;
    setMyId(userId);
    firebase.database().ref().child(`checksById/${checkId}`).once('value').then( snapshot => {
      setData(snapshot.val())
      setIsLoading(false)
    }).catch(e => {
      console.log('ERROR ', e);
    })
  }, [sessionId]);

  const myItems = useMemo(() => {
    if (data && data.selections) {
      const  { itemsById, selections, guests } = data;

      return Object.keys(selections).reduce((result, itemId) => {
        if (itemId === 'initial') { return result }
        return selections[itemId].includes(myId) ? {
          subtotal: result.subtotal + ( Number(itemsById[itemId].price) / selections[itemId].length ),
          items: [
            ...result.items,
            { 
              name: itemsById[itemId].name,
              price: itemsById[itemId].price,
              noOfSharers: selections[itemId].length,
              namesOfSharers: selections[itemId].length > 1 
                ? selections[itemId].reduce((acc, id) => {
                  if (id === myId) return acc;
                  return [ ...acc, guests[id].name ]
                },[])
                : null,
            },
          ]
        } : result;
      }, {subtotal: 0, items: []})
    }
    return [];
  }, [data, myId])

  const tip = useMemo(() => {
    console.log('myID', myId)
    if ( data && data.guests && data.guests[myId] && data.guests[myId].tip) {
      return data.guests[myId].tip
    }
    return 0;
  }, [data, myId])

  return (
    <div className={classes.root}>
      <div className={classes.successHeader}>
        <span>Your payment was successful!</span>
      </div>
      <div className="sr-section completed-view">

        {isLoading ? (
          <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </div>
        ) : (
          <SuccessReceipt
            items={myItems.items}
            subtotal={myItems.subtotal}
            restaurantName={data.restaurantName}
            tip={tip}
            tax={data.tax}
          />
        )}
      </div>
    </div>
  );
};

export default Success;
