import React, { useEffect, useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
// import BaseLoadingMask from "../loadingMasks/BaseLoadingMask";
import { basicPlurify } from "../utils";
import SummaryItemList from "./ocrComponents/ItemsList/SummaryItemList";
import { darkGreen, lightGrey, primaryBorder } from '../style/colors';
import TotalItem from "./SummaryModal/TotalItem/TotalItem";
import { useMySplytTotals } from "../utils/hooks/useMySplytTotals";
import { subscribeToCheck } from "../API/checks";
import AppStoreButtons from "./AppStoreButtons";

function assertIsString(val) {
  if (typeof val !== "string") throw new Error(`Page Malformed`);
}

const StaticSplytSummary = () => {
  const styles = useStyles();
  const { checkId, userId } = useParams();
  assertIsString(checkId);
  assertIsString(userId);

  const [ check, setCheck ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(function getSplyt() {
    setIsLoading(true);
    return subscribeToCheck(checkId, check => {
      setCheck(check);
      setIsLoading(false);
    });
  }, [checkId]);

  const [
    subtotal,
    tip,
    tax,
    total,
    percentageOfSubtotal,
    itemizedFeesAndDiscounts
  ] = useMySplytTotals(check, userId);

  const selections = Object.entries(check?.selections || {})
    .filter(([ _, guestMap ]) => Object.values(guestMap).includes(userId))
    .map(([ itemId, guestMap ]) => ({
      ...check.itemsById[itemId],
      id: itemId,
      sharedWith: Object.keys(guestMap)
        .filter(guestId => guestId !== userId)
        .map(guestId => check.guests[guestId]),
    }));

  const totalsRows = [
    ['Subtotal', subtotal],
    ['Tax', tax],
    [`Tip (${check?.totalInfo?.tipPercent || 0}%)`, tip],
  ];

  if (itemizedFeesAndDiscounts && itemizedFeesAndDiscounts?.length > 0) {
    for (let i = 0; i < itemizedFeesAndDiscounts.length; i++) {
      const myPortionOfItemizedFeeOrDiscount = (Number(itemizedFeesAndDiscounts[i].value) * percentageOfSubtotal).toFixed(2).toString();
      const row = [itemizedFeesAndDiscounts[i].label, myPortionOfItemizedFeeOrDiscount];
      totalsRows.push(row);
    }
  }

  totalsRows.push(['Your Total', total]);
  
  return (
    <div className={styles.root}>
      { !check || isLoading ? (
        null
        // <BaseLoadingMask isLoading={isLoading} />
      ): (
        <>
          <Typography variant="h4" component="h1" align="center">
            { check.restaurantInfo?.restaurantName || 'Receipt' }
          </Typography>
          <Typography variant="h4" component="h2">
            { check.guests[userId].name }
          </Typography>
          <Typography variant="subtitle2" component="p" className={styles.subtitle}>
            { selections.length } { basicPlurify('item', selections.length) }
          </Typography>
          <SummaryItemList
            classes={{ list: styles.list }}
            myItemsIds={selections.map(({ id }) => id)}
            itemsById={check.itemsById}
            guests={check.guests}
            selectedItems={check.selections}
            myId={userId}
            isShowingSuggestions={check.isShowingSuggestions}
          />

          <div className={styles.backgroundBlock} />

          <Typography className={styles.totalsTitle} variant="h4" component="h3">
            Totals
          </Typography>
          { totalsRows.map(([label, value]) => (
            <TotalItem key={label} isLoading={isLoading} label={label} value={value} />
          ))}
          <div className={styles.spacer} />
          <AppStoreButtons source="participant summary" />
        </>
      )}
    </div>
  );
}

export default StaticSplytSummary;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    minHeight: '90vh',
  },
  subtitle: {
    color: darkGreen,
    marginBottom: theme.spacing(1),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  backgroundBlock: {
    backgroundColor: lightGrey,
    border: primaryBorder,
    height: theme.spacing(1),
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  totalsTitle: {
    borderBottom: primaryBorder,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  spacer: {
    flexGrow: 1,
    width: '100%',
    flex: 1,
  },
}));
