import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CountdownNumber from './../Countdown/CountdownNumber';

export default function CountdownTimer({countdownEndDate}:{ countdownEndDate: number | null }) {
  const styles = useStyles();

  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!countdownEndDate) return;

    // Function to update countdown
    const updateCountdown = () => {
      const now = new Date();
      const distance = new Date(countdownEndDate).getTime() - now.getTime();

      if (distance < 0) {
        // Stop the countdown once it reaches 0
        clearInterval(interval);
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
      } else {
        // Calculate and set time left
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeLeft({ hours, minutes, seconds });
      }
    };
    // Start countdown
    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [countdownEndDate]);

  return (
    <div className={styles.root}>
      <CountdownNumber number={timeLeft.hours}/>
      <span className={styles.separator}>:</span>
      <CountdownNumber number={timeLeft.minutes}/>
      <span className={styles.separator}>:</span>
      <CountdownNumber number={timeLeft.seconds} />
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
  },
  separator: {
    color: '#ABA5FF',
    fontFamily: 'poppins',
    fontSize: 36,
    fontWeight: 400,
  },
}));
