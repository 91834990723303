import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles({
  root: props => ({
    background: props.isActive && 'linear-gradient(45deg,#D36BBC 20%, #9A86D7 99%, #7D93E4 40%, #61A1F2 40%, #44AEFF 40%)',
    color: props.isActive && 'white',
    textTransform: 'none',
  }),
  percent: {
    fontSize: '16px',
  },
  dollarAmount: {
    fontSize: '10px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  }
});


const TipButton = ({ label, isActive, handleClick, tipDollarAmountString }) => {
  const classes = useStyles({isActive})
  return (
    <Button
      size="small"
      variant="contained" 
      onClick={handleClick}
      className={classes.root}
    >
      <div className={classes.textContainer}>
        <span className={classes.percent}>{label}</span>
        <span className={classes.dollarAmount}>{`($${tipDollarAmountString})`}</span>
      </div>
    </Button>
  )
}

TipButton.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
  tipDollarAmountString: PropTypes.string,
}

export default TipButton;