export const black = '#000000';
export const blue = '#3672F8';
export const blueMarine = ['#14F1D9', '100%', '#3672F8', '100%'];
export const blueViolet =  'linear-gradient(45deg, #3672F8 100%, #B01EFF, 100%)' 
export const darkBlue ='#212C4F';
export const darkGreen ='#205284';
export const lightBlue = '#C6D0EB';
export const lightGrey ='#E8EBED';
export const darkGrey = '#8D8E8F';
export const borderGrey = '#C4C4C4';
export const payGreen ='#0ACF83';
export const purple ='#7B42F6';
export const lightPurple = '#825ed1';
export const purpleViolet = 'linear-gradient(45deg, #7B42F6 100%, #B01EFF, 100%)' 
export const violet ='#B01EFF';
export const violetPink = 'linear-gradient(45deg, #B01EFF 100%, #E1467C, 100%)' 
export const white = '#FFFFFF';
export const primaryBorder = '1px solid #E8EBED';
export const powder = '#F9F8FF';
