import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import OcrReceiptContainer from './components/OcrReceiptContainer';
import ContactlessSuccess from './components/contactlessComponents/ContactlessSuccess';
import VerifyContainer from './components/VerifyContainer';
import PlacesContainer from './containers/PlacesContainer';
import CurrentUserPlacesContainer from './containers/CurrentUserPlacesContainer';
import Header from './components/Header/Header';
import Success from './components/Success';
import OrderEntry from './components/OrderEntry';
import Upload from './components/Upload';
import AuthProvider from './context/AuthProvider';

import './App.css';
import StaticSplytSummary from './components/StaticSplytSummary';
import { Button } from '@material-ui/core';

const Cancel = () => <h1>Cancel</h1>
const Failure = () => <h1>Failure</h1>

const TEXT_LENGTH = 175;
const TEXT_HEIGHT = 16;
const OFFSET = TEXT_LENGTH / 2 - TEXT_HEIGHT / 2;

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <a href="https://www.instagram.com/splyt.co/" target="blank" style={{textDecoration: 'none', color: 'white'}}>
          <div
            style={{
              position: 'sticky',
              top: '45%',
              transform: `translate(-${OFFSET}px) rotate(90deg)`,
              backgroundImage: 'url(/instagram.png)', /* Replace with the actual path to your image */
              backgroundSize: 'cover', /* Adjust this property based on your image dimensions */
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              width: TEXT_LENGTH,
              height: TEXT_HEIGHT,
              zIndex: 1000000,
              color: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              borderTopRightRadius: 4,
              borderTopLeftRadius: 4,
            }}>
            <img style={{width: 14, height: 14, paddingRight: 6}}src="/instagram-logo-white.png" alt="Instagram logo" />
            Give Feedback
          </div>
        </a>
        <Switch>
          <Route exact path="/">
            <CurrentUserPlacesContainer />
          </Route>
          <Route path="/canceled">
            <Cancel/>
          </Route>
          <Route path="/success">
            <Success/>
          </Route>
          <Route exact path="/contactless/success">
            <ContactlessSuccess />
          </Route>
          <Route path="/failure">
            <Failure />
          </Route>
          <Route path="/order-entry">
            <OrderEntry />
          </Route>
          <Route path="/upload">
            <Upload />
          </Route>
          <Route path="/places/:userId">
            <PlacesContainer />
          </Route>
          <Route path="/splyt/ocr/:checkId/summary/:userId">
            <StaticSplytSummary />
          </Route>
          <Route path="/splyt/ocr/:checkId" render={() => (
            <OcrReceiptContainer />
          )} />
          <Route path="/demo/splyt/ocr/:checkId" render={() => (
            <OcrReceiptContainer isDemo />
          )} />
          <Route path="/verify/:checkId" render={() => (
            <VerifyContainer isDemo={true} />
          )} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}
