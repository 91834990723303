import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, TextField, DialogContent, DialogTitle, makeStyles, Button, CircularProgress, Typography } from '@material-ui/core';
import firebase from '../../services/firebase';
import { isObject } from 'lodash';
import { blue } from '../../style/colors';
const { auth } = firebase;

type OTPModalProps = {
	phone: string,
	onSuccess: (cred: firebase.auth.UserCredential) => void,
	isOpen: boolean,
  confirmation?: firebase.auth.ConfirmationResult,
	recaptcha: firebase.auth.RecaptchaVerifier | undefined,
}

export default function OTPModal({
  confirmation,
  phone,
  onSuccess,
  isOpen,
  recaptcha,
}: OTPModalProps) {
  const classes = useStyles();
  const [ otp, setOTP ] = useState('');
  const [ errorMessage, setErrorMessage ] = useState('');
  // this is to support resending confirmation codes
  const [ innerConfirmation, setInnerConfirmation ] = useState(confirmation);
  useEffect(function syncConfirmation() {
    setInnerConfirmation(confirmation);
  }, [confirmation]);

  const [ isProcessing, setIsProcessing ] = useState(false);
  async function verifyOTP() {
    if (isProcessing || !innerConfirmation) return;
    setIsProcessing(true);
    setErrorMessage('');
    try {
      const credentials = await innerConfirmation.confirm(otp);
      onSuccess(credentials);
    } catch (err) {
      if (isAuthError(err)) {
        // we cane check against err.code here if need be for custom stuff
        setErrorMessage(err.message);
      } else if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('Something went wrong');
      }
    }
    setIsProcessing(false);
  }

  const [ resetCoolDown, setResetCoolDown ] = useState(10);
  const clock = useRef<ReturnType<typeof setInterval>>();
  const resetCoolDownClock = useCallback(() => {
    clearInterval(clock.current);
    setResetCoolDown(10);
    clock.current = setInterval(() => {
      setResetCoolDown(prev => {
        const value = Math.max(0, prev - 1);
        if (value === 0) clearInterval(clock.current);
        return value;
      });
    }, 1000);
  }, []);
  useEffect(resetCoolDownClock, [resetCoolDownClock]);

  async function resendOTP() {
    if (isProcessing) return;
    if (resetCoolDown !== 0) return;
    if (!recaptcha) throw new Error('Recaptcha initialization failed');
		
    resetCoolDownClock();

    setIsProcessing(true);
    auth().useDeviceLanguage();
    /** cSpell:ignore grecaptcha */
    /** @ts-expect-error Not bothering with this */
    recaptcha.render().then(widgetId => window.grecaptcha.reset(widgetId));
    try {
      const confirmResult = await auth().signInWithPhoneNumber(phone, recaptcha);
      setInnerConfirmation(confirmResult);
    } catch (err) {
      if (isAuthError(err)) {
        // we cane check against err.code here if need be for custom stuff
        setErrorMessage(err.message);
      } else if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('Something went wrong');
      }
    }
    setIsProcessing(false);
  }

  return (
    <Dialog className={classes.root} open={isOpen} aria-labelledby="otp-title">
      <DialogTitle id="otp-title">
        <Typography variant="h3">Verify your number</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body2">
					Please enter the 6-digit code we&apos;ve sent to <b>{ phone }</b>
        </Typography>
        <TextField
          variant="outlined"
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
          helperText={errorMessage}
          error={!!errorMessage}
        />
        <Typography variant="body2">
          {/* cSpell:ignore didn */}
					Didn&apos;t get a code?&nbsp;
          <button
            type="button"
            className={classes.asLink}
            onClick={resendOTP}
            disabled={resetCoolDown !== 0}
          >
            { resetCoolDown === 0 ? 'Resend it.' : `Resend in ${resetCoolDown} seconds` }
          </button>
        </Typography>
        <Typography variant="body2">
					By clicking <span className={classes.upperCase}>I agree</span>,
					you agree to our&nbsp;
          <a href="https://splyt.webflow.io/terms-of-service" rel="noreferrer" target="_blank">Terms</a>
					&nbsp;&amp;&nbsp;
          <a href="https://splyt.webflow.io/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a>
					&nbsp;and consent to receive text messages from us on behalf of organizers.
					Messages and data rates apply. Text&nbsp;
          <span className={classes.upperCase}>stop</span>
					&nbsp;to cancel.
        </Typography>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.upperCase}
          onClick={verifyOTP}
          startIcon={isProcessing ? <CircularProgress style={{ color: 'white' }} size={20} /> : null}
          disabled={otp.length !== 6}
        >
					I Agree
        </Button>
      </DialogContent>
    </Dialog>
  );
}

// firebase.auth.Error isn't exposed in the typescript definitions
// so this is the only way to check for the error type
function isAuthError(err: unknown): err is { name: string, code: string, message: string } {
  return isObject(err)
		&& 'code' in err
		&& typeof err.code === 'string' 
		&& 'message' in err
		&& typeof err.message === 'string'
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  asLink: {
    border: 'none',
    background: 'none',
    color: blue,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  }
}));
