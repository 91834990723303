import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import DrawerModal from './DrawerModal';
import LightBlueButton from '../ThemedComponents/ThemedButtons/LightBlueButton';
import { darkGreen } from '../../style/colors';

const propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOpenInvite: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    textAlign: 'center',
    fontSize: '3rem',
  },
  title: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&>span': {
      fontSize: '1.125rem',
      fontWeight: 900,
      fontStyle: 'italic',
    },
  },
  desc: {
    ...theme.customType.mediumText,
    color: darkGreen,
    marginBottom: theme.spacing(4),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    '&>span': {
      ...theme.customType.mediumText,
      fontWeight: 700,
      fontStyle: 'italic',
    },
  },
}));

const SplytCreatedInstructionModal = ({
  isOpen,
  onClose,
  onOpenInvite,
  onOpen,
}) => {
  const classes = useStyles();

  return (
    <DrawerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <span className={classes.icon} role="img" aria-label="Partying Face Emoji">🥳</span>
      <Typography align="center" className={classes.title} component="h2">
        Congrats, your <Typography component="span">splyt</Typography> is now ready!
      </Typography>
      <Typography align="center" className={classes.desc} component="p">
        Invite others to select their items. <Typography component="span">splyt</Typography> works best when everyone at the table.
      </Typography>

      <LightBlueButton
        text="Invite Your Group"
        handleClick={onOpenInvite}
      />
    </DrawerModal>
  );
};

SplytCreatedInstructionModal.propTypes = propTypes;
export default SplytCreatedInstructionModal;
