import { createMuiTheme } from '@material-ui/core/styles';
import {
  blue,
  darkGreen,
  darkBlue
} from './colors';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: darkBlue,
    },
  },
  customType: {
    normalText: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    semiBold: {
      fontWeight: 600,
    },
    normalTextSemiBold: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    caption: {
      textTransform: 'uppercase',
    },
    mediumText: {
      fontSize: '.875rem',
      lineHeight: '1.5rem',
      fontWeight: 400,
    },
    smallText: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
  },
  typography: {
    fontFamily: [
      'poppins',
      'Verdana',
      'sans-serif',
      'Helvetica Neue',
    ].join(','),
    h2: {
      fontSize: '2.625rem',
      lineHeight: '3rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.625rem',
      lineHeight: '3rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '.75rem',
      lineHeight: '1rem',
      fontWeight: 700,
    },
  }
});

export default theme

/*
Palette
Typography
Spacing
Breakpoints
z-index
Globals

Palette
  primary - used to represent primary interface elements for a user. It's the color displayed most frequently across your app's screens and components.
  secondary - used to represent secondary interface elements for a user. It provides more ways to accent and distinguish your product. Having it is optional.
  error - used to represent interface elements that the user should be made aware of.
  warning - used to represent potentially dangerous actions or important messages.
  info - used to present information to the user that is neutral and not necessarily important.
  success - used to indicate the successful completion of an action that user triggered.
*/
