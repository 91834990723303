import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    marginBottom: '1em',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginBottom: '.5em'
    }
  },
  restaurantName: {
    fontSize: '1.5em',
  },
});


export default function RestaurantInfo({
  restaurantInfo: {
    restaurantName,
    restaurantPhone,
    serverName,
    checkNumber,
    restaurantAddress,
  }
}) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.restaurantName}>{restaurantName}</div>
        <div>{restaurantAddress}</div>
        {/* <div>{street}, {city}, {state} {zip}</div>
        <div>{restaurantPhone}</div> */}
      </div>
    </div>
  );
}


