import * as mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import firebase from '../../services/firebase';
import AskPhoneNumberModal from './AskPhoneNumberModal';
import OTPModal from './OTPModal';

type SignUpModalProps = {
	title?: string,
	subtitle?: string,
	open?: boolean,
  isModSignup?: boolean,
  selectedEmoji?: string,
  isModSubscriber?: boolean,
	onLogin?: () => void,
  selectEmoji?: (emoji: string | null | undefined) => void,
}

type State = {
	type: 'phone',
	confirmation: undefined,
} | {
	type: 'code',
	confirmation: firebase.auth.ConfirmationResult,
} | {
	type: 'closed',
	confirmation: undefined,
};

const SignUpModal = ({
  title = 'Sign In',
  subtitle = 'Please Sign In Or Register',
  open = false,
  isModSignup = false,
  selectedEmoji,
  isModSubscriber,
  onLogin,
  selectEmoji,
}: SignUpModalProps) => {
  const [ form, setForm ] = useState({ mode: 'signup' as 'signup' | 'login', name: '', phone: '' });
  const [ state, setState ] = useState<State>({ type: 'closed', confirmation: undefined });

  useEffect(function handlePropsOpenChange() {
    if (!open) setState({ type: 'closed', confirmation: undefined });
    else setState({ type: 'phone', confirmation: undefined });
  }, [open]);
	
  function handleContinue(confirmation: firebase.auth.ConfirmationResult) {
    setState({ type: 'code', confirmation });
  }

  async function onSuccessfulLogin(cred: firebase.auth.UserCredential) {
    if (form.mode === 'signup') {
      mixpanel.people.set({ "$name": form.name });
      if (!cred.user) return;
      await firebase.firestore().collection('users').doc(cred.user.uid).set({ name: form.name }, { merge: true });
    }
    setState({ type: 'closed', confirmation: undefined });
    onLogin?.();
  }
	
  const [ recaptcha, setRecaptcha ] = useState<firebase.auth.RecaptchaVerifier>();
  useEffect(function bindRecaptcha() {
    setRecaptcha(new firebase.auth.RecaptchaVerifier('recaptcha-sign-in-hook', { size: 'invisible' }));
  }, []);

  return (
    <>
      <AskPhoneNumberModal
        isOpen={state.type === 'phone'}
        title={title}
        subtitle={subtitle}
        form={form}
        isModSignup={isModSignup}
        onUpdateForm={setForm}
        onContinue={handleContinue}
        selectEmoji={selectEmoji}
        selectedEmoji={selectedEmoji}
        recaptcha={recaptcha}
        isModSubscriber={isModSubscriber}
      />
      <OTPModal
        confirmation={state.confirmation}
        isOpen={state.type === 'code'}
        onSuccess={onSuccessfulLogin}
        phone={form.phone}
        recaptcha={recaptcha}
      />
      <span id="recaptcha-sign-in-hook" />
    </>
  );
}

export default SignUpModal;
