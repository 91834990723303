import React, { useState, useCallback, useEffect } from "react";
import clsx from 'clsx';
import { NumberFormatCustom, formatPriceStringForInputValue, formatPriceInputValueIntoStateString } from '../ItemEdit';
import { formatPriceStringForDisplay } from '../../VerifyContainer';
import TipButtons from '../TipButtons/TipButtons';
import { green } from '@material-ui/core/colors';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

const useStyles = makeStyles({
  root: {
    '& > *': {
      margin: '4px',
    },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1em',
  },
  paddingBottom: {
    padding: '1em 0'
  },
  button: {
    backgroundColor: 'green',
  },
  padded: {
    padding: '0 1em'
  },
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: '2em',
    alignItems: 'center',
  },
  MuiInputBase: {
    width: '140px'
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  labelsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.5em',
  },
  label: {
    fontWeight: 700,
    marginRight: '.5em',
  },
  total: {
    fontSize: '1.5em',
  }
});

const PriceForm = ({ price, field, handleChange, mixpanelString }) => {

  const handleFocus = useCallback(() => {
    if (mixpanelString) {
      mixpanel.track(mixpanelString)
    }
  }, [mixpanelString])
  return (
    <div style={{width: '9em'}}>
      <FormControl size="small" variant="outlined">
        <OutlinedInput
          value={formatPriceStringForInputValue(price)}
          onChange={(e) => handleChange(e, field)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          labelWidth={60}
          inputComponent={NumberFormatCustom}
          onFocus={handleFocus}
        />
      </FormControl>
    </div>
  )
}


export default function VerifyTotal({
  totalInfo: {
    subtotal,
    tax,
    tip,
    total,
    tipPercent,
  },
  tipDollarAmountStrings,
  handleTipButtonClick,
  handleInputChange,
  handleSave,
  calculatedTipAmount,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.padded}>
        <div className={clsx(classes.rowSpaceBetween, classes.paddingBottom)}>
          <h3>Subtotal</h3>
          <h3>{formatPriceStringForDisplay(subtotal)}</h3>
        </div>
        <div>
          <div className={classes.rowSpaceBetween}>
            <span>{"+Tax"}</span>
            <PriceForm price={tax} field={'tax'} handleChange={handleInputChange} mixpanelString={'focused on tax input'}/>
          </div>
          <div className={classes.rowSpaceBetween}>
            <span>{"+ Tip"}</span>
            {
              tipPercent !== 'custom' ? (
                <h3>{formatPriceStringForDisplay(calculatedTipAmount)}</h3>
              ) : (
                <PriceForm price={calculatedTipAmount} field={'tip'} handleChange={handleInputChange} mixpanelString={'focused on tipx input'}/>
              )
            }
          </div>
        </div> 
      </div>
      <TipButtons
        handleTipButtonClick={handleTipButtonClick}
        tipPercent={tipPercent}
        dollarAmounts={tipDollarAmountStrings}
        calculatedTipAmount={calculatedTipAmount}
      />
      <div className={classes.rowSpaceBetween}>
        <h3>Grand total:</h3>
        <h3>{formatPriceStringForDisplay(total)}</h3>
      </div>
      <Button
        variant="contained"
        classes={{
          root: classes.button,
        }}
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleSave}
      >
        continue to splyt
      </Button>
      
    </div>
  );
}

const { string, func, bool } = PropTypes;

VerifyTotal.propTypes = {
  totalInfo: PropTypes.shape({
    subtotal: string,
    tax: string,
    tip: string,
    total: string,
  }),
  dollarAmounts: PropTypes.shape({
    '18': PropTypes.string,
    '20': PropTypes.string,
    '22': PropTypes.string,
  }),
  handleTipButtonClick: func,
  handleInputChange: func,
  handleSave: func,
};
