import React from "react";
import { makeStyles } from "@material-ui/core";

export default function CountdownNumber({ number }: { number: number }) {
  const styles = useStyles();

  return (
    <div className={styles.numberContainer}>
      <span className={styles.number}>{number}</span>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  numberContainer: {
    backgroundColor: '#D5D2FF',
    width: 66,
    height: 66,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  number: {
    fontFamily: 'poppins',
    fontWeight: 600,
    color: 'white',
    lineHeight: '44px',
    margin: '0 auto',
    fontSize: 36,
    filter: "drop-shadow(1px 1px #574CFF)",
    WebkitTextStroke: "1px #574CFF"
  },
}));
