import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import LightBlueButton from '../../ThemedComponents/ThemedButtons/LightBlueButton';
import { darkGreen } from '../../../style/colors';
import { formatPriceStringForDisplay } from '../../../utils/NumberUtils';

const propTypes = {
  isModerator: PropTypes.bool,
  numSelected: PropTypes.number,
  recipientName: PropTypes.string,
  total: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1),

  },
  subtitle: {
    fontSize: '.875rem',
    lineHeight: '1.5rem',
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: theme.spacing(2),
    backgroundColor: darkGreen,
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(2),
    fontSize: '1rem',
  },
}));

const OpenSummaryButton = ({
  isModerator,
  recipientName,
  numSelected,
  total,
  ...buttonProps
}) => {
  const classes = useStyles();

  let selectedIcon;
  if (numSelected) {
    selectedIcon = (
      <div className={classes.icon}>{ numSelected }</div>
    );
  }

  return (
    <LightBlueButton
      {...buttonProps}
      startIcon={selectedIcon}
      text={(
        <div>
          <Typography className={classes.subtitle} component="div">
            { isModerator ? 'Done Selecting' : `Pay ${recipientName}`}
          </Typography>
          <Typography className={classes.title} component="div">
            {isModerator && <span>Your </span> }Total &bull; ${ formatPriceStringForDisplay(total) }
          </Typography>
        </div>
      )}
    />
  );
};

OpenSummaryButton.propTypes = propTypes;
export default OpenSummaryButton;
