import React, { ReactNode } from "react";
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";

type ActivityOverlayProps = {
  isLoading?: boolean,
  children?: ReactNode,
	color?: string,
	text?: string,
	isFullScreen?: boolean,
};

export default function ActivityOverlay({
  isLoading = false,
  children,
  color,
  text,
  isFullScreen,
}: ActivityOverlayProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      { children }
      { isLoading && (
        <div className={classes.indicatorWrapper} style={isFullScreen ? { position: 'fixed' } : undefined}>
          <CircularProgress style={{ color }} variant="indeterminate" />
          { text && (
            <Typography variant="h3" style={{ color, textAlign: 'center' }}>
              { text }
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  indicatorWrapper: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,.75)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));
