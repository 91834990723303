import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const propTypes = {
  icon: PropTypes.node,
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  titleComponent: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  root: {

  },
  icon: {

  },
  title: {

  },
  desc: {

  },
}));

const StepText = (props) => {
  const classes = useStyles(props);
  const { icon, title, titleComponent, description } = props;

  return (
    <div className={classes.root}>
      <div className={classes.icon}>{ icon }</div>
      <div className={classes.textWrapper}>
        <Typography className={classes.title} component={titleComponent}>{ title }</Typography>
        <Typography className={classes.desc}>{ description }</Typography>
      </div>
    </div>
  );
};

StepText.propTypes = propTypes;
export default StepText;
