import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import QRCodeDisplay from 'qrcode.react';
import { makeStyles } from "@material-ui/core/styles";
import mixpanel from 'mixpanel-browser';


const useStyles = makeStyles({
  root: {
    padding: '8px 24px 24px 24px',
  },
  closeButton: {
    position: 'absolute',
    right: '.2em',
    top: '.2em',
    color: 'grey',
  },

  qrWrapper : {
    margin: '1.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  title: {
    marginTop: '1em',
    fontWeight: '600',
    textAlign: 'center'
  },

  instructions: {
    textAlign: 'center'
  },

  link: {
    marginTop: '.5em',
    marginBottom: '1.3em',
    textAlign: 'center',
    fontWeight: '600',
    whiteSpace: 'nowrap',
  },
  shareButton: {
    marginBotton: '2em',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

});


//leaving this here for later
async function shareViaOS(e, restaurantName) {
  const title = 'Splyt';
  const text = `Here is your Splyt link for ${restaurantName}`;
  const url = `${window.location.href}?utm_source=osshare`;

  mixpanel.track('clicked share link');

  try {
    await navigator.share({title, text, url});
    //window.alert('Successfully sent share');
  } catch (error) {
    //window.alert('Error sharing: ' + error);
  }
}


function ShareModal({ isOpen, closeShareModal, restaurantName }) {
  const classes = useStyles();
  //mixpanel.track("opened qr share");
  const size = 170;
  const utmURL = `${window.location.href}?utm_source=qrshare`;
  return (
    <div>
      <Dialog open={isOpen} onClose={closeShareModal} aria-labelledby="form-dialog-title">
        <DialogTitle disableTypography className={classes.title}>
          <Typography variant="h5">Invite Others</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeShareModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent classes={{root: classes.root}}>
          <Typography className={classes.instructions} gutterBottom>
            Invite members of your party by having them scan this QR code.
          </Typography>
          <div className={classes.qrWrapper}>
            <QRCodeDisplay value={utmURL} size={size}/>
          </div>
          <Typography className={classes.link} gutterBottom>
            Or
          </Typography>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.shareButton}
              variant="contained"
              color="primary"
              role="button"
              onClick={(e) => shareViaOS(e, restaurantName)}            >
                Share Link
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShareModal;
