import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Topline from './TopLine';
import { formatPriceStringForDisplay } from '../../../VerifyContainer';
import NameInput from './EditFields/NameInput'
import PriceInput from './EditFields/PriceInput'
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MergeTypeIcon from '@material-ui/icons/MergeType';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#1EA7FD',
    '&:focus': {
      outline: 'none',
    },
  },
  edit: {
  },
  extrasRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '2em',
    alignItems: 'center',
    // marginBottom: '.4em',
  },
  blueUnderline: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textUnderlineOffset: '1px'
  },
  buttonRow: {
    display: 'flex',
    marginTop: '1em',
    paddingLeft: '1.2em',
  },
  first: {
    marginRight: '1em',
  },
  editButton: {
    height: '30px',
  },
  editButonLabel: {
    fontSize: '.8em',
  },
});

const Item = ({
  item,
  handleTap,
  confirmDelete,
  isEditing,
  editingById,
  handleItemDeleteTap,
  deleteItem,
  mergeAsExtra,
  handleChange,
  handleFocus,
  handleBlur,
  addExtraCharge,
  i,
}) => {
  const classes = useStyles()
  const {
    id,
    name,
    qty,
    price,
    extras: {
      descriptionsById = {},
      descriptionIds = [],
      chargesById = {},
      chargeIds = []
    } } = item;

  const editField = useMemo(() => {
    return isEditing ? editingById[id].field : null;
  }, [isEditing, editingById, id])

  return (
    <div className={clsx(classes.root, isEditing && classes.edit)}
      tabIndex={i}
      onFocus={(e) => {handleFocus(e, id)}}
      onBlur={(e) => {handleBlur(e, id)}}
    >
      {/* on blur we */}
      <Topline 
        itemId={id}
        name={name}
        qty={qty}
        price={price}
        confirmDelete={confirmDelete}
        handleDeleteTap={handleItemDeleteTap}
        deleteItem={deleteItem}
        editField={editField}
        isEditing={isEditing}
        handleTap={handleTap}
        mergeAsExtra={mergeAsExtra}
        handleChange={handleChange}
      />
      <div className={classes.extrasRow}>
        { descriptionIds.length > 0 && descriptionIds.map(descId => {
          return editField === 'description' && editingById[id].extra?.id === descId 
            ? (
              <NameInput key={descId} name={descriptionsById[descId]} handleChange={(val) => handleChange(val, id, 'chargeDescription', descId )}/>        
            ) : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                className={classes.blueUnderline}
                onClick={(e) => handleTap(e, id, 'description', descId, 'name')}
                key={descId}>
                {descriptionsById[descId].toLowerCase()}
              </div>
            )
        })}
      </div>
      <div className={classes.extrasRow}>
        { chargeIds.length > 0 && chargeIds.map((chargeId) => {
          const { chargeDescription, chargePrice } = chargesById[chargeId]
          const isEditingThisField = editField === 'charge' && editingById[id].extra?.id === chargeId;
          const chargeEditField = isEditingThisField ? editingById[id]?.extra?.field ?? null : null;
              
          return(
            <React.Fragment key={chargeId}>
              { isEditingThisField && chargeEditField === 'name' 
                ? <NameInput name={chargeDescription} handleChange={(val) => handleChange(val, id, 'extraCharge', chargeId, 'chargeDescription' )}/>
                : (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <span
                    className={classes.blueUnderline}
                    onClick={(e) => handleTap(e, id, 'charge', chargeId, 'name')}>
                    {chargeDescription.toLowerCase()}
                  </span>
                )}
              {
                isEditingThisField && chargeEditField === 'price'
                  ? (
                    <PriceInput
                      // The user action leading up to this showing is clear intent of modifying the price
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus={true}
                      price={chargePrice}
                      handleChange={(val) => handleChange(val, id, 'extraCharge', chargeId, 'chargePrice' )}
                    />
                  )
                  : (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <span
                      className={classes.blueUnderline}
                      onClick={(e) => handleTap(e, id, 'charge', chargeId, 'price')}>
                      {formatPriceStringForDisplay(chargePrice)}
                    </span>
                  )
              }
            </React.Fragment>
          )
        })}
      </div>
      {isEditing && (
        <div className={classes.buttonRow}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => addExtraCharge(id)}
            classes={{root: clsx(classes.editButton, classes.first), label: classes.editButonLabel}}
          >
            Add X-tra charge
          </Button>
          {/* 
            <Button
              variant="contained"
              color="primary"
              startIcon={<MergeTypeIcon />}
              onClick={() => mergeAsExtra(id)}
              classes={{root: classes.editButton, label: classes.editButonLabel}}
            >
              Merge
            </Button>
          */ }
        </div>
      )}
    </div>
  )
}


const ExtraChargePropType = PropTypes.shape({
  chargeDescription: PropTypes.string,
  chargePrice: PropTypes.string,
})

Item.propTypes = {
  item: PropTypes.shape({
    extras: PropTypes.shape({
      chargeIds: PropTypes.arrayOf(PropTypes.string),
      chargesById: PropTypes.objectOf(ExtraChargePropType),
      descriptionIds: PropTypes.arrayOf(PropTypes.string),
      descriptionsById: PropTypes.objectOf(PropTypes.string),
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.string,
    qty: PropTypes.string,
  }),
  handleItemTap: PropTypes.func,
};

export default Item;
