import PropTypes from 'prop-types';

const { shape, string, arrayOf, objectOf, oneOfType, number } = PropTypes;

export const itemShape = shape({
  name: string,
  price: string,
  extras: shape({
    descriptionIds: arrayOf(string),
    descriptionsById: objectOf(string),
  })
});

export const selectedItemsShape = shape({
  id: arrayOf(string),
});

export const guestShape = shape({
  didCheckout: PropTypes.bool,
  name: PropTypes.string,
});

export const moneyType = oneOfType([
  string,
  number,
]);
