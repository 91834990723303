import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { darkGreen, lightGrey, primaryBorder } from '../../../style/colors';
import SplytDownloadBanner from '../../../components/SplytDownloadBanner';
import firebase from '../../../services/firebase';
import AvatarV2 from "../guestAvatars/AvatarV2";

import { join, map, words, upperFirst } from 'lodash';
import { buttonPressed, pageViewed } from '../../../API/mixpanel';

function capitalizeWords(sentence: string | undefined): string {
  // Check if the sentence is undefined or empty
  if (!sentence) return '';

  return join(map(words(sentence), upperFirst), ' ');
}

const TRANSITION_PROPS = { direction: 'right' };
type GuestListProps = {
  isOpen: boolean,
  onClose: () => void,
  guests: any,
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: '.2em',
    top: '.2em',
    color: 'grey',
    zIndex: 100,
  },
  subtitle: {
    color: darkGreen,
    marginBottom: theme.spacing(1),
  },
  backgroundBlock: {
    backgroundColor: lightGrey,
    border: primaryBorder,
    height: theme.spacing(1),
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  totalsTitle: {
    borderBottom: primaryBorder,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
}));

const useLoadingClasses = makeStyles(theme => ({
  titleSize: {
    width: '50%',
    height: '1.5rem',
  },
  normalTextSize: {
    width: '30%',
    height: '1rem',
    marginBottom: theme.spacing(1),
  },
  item: {
    borderTop: primaryBorder,
    borderBottom: primaryBorder,
    marginBottom: theme.spacing(2),
  },
}));

export type Profile = {
  friendCount?: number,
  friendRequestsCount?: number,
  friendIds?: string[],
	defaultListId?: string,
	favoriteListId?: string,
	wantToGoListId?: string,
  contactFollowerIds?: string[],
  contactIds?: string[],
	numberOfChecksModerated?: number,
	numberOfSplytsJoined?: number,
	hasCard?: boolean,
	handle?: string,
	name?: string,
  emoji?: string,
};

export type SmartGuestType = {
  [id: string]: {
    didCheckout: boolean,
    name: string,
    avatar: string,
    display?: string,
    color?: string,
    id: string,
  }
};
export type GuestWithProfile = Profile & { userId: string, avatar: string};

const getUserProfiles = async (guests: SmartGuestType, cb: (guests: GuestWithProfile[]) => void) => {
  // Create an array to store all the promise queries
  const userIds = Object.keys(guests);
  try {
    const results: GuestWithProfile[] = [];
    await Promise.all(userIds.map(async (userId) => {
      const profile = await firebase.firestore().collection('users').doc(userId).get();
      if (profile.exists) {
        results.push({...profile.data(), avatar: guests?.[userId]?.avatar ?? 'avocado', userId} as GuestWithProfile);
      }
    }));
    cb(results);
  }
  catch(e) {
    console.error(e);
  }
};

const GuestList = ({
  isOpen,
  guests,
  onClose,
}: GuestListProps) => {
  const classes = useStyles();
  const [ isLoading, setLoading ] = useState(false);
  const [ profiles, setProfiles ] = useState<GuestWithProfile[]>([]);

  useEffect(function trackOpen() {
    isOpen && pageViewed({ page: 'GuestList', url: `${window.location.pathname}/guest-list` });
  }, [isOpen]);

  useEffect(() => {
    getUserProfiles(guests, (profiles) => {setProfiles(profiles)});
  }, [guests])

  const sortedGuestList = useMemo(() => {
    if (profiles.length === 0) return [];
		
    return profiles.sort((a, b) => {
      // If a has a handle and b doesn't, a should come first
      if (a.handle && !b.handle) return -1;
		
      // If b has a handle and a doesn't, b should come first
      if (b.handle && !a.handle) return 1;
		
      // If both a and b have handles or neither do, first sort by numberOfSplytsCreated
      if (a.numberOfChecksModerated && b.numberOfChecksModerated) {
        if (a.numberOfChecksModerated !== b.numberOfChecksModerated) {
          return (b?.numberOfChecksModerated ?? 1) - (a?.numberOfChecksModerated ?? 1);
        } else {
          return (b?.numberOfSplytsJoined ?? 1) - (a?.numberOfSplytsJoined ?? 1);
        }
      } else if (a.numberOfChecksModerated) {
        return -1;
      } else if (b.numberOfChecksModerated) {
        return 1;
      }
      // If they have the same numberOfSplytsCreated or neither has it, sort by numberOfSplyts
      return (b?.numberOfSplytsJoined ?? 1) - (a?.numberOfSplytsJoined ?? 1);
    });
  }, [profiles]);

  function trackButtonPress(item: GuestWithProfile) {
    buttonPressed({
      buttonName: 'guest avatar',
      source: 'splyt guest list',
      meta: { guestId: item.userId },
    });
  }
  
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Slide}
      // @ts-ignore This works - don't see why it's complaining
      TransitionProps={TRANSITION_PROPS}
      aria-live="polite"
      aria-busy={isLoading}
    >
      {/* { isLoading && <ScreenReaderLoadingIndicator /> } */}
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h2" align="center">Guest List</Typography>
      </DialogTitle>
      <SplytDownloadBanner countdownEndDate={null}/>
      <DialogContent className={classes.content}>        
        <div style={{ display: 'flex', flexDirection:'column', paddingTop: '16', gap: 16}}>
          {sortedGuestList.length > 0 && (sortedGuestList.map((guest, i) => {
            return (
              <div
                style={{ display: 'flex', flexDirection: 'row'}}
                key={`guest-list-avatar${i}`}
                onClick={() => trackButtonPress(guest)}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    trackButtonPress(guest);
                  }
                }}
              >

                <AvatarV2
                  key={guest.userId} 
                  uid={guest.userId}
                  name={guest?.name ?? 'Guest'} 
                  defaultImage={`/emojis/${guest.avatar}.png`} 
                  showName={false}
                  textColor="black"
                  size="medium"
                />
               
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 8}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

                    <Typography style={{fontWeight: 'bold'}} component="span" align="center">{capitalizeWords(guest.name)}</Typography>
                    { guest.handle &&	(
                      <>
                        <Typography style={{fontSize: 6, marginLeft: 8, marginRight: 8}} component="span" align="center">{'\u2B24'}</Typography>
                        <Typography component="span" align="center">{'\u0040'}</Typography>
                        <Typography component="span" align="center">{guest.handle}</Typography>

                      </>
                    )
                    }
                  </div>
                  <div style={{flexDirection: 'row',}}>
                    { guest.numberOfChecksModerated && (
                      <Typography  style={{fontSize: 14, fontStyle: 'italic'}} component="span" align="center">{guest.numberOfChecksModerated} splyts created</Typography>
                    )}
                    { guest.numberOfChecksModerated && guest.numberOfSplytsJoined && (
                      <Typography style={{fontSize: 14, fontStyle: 'italic'}}  component="span" align="center">{' \u0026 '}</Typography>

                    )}
                    <Typography style={{fontSize: 14, fontStyle: 'italic'}} component="span" align="center">{guest?.numberOfSplytsJoined ?? 1} splyts joined</Typography>

                  </div>
                </div>
              </div>
              
            )
          }))}
        </div>
        
        
        
      </DialogContent>
    </Dialog>
  );
};

export default GuestList;