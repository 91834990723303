import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function LoadingDialog({ open }) {


  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title">
        <DialogTitle id="loadingDialog">Loading</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Our AI friend is processing your receipt, This should be quick, but please allow up to one minute for them to finish.
            <br></br>
            When they&apos;re finished you will be redirected to a screen where you can check their results and fix any mistakes they may have made.
            <br></br>
            If you refresh the page you will need to start over!
          </DialogContentText>
          <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}