import React, { ReactNode } from "react";

export type IRingAdornment = { type: 'ring', color: string | 'rainbow', width: number };
type RingAdornmentProps = Omit<IRingAdornment, 'type'> & {
	children: ReactNode,
};

export default function RingAdornment({
  color,
  width,
  children,
}: RingAdornmentProps) {
  return (
    <div style={{ position: 'relative' }}>
      { children }
      { color === 'rainbow' ? (
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}>
          <svg viewBox="0 0 100 100" height="100%" width="100%">
            <linearGradient
              id="gradient"
              gradientTransform="rotate(120.88)"
              x1="-49.83%" x2="109.02%"
            >
              <stop offset="-41.93%" stopColor="#ABA5FF" />
              <stop offset="-8.12%" stopColor="#FEAD95" />
              <stop offset="37.48%" stopColor="#F7FEAC" />
              <stop offset="68.93%" stopColor="#FFC7F4" />
              <stop offset="109.02%" stopColor="#ABA5FF" />
            </linearGradient>
            <circle
              cx="50"
              cy="50"
              r={50 - width / 2}
              stroke="url(#gradient)"
              strokeWidth={width}
              fillOpacity={0}
            />
          </svg>
        </div>
      ) : (
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: '50%',
          borderColor: color,
          borderWidth: width,
          borderStyle: 'solid',
        }} />
      )}
    </div>
  );
}