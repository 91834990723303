import React from 'react';
import { makeStyles } from '@material-ui/core';
import { lightGrey } from '../../style/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: lightGrey,
    animation: '$pulse 2s infinite',
    animationDelay: '.5s',
    width: '100%',
    borderColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  '@keyframes pulse': {
    '0%': { opacity: 1 },
    '50%': { opacity: .4 },
    '100%': { opacity: 1 },
  },
});

const BaseLoadingMask = (props) => {
  const classes = useStyles(props);

  return (
    <div aria-hidden={true} className={classes.root} />
  );
}

export default BaseLoadingMask;
