import { isNil } from 'lodash-es';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { signOut, subscribeToLoginChanges, subscribeToProfile, subscribeToUser, signInAnonymously} from '../API/users';
import AuthContext from './AuthContext';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const AuthProvider = ({ children }) => {
  const [ isSignedIn, setIsSignedIn ] = useState(true); // Local signed-in state.
  const [ user, setUser ] = useState(null);
  const [ userFromDb, setUserFromDb ] = useState({});
  const [ myId, setMyId ] = useState(null);
  const [ isAuthenticating, setIsAuthenticating ] = useState(true);
  const [ profile, setProfile ] = useState(undefined);

  // signOut();

  useEffect(function listenToLoginChanges() {
    const timeStart = Date.now();
    return subscribeToLoginChanges(authedUser => {
      console.debug('AUTHED USER ', authedUser, Date.now() - timeStart);

      if (isNil(authedUser)) {
        setIsSignedIn(false);
        setUser(null);
        setIsAuthenticating(false);
        setMyId(null);
        setUserFromDb({});
        setProfile(undefined);
      } else {
        const { uid } = authedUser;
        setMyId(uid);
        setUser(authedUser);
        setIsSignedIn(true);
        mixpanel.identify(uid);
        mixpanel.people.set_once({
          '$phone': authedUser.phoneNumber,
          'id': uid,
        });
      }

      if (window.location.pathname.indexOf('/demo/') === 0) {
        if (isNil(authedUser)) {
          signInAnonymously().then(() => {
            mixpanel.people.set_once({ fromDemo: true });
          })
        }
      } else if (window.location.pathname.includes('upload')){
        if (!isNil(authedUser)) {
          if (authedUser.isAnonymous === true) {
            signOut();
          } else {
            mixpanel.track("user signed in from upload", { "userId": authedUser.uid });
          }
        }
      } else if (window.location.pathname.indexOf('/splyt/ocr/') === 0) {
        if (!isNil(authedUser) && authedUser.isAnonymous) {
          signOut();
          window.location.reload();
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!user?.uid) return;
		
    return subscribeToUser(user.uid, userData => {
      setUserFromDb(userData);
      setIsAuthenticating(false);
      if (userData === null) {
        console.debug('New user signed up')
        const today = new Date(Date.now());
        const dateString = today.toISOString();
        mixpanel.people.set_once({
          first_login: dateString,
          $created: dateString,
          $distinct_id: user.uid,
        });
      }
    });
  }, [user?.uid]);

  useEffect(function syncProfile() {
    if (!user?.uid) return;
    return subscribeToProfile(user.uid, setProfile);
  }, [user?.uid]);

  const composedContext = {
    isSignedIn, 
    setIsSignedIn,
    myId,
    setMyId,
    user,
    userFromDb,
    profile,
    setUser, 
    isAuthenticating,
  }

  return (
    <AuthContext.Provider value={composedContext}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = propTypes;
export default AuthProvider;
