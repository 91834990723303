import React, { ReactNode } from "react";
import { ReactComponent as CrownSVG } from "../../../assets/images/crown.svg";

export type ICrownAdornment = { type: 'crown', tilt?: number };
type CrownAdornmentProps = Omit<ICrownAdornment, 'type'> & {
	children: ReactNode,
};

export default function CrownAdornment({
  tilt = 0,
  children,
}: CrownAdornmentProps) {
  return (
    <div style={{ position: 'relative' }}>
      { children }
      <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, transform: `rotate(${tilt}deg)` }}>
        <div style={{ position: 'absolute', top: '-30%', right: '25%', bottom: '90%', left: '25%' }}>
          <CrownSVG />
        </div>
      </div>
    </div>
  );
}