import { useMemo } from "react";


export function getItemizedFeesAndDiscounts(appliedFees, myPercentageOfSubtotal) {
  const feeIds = Object.keys(appliedFees);
  const result = [];
  if (feeIds.length === 0) {
    return [];
  }

  if (Object.keys(appliedFees).length > 0) {
    Object.keys(appliedFees).forEach((feeId) => {
      const fees = [];
      const discounts = [];
      const feeAndDiscountObject = appliedFees[feeId];
      const hasFee = feeAndDiscountObject?.fee?.amount && Number(feeAndDiscountObject?.fee?.amount ?? '0') !== 0;
      const hasDiscount = feeAndDiscountObject?.discount?.amount && Number(feeAndDiscountObject?.discount?.amount ?? '0') !== 0;
      if (hasFee) {
        fees.push({
          label: feeAndDiscountObject?.fee?.name ?? 'Fee',
          value: feeAndDiscountObject?.fee?.amount ?? '0',
        });
      }
      if (hasDiscount) {
        // const myPortionOfDiscount = (Math.round(Number(feeAndDiscountObject?.discount?.amount ?? '0') * myPercentageOfSubtotal)).toString();

        discounts.push({
          label: feeAndDiscountObject?.discount?.name ?? 'Discount',
          value: feeAndDiscountObject?.discount?.amount ?? '0',
        });
      }
      result.push(...fees);
      result.push(...discounts);
    });
    return result;
  }
  return [];
}

const DEFAULT = [ '000', '000', '000', '0.00', 0, []];
export function getMySplytTotals(checkData, myId) {
  const { totalInfo, itemIds, itemsById, selections } = checkData || {};
  if (totalInfo && itemIds) {
    const myItemsIds = itemIds?.filter(itemId => (
      (selections?.[itemId] ?? []).includes(myId)
    )) ?? [];

    const mySelections = myItemsIds.reduce((agg, itemId) => {
      if(selections[itemId].includes(myId)) {
        const item = itemsById[itemId];
        const { price } = item;
        const splitByHowManyPeople = item?.splitByHowManyPeople ?? 1;
        let extrasTotal = 0;
        const chargeIds = item?.extras?.chargeIds ?? null;

        if ( !!chargeIds && chargeIds.length > 0 ) {
          const chargesById = item.extras?.chargesById;
          extrasTotal = chargeIds.reduce((acc, chargeId) => {
            const chargePriceString = chargesById?.[chargeId].chargePrice;
            const chargePrice = Number(chargePriceString);
            return acc + chargePrice;
          }, 0);
        }

        const totalPriceString = ((Number(price) / splitByHowManyPeople) + extrasTotal).toFixed(2);

        return [
          ...agg,
          {
            itemId,
            price: totalPriceString,
            numberOfPeopleWhoSelected: selections[itemId].length,
            splitByHowManyPeople,
          },
        ];
      }
      return agg;
    }, []);

    let subtotal = mySelections
      ? mySelections.reduce((total, item) => {
        const {price, numberOfPeopleWhoSelected} = item;
        const splitByHowManyPeople = item?.splitByHowManyPeople ?? 1;
        const divisor = splitByHowManyPeople > 1 ? 1 : numberOfPeopleWhoSelected;
        return total + ((Number(price)) / divisor )
      }, 0).toString()
      : null;

    subtotal = subtotal ? subtotal.toString() : '000';
    const { subtotal: sub, tip, tax, fees } = totalInfo;
    const appliedFees = fees?.appliedFees ?? {};
    const feesAndDiscountTotal = fees?.feeAndDiscountTotal ?? '0000';
    const subInt = Number(sub);
    const tipInt = Number(tip);
    const mySubInt = Number(subtotal);

    const myPercentageOfSubtotal = mySubInt / subInt;
    const myTip = mySubInt < 0 ? '000' : (tipInt * myPercentageOfSubtotal).toFixed(2);

		
    const feesAndDiscountTotalAsInteger = (Number(feesAndDiscountTotal));
    const myPortionOfFeesAndDiscounts = Math.round(myPercentageOfSubtotal * feesAndDiscountTotalAsInteger).toString();
    const itemizedFeesAndDiscounts = getItemizedFeesAndDiscounts(appliedFees, myPercentageOfSubtotal);
    const taxInt = Number(tax);
    const taxPercent = taxInt / subInt;
    const myTaxTotal = mySubInt < 0 ? '000' : Math.round((taxPercent * mySubInt)).toString();
		
    const myTotal = Number(subtotal) < 0 ? 0 : Number(subtotal) + Number(myTip) + Number(myTaxTotal) + Number(myPortionOfFeesAndDiscounts);
    const myTotalStr = myTotal.toString();
    return [ subtotal, myTip, myTaxTotal, myTotalStr, myPercentageOfSubtotal, itemizedFeesAndDiscounts ];
  } else {
    return DEFAULT;
  }
}

export function useMySplytTotals(checkData, myId = '') {
  return useMemo(() => {
    if (!checkData || !myId) return DEFAULT;
    return getMySplytTotals(checkData, myId);
  }, [checkData, myId]);
}
