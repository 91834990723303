
import React, { ReactNode } from "react";
import CrownAdornment, { ICrownAdornment } from "./CrownAdornment";
import RingAdornment, { IRingAdornment } from "./RingAdornment";

export type Adornment =
	| ICrownAdornment
	| IRingAdornment
;

type AdornmentsProps = {
	adornments?: Adornment[],
	children: ReactNode,
}

export default function Adornments({ adornments, children }: AdornmentsProps) {
  if (!adornments) return children;

  // strips out any duplicate adornments
  const legalAdornments = adornments.reduce<Adornment[]>((acc, adornment) => {
    if (acc.some(a => a.type === adornment.type)) return acc;
    return [...acc, adornment];
  }, []);

  if (legalAdornments.length === 0) return children;

  return (
    <AdornmentsWithLegalAdornments adornments={legalAdornments}>
      { children }
    </AdornmentsWithLegalAdornments>
  );
}

function AdornmentsWithLegalAdornments({ adornments, children }: AdornmentsProps) {
  if (!adornments || adornments.length === 0) return children;

  return (
    <AdornmentsFactory adornment={adornments[0]}>
      <AdornmentsWithLegalAdornments adornments={adornments.slice(1)}>
        { children }
      </AdornmentsWithLegalAdornments>
    </AdornmentsFactory>
  );
}


type AdornmentsFactoryProps = { children: ReactNode, adornment: Adornment };
function AdornmentsFactory({ adornment, children }: AdornmentsFactoryProps) {
  switch (adornment.type) {
  case 'crown': return <CrownAdornment {...adornment}>{ children }</CrownAdornment>;
  case 'ring': return <RingAdornment {...adornment}>{ children }</RingAdornment>;
  default: return null;
  }
}
