import React from "react";
import PropTypes from "prop-types";
import * as analytics from "../API/mixpanel";
import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  appStoreRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  linkButton: {
    textDecoration: 'none',
  },
}));

const propTypes = {
  source: PropTypes.string.isRequired,
};

const AppStoreButtons = ({
  source,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.appStoreRow}>
      <a
        href="https://splytpay.page.link/37sP"
        onClick={() => analytics.clickedDownloadIOSApp(source)}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.linkButton}
      >
        <img src="/es-download-on-app-store.svg" alt="Download on the Apple App Store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.app.splytpay"
        onClick={() => analytics.clickedDownloadAndroidApp(source)}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.linkButton}
      >
        <img height={40} src="/google-play-badge.png" alt="Get it on Google Play" />
      </a>
    </div>
  );
};

AppStoreButtons.propTypes = propTypes;
export default AppStoreButtons;
