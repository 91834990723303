export const updateItemsAlreadyPaidFor = (userId, itemIds, itemsAlreadyPaidFor, selections) => {
  return itemIds.reduce((result, itemId) => {
    const numberOfSelectorsAtTimeOfCheckout = selections[itemId].length;
    if (result[itemId]) {
      const itemPaidForBy = result[itemId].paidForBy;
      return {
        ...result,
        [itemId]: {
          paidForBy: [ ...itemPaidForBy, userId],
          numberOfSelectorsAtTimeOfCheckout,
        },
      }
    }
    return {
      ...result,
      [itemId]: {paidForBy: [ userId ], numberOfSelectorsAtTimeOfCheckout},
    };
  }, { ...itemsAlreadyPaidFor })
};

export const setGuestAvatar = (length, imageStrings) => {
  if (imageStrings.length === 0 ) return 'banana';
  const index = length >= imageStrings.length ? length % imageStrings.length : length;
  return imageStrings[index];
}

export const setGuestColor = (length, colorStrings) => {
  if (colorStrings.length === 0) {
    return 'blue'; // Handle the case when colorStrings is empty by returning null.
  }

  const index = length >= colorStrings.length ? length % colorStrings.length : length;
  return colorStrings[index];
}