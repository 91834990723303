import React, { useRef, useEffect, useCallback } from 'react';
import { Input } from '@material-ui/core';

const QtyInput = ({qty, handleChange}) => {
  const inputRef = useRef(null);
  
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleQtyChange = useCallback((e) => {
    const { value } = e.target;
    const isNum = value === '' || /^\d+$/.test(value)
    if (isNum) {
      handleChange(value)
    }
  }, [handleChange])

  return (
    <Input
      value={qty}
      onChange={(e) => handleQtyChange(e)}
      inputProps={{ 'aria-label': 'description', 'ref': inputRef, style: {'textAlign': 'center'}}} />
  )
}

export default QtyInput;
