import { Opaque, RequireAtLeastOne } from "type-fest";
import firebase from '../services/firebase';

const storeDB = firebase.firestore();

type Page<T> = [T[], (() => Promise<Page<T>> )| undefined];

type PaymentOptionMatrix = {
	venmo: string,
	cashApp: string,
	card: boolean,
};
export type PaymentOptions = keyof PaymentOptionMatrix;
export type ValidPaymentOptionMatrix = RequireAtLeastOne<PaymentOptionMatrix, PaymentOptions>;
export type SplytPayment = {
	status: 'success' | 'processing' | 'failed',
	method: PaymentOptions,
	amount: string,
};

export type Guest = {
	id: string,
	name: string,
	funIcon: string,
};

export type ItemId = Opaque<string, 'itemId'>;
export type Item = {
	id: ItemId,
	name: string,
	price: string,
	qty: number,
	preSplytCount?: number,
	selections: string[],
}

export type FeeId = Opaque<string, 'feeId'>;
export type Fee = {
	id: FeeId,
	name: string,
	price: string,
}

export type DiscountId = Opaque<string, 'discountId'>;
export type Discount = {
	id: DiscountId,
	name: string,
	price: string,
}

export type WithSuggestion<T> = T & {
	suggestion: string,
	type: 'drink' | 'food' | 'miscellaneous',
};
type WithFunSuggestionFeature<T> = T & { allowSuggestions?: boolean } & ({
	isSuggestionsReady: true,
	isShowingSuggestions: boolean,
	lineItems: Record<ItemId, WithSuggestion<Item>>
} | {
	isSuggestionsReady?: false,
	lineItems: Record<string, Item>,
});

export type SplytV2 = WithFunSuggestionFeature<{
	id: string,
	createDate: number,
	updateDate: number,
	moderatorId: string,
	guestsById: string[],
	guests: Record<string, Guest>,
	lineItems: Record<ItemId, Item>,
	fees: Record<FeeId, Fee>,
	discounts: Record<DiscountId, Discount>,
	tax: string,
	tip: string,
	tipPercent: number | 'custom',
	calculatedTotals: {
		subtotal: string,
		fees: string,
		discounts: string,
		total: string,
	},
	restaurant?: {
		id?: string,
		imgUrl?: string,
		name?: string,
		address?: string,
		phone?: string,
	},
	paymentOptions: ValidPaymentOptionMatrix,
	payments: Record<string, SplytPayment>,
}>;

const splytsCollection = () => storeDB.collection('splyts').withConverter({
  toFirestore(data: SplytV2) { return data; },
  fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot<SplytV2>) { return snapshot.data(); }
});

export type SplytsPageQuery = {
	user: string,
	count?: number,
	friendId?: string,
};

function helperPageFunction(
  results: firebase.firestore.QuerySnapshot<SplytV2>,
  query: firebase.firestore.Query<SplytV2>,
  friendId?: string,
): Page<SplytV2> {
  if (results.empty) {
    return [ [], undefined ];
  }
  const feedItems = results.docs.map((doc, index) => {
    const data = doc.data();
    return {...data, gradientIndex: index};
  });

  const lastDoc = results.docs[results.docs.length - 1];
  const next = async () => helperPageFunction(await query.startAfter(lastDoc).get(), query, friendId);
  return [ feedItems, next ];
}

export async function getSplytsPage(query: SplytsPageQuery): Promise<Page<SplytV2>> {
  const friendId = query?.friendId;
  const count = query.count || 20;
  const dbQuery = splytsCollection()
    .where('guestsById', 'array-contains', query.user)
    .orderBy('createDate', 'desc')
    .limit(count);

  return helperPageFunction(await dbQuery.get(), dbQuery, friendId);
}

export async function getUserSplyts(userId: string) {
  const docs = await splytsCollection()
    .where('guestsById', 'array-contains', userId)
    .orderBy('createDate', 'desc')
    .get();
	
  return docs.docs.map(doc => doc.data());
}
