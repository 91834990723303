import { isFunction, isArray } from 'lodash';
import firebase from '../services/firebase';
import { updateItemsAlreadyPaidFor } from './dbUtils';
import { setGuestAvatar, setGuestColor } from './dbUtils';
import { IMAGE_STRINGS, COLOR_STRINGS } from './users';
import { omit } from 'lodash-es';
import { failedToAddEmoji } from '../API/mixpanel'
/** @todo Do something better with this or outright delete it. */
/**
 * @typedef Check
 * @property {String} currency
 * @property {Boolean} didCheckout
 * @property {*} guests
 * @property {*} idsReadyToCheckout
 * @property {Object} isEditingTotal
 * @property {boolean} isEditingTotal.tax
 * @property {boolean} isEditingTotal.tip
 * @property {Array<String>} itemIds
 * @property {Map<String, Item>} itemsById
 * @property {String} mandatoryTip
 * @property {Object} restaurantInfo
 * @property {*} selections
 * @property {String} serviceCharge
 * @property {Object} totalInfo
 * @property {String} totalInfo.subtotal
 * @property {String} totalInfo.tax
 * @property {String} totalInfo.tip
 * @property {String} totalInfo.tipPercent
 * @property {String} totalInfo.total
 * @property {Object} userPaymentInfo
 * @property {String} userPaymentInfo.paymentId
 * @property {String} userPaymentInfo.type
 */

/**
 * @typedef Item
 * @property {String} id
 * @property {String} name
 * @property {String} price
 * @property {Number} qty
 */

/**
 * Put check data at the given location
 * @param {String} checkId
 * @param {Check} check 
 * @returns {Promise}
 */
export function putCheck(checkId, check) {
  return firebase.database().ref(`checksById/${checkId}`).set(check);
}

/**
 * Subscribes to any updates on the check
 * @param {string} checkId 
 * @param {{(check: Check) => void}} onUpdate
 * @returns {function} Unsubscribe method for cleanup
 */
export function subscribeToCheck(checkId, onUpdate) {
  const ref = firebase.database().ref().child(`checksById/${checkId}`);

  const updateWrapper = snapshot => {
    isFunction(onUpdate) && onUpdate(snapshot.val());
  };

  ref.on('value', updateWrapper);
  return () => ref.off('value', updateWrapper);
}

/**
 * Fetches a given check
 * @param {string} checkId 
 * @returns {Promise<Check>}
 */
export function getCheck(checkId) {
  return firebase.database().ref().child(`checksById/${checkId}`).once('value').then(snapshot => snapshot.val());
}

/**
 * Adds or removes a user to a given check item
 * @param {string} checkId 
 * @param {string} itemId
 * @param {string} userId 
 * @returns {Promise}
 */
export const toggleClaimOnLineItem = withUpdateDateChange((checkId, itemId, userId) => {
  return firebase.database().ref().child(`checksById/${checkId}/selections`).transaction(selections => {
    let newSelections = { ...selections || {}};
    

    let newUsersOnItemList = [ ...newSelections[itemId] || [] ];
    if (newUsersOnItemList.includes(userId)) {
      newUsersOnItemList = newUsersOnItemList.filter(id => id !== userId);
    } else {
      newUsersOnItemList.push(userId);
    }
    newSelections[itemId] = newUsersOnItemList;
    
    return newSelections;
  });
});

export const acknowledgeMessageOnCheck = (userId, checkId) => {
  if (!checkId || !userId) return;
  return firebase.database().ref().child(`checksById/${checkId}/messages`).transaction(userMessages => {
    const oldMessages = { ...userMessages || {}};
    let newMessages = {};
    const filteredUserIds = Object.keys(oldMessages).filter(id => id !== userId);
    filteredUserIds.forEach(id => {
      newMessages[id] = oldMessages[id];
    });
    
    return newMessages;
  });
}

/**
 * Adds or removes a user to the specified check items
 * @param {String} checkId 
 * @param {Array<String>} itemIds 
 * @param {Boolean} isSelected 
 * @param {String} userId 
 * @returns {Promise}
 */
export const updateClaimOnItems = withUpdateDateChange((checkId, itemIds, isSelected, userId) => {
  if (!isArray(itemIds)) Promise.reject(new Error(`Expected itemIds to be an array, received ${typeof itemIds}`));
  
  return firebase.database().ref().child(`checksById/${checkId}/selections`).transaction(selections => {
    let newSelections = { ...selections || {}};
    
    itemIds.forEach(itemId => {
      let newUsersOnItemList = [ ...newSelections[itemId] || [] ].filter(id => id !== userId);
      if (isSelected) {
        newUsersOnItemList.push(userId);
      }
      newSelections[itemId] = newUsersOnItemList;
    });
    
    return newSelections;
  });
});

/**
 * Marks the user as finished or unfinished for a given check
 * @param {*} checkId 
 * @param {*} userId 
 * @returns {Promise}
 */
export const toggleCheckFinalized = withUpdateDateChange((checkId, userId) => {
  return firebase.database().ref().child(`checksById/${checkId}/idsReadyToCheckout`).transaction(guestList => {
    let newGuestList;

    if (isArray(guestList)) {
      if (guestList.includes(userId)) {
        newGuestList = guestList.filter(id => id !== userId);
      } else {
        newGuestList = [ ...guestList, userId ];
      }
    } else {
      newGuestList = [ userId ];
    }

    return newGuestList;
  });
});

/**
 * Marks items as paid for by a given user
 * @param {String} checkId 
 * @param {Array<String>} itemIds 
 * @param {Object} selections
 * @param {String} userId 
 * @returns 
 */
export const markItemAsPaidFor = withUpdateDateChange((checkId, itemIds, selections, userId) => {
  return firebase.database().ref().child(`checksById/${checkId}/itemsAlreadyPaidFor`).transaction(itemsAlreadyPaidFor => {
    if (itemsAlreadyPaidFor) {
      return updateItemsAlreadyPaidFor(userId, itemIds, itemsAlreadyPaidFor, selections);
    } else {
      return updateItemsAlreadyPaidFor(userId, itemIds, { initial: ''}, selections);
    }
  });
})

export const updatePaymentForSplyt = (checkId, userId, payment) => {
  if (!payment) return firebase.database().ref(`checksById/${checkId}/payments/${userId}`).remove();
  else return firebase.database().ref(`checksById/${checkId}/payments/${userId}`).set(payment);
};

/**
 * HOF used for transaction type updates to update the updateDate field
 * This expects the first argument to be the checkId
 */
function withUpdateDateChange(func) {
  return (...args) => func(...args)
    .then(res => {
      if (res.committed) {
        // send it and don't make a big stink if it fails
        firebase.database().ref(`checksById/${args[0]}/updateDate`).set(Date.now()).catch(() => {});
      }
      
      return res;
    });
}

export function setHasSeenInstructions(checkId, type, status) {
  return firebase.database().ref(`checksById/${checkId}/seenInstructions`).update({ [type]: status });
}

export function appendGuestV2(checkId, guestId, guestName, selectedEmoji) {
  if (selectedEmoji) {
    void firebase.firestore().collection('users').doc(guestId).set({ emoji: selectedEmoji}, { merge: true }).catch(e => {
      failedToAddEmoji(e)
    });
  }

  return firebase.database().ref(`checksById/${checkId}/guests`).transaction((data) => {
    if (data?.[guestId]) return data;

    const newGuestCount = Object.keys(data || {}).length + 1;
    if (data) {
      const update = {
        ...data,
        [guestId]: {
          name: guestName,
          avatar: selectedEmoji || setGuestAvatar(newGuestCount, IMAGE_STRINGS),
          color: setGuestColor(newGuestCount, COLOR_STRINGS),
        },
      };
      return update;
    }
  });
  // firebase.database().ref(`checksById/${checkId}/updateDate`).set(Date.now()),
}

export const nudgePaymentStatus = {
  UNCONFIRMED: 'unconfirmed',
  DID_NOT_PAY: 'did-not-pay',
  CONFIRMED: 'confirmed',
}

/**
 * Adds or removes a user to a given check item
 * @param {string} checkId 
 * @param {string} userId
 * @param {string} phoneNumber
 * @param {string} moderatorName
 * @param {string} guestName
 * @returns {Promise}
 */

export function setNudgeOnJoin(checkId, userId, phoneNumber, restaurantName, moderatorName, guestName) {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';
  const db = firebase.firestore();
  const userNudgeDocRef = db.collection('nudges').doc(`${userId}-${checkId}`);
  const nudge = {
    restaurantName,
    moderatorName,
    name: guestName,
    checkId,
    paymentStatus: nudgePaymentStatus.DID_NOT_PAY,
    onMobile: false,
    timestamp: Date.now(),
    timezone,
    phoneNumber,
  };

  return userNudgeDocRef.set(nudge).catch((err) => {
    console.error(err);
  });
}

export function updateNudgeOnPaymentActivity(userId, checkId, paymentStatus) {
  const db = firebase.firestore();
  const userNudgeDocRef = db.collection('nudges').doc(`${userId}-${checkId}`);

  userNudgeDocRef.update({ paymentStatus }).catch((err) => {
    console.error(err);
  });
}

export function appendGhostGuest(checkId, phoneNumber, contactId) {
  return Promise.all([
    firebase.database().ref(`checksById/${checkId}/guests`).transaction((data) => {
      if (data?.[phoneNumber]) return data;

      const newGuestCount = Object.keys(data || {}).length + 1;
      const update = {
        ...data,
        [phoneNumber]: {
          isGhost: true,
          contactId,
          name: 'Friend',
          avatar: setGuestAvatar(newGuestCount, IMAGE_STRINGS),
          color: setGuestColor(newGuestCount, COLOR_STRINGS),
        },
      };
      return update;
    }),
    firebase.database().ref(`checksById/${checkId}/updateDate`).set(Date.now()),
  ]);
}

export function claimGhostContactUser(checkId, phoneNumber, userId, name) {
  // Done in one large transaction instead of one for each of the critical sections
  // Separating those out would introduce bugs due to timing where a selection may not reference
  // a ghost user that has yet to be changed to the real user.
  return firebase.database().ref(`checksById/${checkId}`).transaction((data) => {
    const newSplyt = { ...data };
		
    // promote to user in guest list
    const newGuests = {};
    Object.entries(newSplyt.guests || {}).forEach(([guestId, guest]) => {
      if (guestId !== phoneNumber) {
        newGuests[guestId] = guest;
        return;
      }

      newGuests[userId] = {
        name,
        avatar: guest.avatar,
        color: guest.color,
      };
    });
    newSplyt.guests = newGuests;

    // swap claimed items to this user
    if (newSplyt.selections) {
      Object.entries(newSplyt.selections).forEach(([itemId, guestArr]) => {
        // in line replace to preserve order.
        newSplyt.selections[itemId] = guestArr.map(guestId => (
          guestId === phoneNumber ? userId : guestId
        ));
      });
    }

    newSplyt.updateDate = Date.now();
    return newSplyt;
  });
}

export function markGhostMutualUserAsJoined(checkId, ghostId) {
  return firebase.database().ref(`checksById/${checkId}/guests/${ghostId}`).transaction((data) => {
    if (!data) return data;

    return omit(data, ['isGhost']);
  });
}
