import firebase from '../services/firebase';
import { isFunction } from 'lodash';
import { setGuestAvatar, setGuestColor } from './dbUtils';
import mixpanel from 'mixpanel-browser';

/**
 * Signs out the user
 * @returns {Promise}
 */
export function signOut() {
  mixpanel.reset();
  return firebase.auth().signOut();
}

export function signInAnonymously() {
  return firebase.auth().signInAnonymously()
    .then(({ user }) => {
      firebase.database().ref().child(`usersById/${user.uid}`).update({
        uid: user.uid,
      });
      return user;
    });
}

/**
 * Subscribes to any changes to the user's log in state
 * @param {(user: Object) => void} onChange 
 * @returns {function} Unsubscribe method for cleanup
 */
export function subscribeToLoginChanges(onChange) {
  return firebase.auth().onAuthStateChanged(onChange);
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export function addCheckIdToUsersChecks(userId, checkId){
  const date = Date.now();
  return firebase.database().ref(`usersById/${userId}/checkIds`).update({[checkId]: date})
}

export const IMAGE_STRINGS = ['avocado', 'banana', 'lollipop', 'meat', 'mushroom', 'sushi', 'taco'];
export const COLOR_STRINGS = ['green', 'yellow', 'purple', 'blue', 'red', 'gray', 'orange', 'black'];

export function setUserCheckData(userId, myName, checkId, checkMetaData, guests){
  // const date = Date.now();
  const rootRef = firebase.database().ref();
  const guestIds = Object.keys(guests);

  const myUserObj = {
    name: myName,
    didCheckout: false,
    avatar: setGuestAvatar(guestIds.length, IMAGE_STRINGS),
    color: setGuestColor(guestIds.length, COLOR_STRINGS),
  };

  const guestsForCheck = {
    ...guests,
    [userId]: myUserObj,
  };

  const guestsForMeta = guestIds.reduce((guestList, guestId) => ({
    ...guestList,
    [guestId]: { name: guests[guestId].name }
  }),{[userId]: { name: myName }})

  const updatesObj = {
    [`checksByUser/${userId}/${checkId}/checkMetaData`]: checkMetaData,
    [`checksById/${checkId}/guests`]: guestsForCheck,
  };

  [...guestIds, userId ].forEach((guestId) => {
    if (guestId !== 'initial') {
      updatesObj[`checksByUser/${guestId}/${checkId}/guests`] = guestsForMeta;
    }
  });

  return Promise.all([
    rootRef.update(updatesObj),
    firebase.firestore().collection('users').doc(userId).set({ name: myName }, { merge: true }),
  ]);
}

export function setUserCountdownEndDate(userId) {
  const startTime = Date.now();
  const endTime = startTime + (24 * 60 * 60 * 1000);
  return firebase.firestore().collection('users').doc(userId).set({ countdownEndDate: endTime }, { merge: true });
}

/**
 * Subscribes to any updates on the check
 * @param {string} userId 
 * @param {{(checkMetaData: checkMetaData) => void}} onUpdate
 * @returns {function} Unsubscribe method for cleanup
 */
export function subscribeToUserChecks(userId, onUpdate) {
  const ref = firebase.database().ref().child(`checksByUser/${userId}`);

  const updateWrapper = snapshot => {
    isFunction(onUpdate) && onUpdate(snapshot.val());
  };

  ref.on('value', updateWrapper);
  return () => ref.off('value', updateWrapper);
}

export function addCheckToUsersChecksModerated(checkId, userId){
  const date = Date.now();
  return firebase.database().ref(`usersById/${userId}/checksModerated`).update({[checkId]: date})
}

export function setUserPaymentInfo(userId, paymentObj, checkId){
  const ref = firebase.database().ref();
  const updateObj = {
    [`usersById/${userId}/paymentInfo`]: paymentObj,
  };

  if (checkId) {
    updateObj[`checksById/${checkId}/userPaymentInfo`] = paymentObj;
  }

  return ref.update(updateObj)
}

export function setHasSeenInstructions(userId, type, status) {
  return firebase.database().ref(`usersById/${userId}/seenInstructions`).update({ [type]: status });
}

export function subscribeToUser(userId, onUpdate) {
  const ref = firebase.database().ref().child(`usersById/${userId}`);

  const updateWrapper = snapshot => {
    isFunction(onUpdate) && onUpdate(snapshot.val());
  };

  ref.on('value', updateWrapper);
  return () => ref.off('value', updateWrapper);
}

export function subscribeToProfile(userId, func) {
  return firebase.firestore().collection('users').doc(userId).onSnapshot(snapshot => {
    if (snapshot.exists) {
      func(snapshot.data());
    } else {
      func();
    }
  });
}

export function sendSplytSMS(recipient, link) {
  return firebase.functions().httpsCallable('smsSendSplytLink')({ recipient, link });
}

export function sendAppStoreLinkSMS(recipient) {
  return firebase.functions().httpsCallable('smsSendAppStoreLink')({ recipient });
}
