import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Place from '../Place/Place';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  li: {
    listStyleType: 'none',
  },
  placeWrapper: {
    border: 'none',
    backgroundColor: 'white',
    width: '100%',
    textAlign: 'initial',
  },
}));

const PlacesList = ({ data: checks, onPlaceClick }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      { checks.map((check) => (
        <li className={classes.li} key={check.id}>
          <button onClick={() => onPlaceClick(check.id)} className={classes.placeWrapper}>
            <Place
              checkId={check.id}
              restaurantName={check.restaurant?.name}
              date={check.createDate}
              guestCount={check.guestsById.length}
            />
          </button>
        </li>
      ))}
    </ul>
  )
}

PlacesList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    checkMetaData: PropTypes.shape({
      date: PropTypes.number,
      restaurantInfo: PropTypes.shape({
        restaurantName: PropTypes.string,
      }),
    }),
    guests: PropTypes.objectOf(PropTypes.shape({
      name: PropTypes.string,
    })),
  })),
  onPlaceClick: PropTypes.func,
};

export default PlacesList
