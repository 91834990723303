import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatPriceStringForDisplay } from '../../../utils/NumberUtils';
import PropTypes from 'prop-types';
import { white, darkGreen, darkBlue, black } from '../../../style/colors';
import { Typography } from '@material-ui/core';
import { displayFraction, listify } from '../../../utils';
import GuestAvatarsV2 from '../guestAvatars/GuestAvatarsV2';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: white,
    minHeight: '3rem',
    padding: '1rem',
  },
  row: {
    display: 'flex',
  },
  quantity: {
    ...theme.customType.mediumText,
    color: darkBlue,
    width: theme.spacing(4),
    textAlign: 'center',
    marginRight: theme.spacing(1),
    marginLeft: -theme.spacing(1),
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'scroll',
  },
  rowWrapper: {
    display: 'flex',
  },
  namePriceRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: black,
  },
  itemName: {
    ...theme.customType.mediumText,
    color: black,
    // fontWeight: theme.customType.normalTextSemiBold,
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    // width: '90%',
  },
  price: {
    ...theme.customType.mediumText,
    ...theme.customType.semiBold,
    color: darkBlue,
  },
  descriptionAndExtrasRow: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    color: darkGreen,
    ...theme.customType.smallText,
  },
  charge: {
    color: darkGreen,
    ...theme.customType.smallText,
  },
  extraCharge: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatars: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  avatarsPrefix: {
    marginRight: '0.8em',
    width: '95px'
  }
}));

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string,
    extras: PropTypes.shape({
      descriptionIds: PropTypes.arrayOf(PropTypes.string),
      descriptionsById: PropTypes.objectOf(PropTypes.string),
      chargesById: PropTypes.arrayOf(PropTypes.string),
      chargeIds: PropTypes.objectOf(PropTypes.string),
    }),
    suggestion: PropTypes.string,
  }),
  useSuggestion: PropTypes.bool,
  sharedWith: PropTypes.arrayOf(PropTypes.string),
};


export default function DisplayItem(props) {
  const {
    item,
    selectedGuests,
    useSuggestion,
  } = props;
  const classes = useStyles(props);
  const { name, price, extras, suggestion } = item;
  let displayName = name;
  if (useSuggestion && suggestion) displayName = suggestion;

  const splitByHowManyPeople = item?.splitByHowManyPeople ?? 1;
  const divisor = splitByHowManyPeople > 1 ? splitByHowManyPeople : selectedGuests?.length ?? 1;
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography className={classes.quantity}>{ displayFraction(1, divisor) }</Typography>
        <div className={classes.rightSide}>
          <div className={classes.namePriceRow}>
            <Typography className={classes.itemName}>{displayName}</Typography>
            <Typography className={classes.price}>{`$${formatPriceStringForDisplay(price / divisor)}`}</Typography>
          </div>
          {!!extras?.descriptionIds && extras.descriptionIds.length > 0 && extras?.descriptionIds.map(id => (
            <div key={id} className={classes.descriptionAndExtrasRow}>
              <Typography className={classes.description}>{extras?.descriptionsById[id]}</Typography>
            </div>
          ))}
          {!!extras?.chargeIds && extras?.chargeIds.length > 0 && extras?.chargeIds.map(id => (
            <div key={id} className={classes.descriptionAndExtrasRow}>
              <Typography className={classes.charge}>{extras?.chargesById[id].chargeDescription}</Typography>
              <Typography className={classes.charge}>{`+$${formatPriceStringForDisplay(extras?.chargesById[id].chargePrice)}`}</Typography>
            </div>
          ))}
        </div>
      </div>
      {!!selectedGuests && selectedGuests.length > 1 && (
        <div className={classes.avatars}>
          <GuestAvatarsV2
            compact
            guests={selectedGuests}
            size="extraSmall"
          />
          <Typography variant="subtitle1" style={{ color: darkGreen }}>
            { listify(selectedGuests.map(({ name, display }) => display ?? name), 4, over => `, & ${over} more`) }
						&nbsp;shared this
          </Typography>
        </div>
      )} 
    </div>
  );
}

DisplayItem.propTypes = propTypes;
