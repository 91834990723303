export function sortItemsByType(a, b) {
  const alphabeticalOrder = a.name.localeCompare(b.name);

  if ('type' in a && 'type' in b) {
    if (a.type === b.type) return alphabeticalOrder;
    else if (a.type === 'drink') return -1;
    else if (b.type === 'drink') return 1;
    else if (a.type === 'food') return -1;
    else if (b.type === 'food') return 1;
  }

  return alphabeticalOrder;
}