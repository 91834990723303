import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Input, IconButton } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from "@material-ui/core/styles";
import { formatPriceStringForDisplay } from '../../../../VerifyContainer';

export function formatPriceInputValueIntoStateString(string) {
  const times100 = Number(string) * 100;
  return times100.toString()
}

export function formatPriceStringForInputValue(string){
  const dividedBy100 = Number(string) / 100;
  return dividedBy100.toString()
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonRoot: {
    minWidth: '45px',
  },
  iconStyle: {
    width: '.8em'
  },
  inputRoot: {
    maxWidth: '80px',
  }
});

const formatPriceString = fourCharString => (Number(fourCharString) / 100).toFixed(2).toString()

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      fixedDecimalScale
      decimalScale={2}
      allowNegative={true}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      inputMode="decimal"
    />
  );
}

const PriceInput = ({price, handleChange, autoFocus = false}) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const [ strVal, setStrVal ] = useState('');

  useEffect(() => {
    setStrVal(formatPriceString(price))
  }, [setStrVal])

  const handleMakeNegOrPos = useCallback(() => {
    const flipped = Number(formatPriceInputValueIntoStateString(strVal)) * -1;
    handleChange(flipped.toString());
  }, [handleChange, strVal])

  const handlePriceChange = useCallback((e) => {
    const { value } = e.target;
    const amountOfDecimals = value.split('').filter(char => char === '.').length;    
    amountOfDecimals <= 1 && setStrVal(value)
    amountOfDecimals <= 1 && handleChange(formatPriceInputValueIntoStateString(value))

    // handleChange(formatPriceInputValueIntoStateString(value));
  }, [handleChange, setStrVal])

  return (
    <div className={classes.root}>
      <IconButton tap-id={'plusMinusButton'} classes={{root: classes.buttonRoot}} size="small" onClick={handleMakeNegOrPos}><RemoveIcon classes={{root: classes.iconStyle}}/> </IconButton>
      <IconButton tap-id={'plusMinusButton'} classes={{root: classes.buttonRoot}} size="small" onClick={handleMakeNegOrPos}><AddIcon classes={{root: classes.iconStyle}}/> </IconButton>
      <Input
        classes={{root: classes.inputRoot}}
        value={strVal}
        onChange={(e) => handlePriceChange(e)}
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        inputProps={{
          'aria-label': 'description',
          'ref': inputRef,
          'autoFocus': autoFocus,
          'style': {'textAlign': 'center'},
          inputMode: 'decimal',
        }} 
      />
    </div>
  )
}

export default PriceInput;
