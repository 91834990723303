import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const formatPrice = numString => Number(numString) / 100;

const useStyles = makeStyles({
    root: {

    },
    title: {
      fontSize: '1.5em',
      marginBottom: '1em',
      textAlign: 'center',
    },
    restaurantName: {
      fontSize: '2em',
      marginBottom: '1em',
      textAlign: 'center',
    },
    items: {

    },
    itemLine: {
      marginBottom: '1em',
      paddingBottom: '.5em',
      borderBottom: [['1px', 'solid', 'grey']]

    },
    itemInfoLine: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '.5em',
    },
    itemName: {
      width: '80%',
    },
    itemPrice: {

    },
    sharedWithLine: {
      fontStyle: 'italic',
    },
    sharedWith: {
      marginRight: '.5em'
    },
    sharerName: {

    },
    totalSectionContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    totalSection: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1em',
    },
    subTotal: {

    },
    tax: {

    },
    tip: {

    },
    totalTitle: {
      fontSize: '1.2em',
    },
    totalNumber: {
      fontSize: '1.2em',
    },
})

const SuccessReceipt = ({ items, subtotal, restaurantName, tax, tip}) => {
  const classes = useStyles();

  const getTaxAmount = () => {
    const sub = formatPrice(subtotal);
    const taxDecimal = Number(tax) / 100000;
    return (sub) * taxDecimal
  }

  const getTotal = () => {
    const sub = formatPrice(subtotal);
    const tax = getTaxAmount();
    const tipDollarAmount = tip * sub;
    return (sub + tax + tipDollarAmount + .75).toFixed(2);
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Here is your itemized receipt:
      </div>
      <div className={classes.restaurantName}>
        {restaurantName}
      </div>
      <div className={classes.items}>
        { items && items.map( ({name, price, namesOfSharers, noOfSharers}, i) => (
          <div key={i} className={classes.itemLine}>
            <div className={classes.itemInfoLine}>
              <div className={classes.itemName}>{name}</div>
              <div className={classes.itemPrice}>${(formatPrice(price) / noOfSharers).toFixed(2)}</div>
            </div>
            { namesOfSharers && (
              <div className={classes.sharedWithLine}>
                <span className={classes.sharedWith}>Shared with:</span>
                { namesOfSharers.map((name, i, arr) => {
                return <span key={`${name}${i}`}className={classes.sharerName}>{`${name}${i < arr.length-1 ? ', ' : ''}`}</span>
                })
              }</div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.totalSectionContainer}>
        <div className={classes.totalSection}>
          <span>subtotal:</span><span>${formatPrice(subtotal).toFixed(2)}</span>
        </div>
        <div className={classes.totalSection}>
            <span>tax: ({tax  / 1000}%)</span><span>${getTaxAmount().toFixed(2)}</span>
        </div>
        <div className={classes.totalSection}>
            <span>{`tip: (${tip * 100}%)`}</span><span>{`$${(tip * formatPrice(subtotal)).toFixed(2)}`}</span>
        </div>
        <div className={classes.totalSection}>
            <span>splyt fee:</span><span>$0.75</span>
        </div>
        <div className={classes.totalSection}>
          <span className={classes.totalTitle}>Total:</span><span className={classes.totalNumber}>${ getTotal()  }</span>
        </div>
      </div>
    </div>
  )
};

export default SuccessReceipt;
