import React, { useCallback, useMemo, useState, useEffect, useRef, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import VerifyHeader from './verifyComponents/VerifyHeader';
import TopSummary from './verifyComponents/TopSummary';
import RestaurantInfo from './verifyComponents/RestaurantInfo';
import VerifyTotal from './verifyComponents/VerifyTotal/VerifyTotal';
import ItemsList from './verifyComponents/ItemsList';
import { putCheck } from '../API/checks';
import { addCheckToUsersChecksModerated } from '../API/users';
import { getOCRData } from '../API/ocrData';
import { formatDataForSaveFromVerifyToFirebase } from '../utils/formatDataForSaveFromVerifyToFirebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams } from "react-router";
import * as analytics from '../API/mixpanel';
import mixpanel from 'mixpanel-browser';
import { Redirect} from "react-router-dom";



// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const BASE_DB_URL = 'https://us-central1-splyt-9f6cc.cloudfunctions.net/splyt';
const TEST_DB_URL = 'http://localhost:5000/splyt-9f6cc/us-central1/splyt'
const testPublishableKey = 'pk_test_51HDkiADbI2PNAriE3YrAG3VYgcBj4F8GYlR7gBMNAKVghwo2SS8He3K2e61evHhMDa1NgY9dBqvuQ8PQ5kGPmQXf00U1CHr4kl';
const livePublishableKey = 'pk_live_51HDkiADbI2PNAriEf83mXF42bb2jyIWRT9hWgbjTRSXMmjteA1M7TUuOvgwetu9OmJbfQQehqoZKJ9s28znOAyN200kOhP9Kc1';

const urlMap = {
  baseUrl: BASE_DB_URL,
  demoUrl: TEST_DB_URL,
  demoCheckoutSessionURL: 'demo-contactless-create-checkout-session',
  checkoutSessionUrl: 'contactless-create-checkout-session',
  testKey: testPublishableKey,
  liveKey: livePublishableKey,
  testOCRUrl: `http://localhost:3000`,
  liveOcrUrl: 'https://splyt-9f6cc.web.app/',
};

const formatPriceStringOCRDataIntoString = total => {
  if (typeof(total) === 'number') {
    const numTimes100 = Math.round(total.toFixed(2) * 100);
    return numTimes100.toString();
  }
  return '0000';
}

export const formatPriceStringForDisplay = string => `$${((Number(string / 100)).toFixed(2).toString())}`

const parseLineItems = lineItems => {
  return lineItems.reduce((result, obj) => {
    const descriptionArray = obj.description.split('\n');
    const [name, ...description] = descriptionArray;
    const hasExtras = descriptionArray.length > 1;
    const descriptionsById = description.reduce((acc, curr) => {
      return {
        ...acc,
        [uuidv4()]: curr,
      }
    }, {})
    const priceString = formatPriceStringOCRDataIntoString(obj?.total) ?? '0000';
    const id = uuidv4();
    return {
      itemIds: [ ...result.itemIds, id ],
      itemsById: {
        ...result.itemsById,
        [id]: {
          id,
          name: name,
          qty: obj.quantity,
          price: priceString,
          extras: {
            descriptionsById: hasExtras ? descriptionsById : {},
            descriptionIds: hasExtras ? Object.keys(descriptionsById) : [],
            chargesById: {},
            chargeIds: [],
          }
        },
      }
    }
  }, {itemIds: [], itemsById: {}})
}

const formatNumString = num => (Math.round(num * 100)).toString();

const parseJson = res => {
  const parsed = parseLineItems(res?.line_items ?? []);
  return {
    restaurantInfo: {
      restaurantId: uuidv4(),
      restaurantName: res?.vendor?.raw_name ?? res?.vendor?.name ?? null,
      restaurantPhone : res?.vendor?.phone_number ?? null,
      serverName: null,
      checkNumber: res?.invoice_number ?? null,
      restaurantAddress: res?.vendor?.address ?? null,
    },
    itemIds: parsed.itemIds,
    itemsById: parsed.itemsById,
    totalInfo: {
      subtotal: formatNumString(res?.subtotal ?? 0),
      tax: formatNumString(res?.tax ?? null),
      total: formatNumString(res?.total ?? null),
      tip: '0000',
      tipPercent: 'custom',
    },
    moderatorId: res.moderatorId,
    userPaymentInfo: res?.userPaymentInfo ?? {type: 'venmo', 'paymentId': 'none'}
  }
}

export default function VerifyContainer() {
  const { checkId } = useParams();
  const topRef = useRef(null);

  const [isFetching, setIsFetching] = useState(true);
  const [ error, setError ] = useState({error: false, message: ''});
  const [isSaveComplete, setSavedComplete] = useState(false);

  const { user, profile }  = useContext(AuthContext);


  const [data, setData] = useState({
    restaurantInfo: {
      restaurantId : null,
      restaurantName : null,
      restaurantPhone : null,
      numberOfguests : null,
      serverName: null,
      checkNumber: null,
      restaurantAddress: {
        street: null,
        city: null,
        state: null,
        zip: null,
      },
    },
    itemsById: {},
    itemIds: [],
    editingIds: [],
    editingById: {},
    confirmDeleteIds: [],
    totalInfo: {},
    isEditingTotal: {tax: false, tip: false},
    didCheckout: false,
    mandatoryTip: '0',
    moderatorId: null,
    serviceCharge: '0',
    currency: "USD",
    guests : {},
    selections : {
      "initial" : "initial"
    },
    idsReadyToCheckout: ["initial"],
    userPaymentInfo: {},
    
  });

  const mergeAsExtra = useCallback((itemId) => {
    const itemToEdit = data.itemsById[itemId]
    const { name: editingName, price: editingPrice } = itemToEdit;
    console.log('ITEM TO EDIT ', editingName)

    const index = data.itemIds.indexOf(itemId);
    if (index === 0) {
      return;
    }
    const last = index - 1;
    // const itemAboveId = data.itemIds[last];
    // const itemAbove = data.itemsById[itemAboveId]
    // console.log('ITEM ABOVE ', itemAbove)
    

    setData((state) => {
      const itemAboveId = state.itemIds[last];
      const itemAbove = state.itemsById[itemAboveId]

      return {
        ...state,
        itemIds: state.itemIds.filter(id => id !== itemId),
        itemsById: {
          ...state.itemsById,
          [itemAboveId]: {
            ...itemAbove,
            extras: {
              ...itemAbove.extras,
              chargeIds: [...itemAbove.extras.chargeIds, itemId],
              chargesById: {
                ...itemAbove.extras.chargesById,
                [itemId]: {
                  chargeDescription: editingName,
                  chargePrice: editingPrice,
                }
              }
            }
  
          }
        }
      }
    })

  }, [data])

  const calculatedSubtotal = useMemo(() => {
    const lineItemTotal = data.itemIds.reduce((total, id) => {
      const item = data.itemsById[id];
      const priceX100 = Number(item.price);
      const chargePricesX100 = item.extras.chargeIds.reduce((chargeTotal, chargeId) => {
        return chargeTotal += Number(item.extras.chargesById[chargeId].chargePrice)
      },0)
      return total += chargePricesX100 + priceX100
    }, 0)

    return lineItemTotal;
  }, [data])


  useEffect(() => {
    setData( data => ({
      ...data,
      totalInfo: {
        ...data.totalInfo,
        subtotal: calculatedSubtotal,
      }
    }))
    
  }, [calculatedSubtotal, setData])
  
  const calculatedTotal = useMemo(() => {
    const { tax, tip } = data.totalInfo;
    return Number(tax) + Number(tip) + calculatedSubtotal;
  }, [data, calculatedSubtotal])

  const handleSaveClick = useCallback(() => {
    mixpanel.track('clicked continue to splyt')
    setIsFetching(true);
    // TODO - save our calculated totals instead of verify total in .set(...data, totalInfo)
    const checkData = formatDataForSaveFromVerifyToFirebase(data, calculatedSubtotal, calculatedTotal, profile.name);
    const now = Date.now();
    checkData.createDate = now;
    checkData.updateDate = now;
    putCheck(checkId, { ...checkData })
      .then(() => {
        addCheckToUsersChecksModerated(checkId, user.uid)
        setSavedComplete(true);
        analytics.receiptSuccessfullySaved({id: checkId, data: checkData})
        mixpanel.people.increment('checks_created');
        mixpanel.people.union('checks_moderated', checkId);
      })
      .catch(err => console.log('Error', err));
  }, [data, checkId, calculatedSubtotal, calculatedTotal, setIsFetching, setSavedComplete, user.uid, profile.name])

  const tipDollarAmountStrings = useMemo(() => {
    return {
      '18': ((calculatedSubtotal * .18)).toString(),
      '20': ((calculatedSubtotal * .20)).toString(),
      '22': ((calculatedSubtotal * .22)).toString(),
    }
  }, [calculatedSubtotal])

  const calculatedTipAmount = useMemo(() => {
    const { tipPercent, tip } = data.totalInfo 
    if ( tipPercent && tipPercent !== 'custom') {
      return ((Number(tipPercent) / 100) * Number(calculatedSubtotal)).toString()
    }
    console.log('CALCULATED TIP ', tip)
    return tip;
  }, [data, calculatedSubtotal])

  useEffect(() => {
    mixpanel.track("landed on verify receipt", {checkId});
  }, [checkId])

  // get data from firebase
  useEffect(() => {
    setIsFetching(true);

    getOCRData(checkId)
      .then(ocrData => {
        const parsedData = parseJson(ocrData);
        console.log('DATA ', ocrData)
        setData(data => ({...data, ...parsedData}))
        setIsFetching(false);

        if (topRef.current) {
          topRef.current.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start"
          });
        }
      });
  }, [checkId, user]);

  const handleItemDeleteTap = useCallback((lineItemId) => {
    setData( state => ({
      ...state,
      confirmDeleteIds: [...state.confirmDeleteIds, lineItemId],
    }))  
  }, [setData])

  const clearDeleteConfirm = useCallback((e) => {
    console.log('bubs', e.bubbles)

    if (data.confirmDeleteIds.length) {
      setData( state => ({
        ...state,
        confirmDeleteIds: [],
      })) 
    }
  }, [data, setData])



  const handleTotalSectionTap = useCallback(() => {
    setData( state => ({
      ...state,
      isEditingTotal: true,
    }))  
  }, [setData])


  const handleTotalSectionInputChange = useCallback((e, field) => {

    const val = (Number(e.target.value) * 100).toString()
    console.log('VAL ', val)
    setData( state => {
      return {
        ...state,
        totalInfo: {
          ...state.totalInfo,
          [field]: val,
        },
      }
    })  
  }, [setData])

  const handleTipButtonClick = useCallback((tipPercent) => {
    mixpanel.track('clicked tip button ', {tipPercent})
    const tipAmount = tipPercent === 'custom' ? null : tipDollarAmountStrings[tipPercent];
    setData( state => {
      console.log('state.tip ', state.totalInfo.tip)
      return {
        ...state,
        totalInfo: {
          ...state.totalInfo,
          tip: tipPercent === 'custom' ? calculatedTipAmount : tipAmount,
          tipPercent,
        },
      }
    }) 
  }, [setData, tipDollarAmountStrings, calculatedTipAmount])

  const removeItemIdFromEdit = useCallback((id) => {
    const newEditing = data.editingIds.filter(itemId => itemId !== id);
    setData(data => ({
      ...data,
      editingIds: newEditing,
    }));
  }, [setData, data]);

  const saveItemEdit = useCallback((val, itemId, field, subId, subField) => {
    if (subField) {
      setData(state => ({
        ...state,
        itemsById: {
          ...state.itemsById,
          [itemId]: {
            ...state.itemsById[itemId],
            extras: {
              ...state.itemsById[itemId].extras,
              chargesById: {
                ...state.itemsById[itemId].extras.chargesById,
                [subId]: {
                  ...state.itemsById[itemId].extras.chargesById[subId],
                  [subField]: val,
                }
              },
            },
          },
        },
      }))
      return;
    } 
    if (subId) {
      setData(state => ({
        ...state,
        itemsById: {
          ...state.itemsById,
          [itemId]: {
            ...state.itemsById[itemId],
            extras: {
              ...state.itemsById[itemId].extras,
              descriptionsById: {
                ...state.itemsById[itemId].extras.descriptionsById,
                [subId]: val,
              },
            },
          },
        },
      }))
    }
    else {
      setData(state => {
        const newState = {
          ...state,
          itemsById: {
            ...state.itemsById,
            [itemId]: {
              ...state.itemsById[itemId],
              [field]: val,
            },
          },
        };
        return newState
      })
    }
    analytics.modifiedLineItem({
      itemId: itemId,
      item: data.itemsById[itemId],
      checkId,
    });
  }, [setData, data, checkId])

  const handleItemTap = useCallback((e, lineItemId, field, subId, subField) => {
    if (subId) {
      setData( state => ({
        ...state,
        editingById: {
          [lineItemId]: {
            field,
            extra: {
              id: subId,
              field: subField,
            },
          },
        },
      })) 
    } else {
      setData( state => ({
        ...state,
        editingById: {
          [lineItemId]: {
            field
          },
        },
      })) 
    } 
  }, [setData])

  const addExtraCharge = useCallback((itemId) => {
    const chargeId = uuidv4();
    setData( state => ({
      ...state,
      itemsById: {
        ...state.itemsById,
        [itemId]: {
          ...state.itemsById[itemId],
          extras: {
            ...state.itemsById[itemId].extras,
            chargeIds: [
              ...state.itemsById[itemId].extras.chargeIds,
              chargeId,
            ],
            chargesById: {
              ...state.itemsById[itemId].extras.chargesById,
              [chargeId]: {
                chargeDescription: 'new charge',
                chargePrice: '0000'
              },
            }
          }
        }
      }
    }))
    handleItemTap(null, itemId, 'charge', chargeId, 'name')
  }, [setData, handleItemTap])

  const deleteItem = useCallback((itemId) => {

    setData( data => {
      mixpanel.track('delete lineItem', {
        itemId,
        item: data.itemsById[itemId],
      })
      const newIds = data.itemIds.filter(id => id !== itemId);
      const newItemsById = newIds.reduce((acc, id) => {
        return {
          ...acc,
          [id]: data.itemsById[id],
        }
      }, {})
      return {
        ...data,
        itemIds: newIds,
        itemsById: newItemsById,
      }
    })
  }, [setData])
  
  const addItem = useCallback(() => {
    const id = uuidv4()
    mixpanel.track('add item clicked ', {itemId: id})
    setData(state => ({
      ...state,
      itemIds: [...state.itemIds, id],
      itemsById: {
        ...state.itemsById,
        [id]: {
          id: id,
          name: 'new item',
          qty: '',
          price: '',
          extras: {
            descriptionsById: {},
            descriptionIds: [],
            chargesById: {},
            chargeIds: [],
          }
        }
      }
    }))
    handleItemTap(null, id, 'name');
  }, [setData, handleItemTap])

  const handleItemFocus = useCallback((e, itemId) => {
    console.log('HIT item focus current target ', e.currentTarget)
    console.log('target ', e.target)

    if (e.currentTarget === e.target) {
      console.log('handle focus e.currentTarget === e.target');
      console.log('e.current ', e.currentTarget)
      console.log('e.target ', e.target)
      setData((state) => {
        return {
          ...state,
          editingById: {
            [itemId]: {
              field: null,
            },
          },
          confirmDeleteIds: [],
        }
      })
    } else {
      console.log('focus e.currentTarget !== e.target focused child', e.target);
    }
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // Not triggered when swapping focus between children
      console.log('focus entered self !e.currentTarget.contains(e.relatedTarget)', e.relatedTarget);
    }
  }, [setData])

  const handleItemBlur = useCallback((e, itemId) => {
    if (e.currentTarget === e.target) {
      console.log('unfocused self blur e.currentTarget === e.target');
    } else {
      console.log('unfocused child blur e.currentTarget does not equal e.target', e.target);
    }
    if (!e.currentTarget.contains(e.relatedTarget)) {
      // Not triggered when swapping focus between children
      console.log('focus left self blur !e.currentTarget.contains(e.relatedTarget)', itemId);
      setData((state) => {
        return {
          ...state,
          editingIds: state.editingIds.filter(id => id !== itemId),
          editingById: {},
          confirmDeleteIds: state.confirmDeleteIds.filter(id => id !== itemId),
        }
      })
    }
  }, [])

  return (
    <div style={{margin: [['0 auto']], 'maxWidth': 500, overflow: 'hidden', position: 'relative'}}>
      <VerifyHeader data={data} ref={topRef}/>
      {isFetching ? (
        <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <div style={{zIndex: 5}}>
          <TopSummary numberOfItems={data.itemIds.length} total={calculatedTotal} />
          <div style={{padding: '1em'}}>
            <RestaurantInfo restaurantInfo={data.restaurantInfo}/>
            <ItemsList
              itemsById={data.itemsById}
              itemIds={data.itemIds}
              editingIds={data.editingIds}
              confirmDeleteIds={data.confirmDeleteIds}
              handleItemDeleteTap={handleItemDeleteTap}
              handleItemTap={handleItemTap}
              saveItemEdit={saveItemEdit}
              removeItemIdFromEdit={removeItemIdFromEdit}
              deleteItem={deleteItem}
              mergeAsExtra={mergeAsExtra}
              handleFocus={handleItemFocus}
              handleBlur={handleItemBlur}
              editingById={data.editingById}
              addItem={addItem}
              addExtraCharge={addExtraCharge}
            />
            <VerifyTotal 
              totalInfo={{
                subtotal: calculatedSubtotal,
                tax: data.totalInfo.tax,
                tip: data.totalInfo.tip,
                total: calculatedTotal,
                tipPercent: data.totalInfo.tipPercent,
              }}
              tipDollarAmountStrings={tipDollarAmountStrings}
              handleTipButtonClick={handleTipButtonClick}
              handleInputChange={handleTotalSectionInputChange}       
              onClick={handleTotalSectionTap}
              handleSave={handleSaveClick}
              calculatedTipAmount={calculatedTipAmount}
            />
          </div>
        </div>
      )}
      { isSaveComplete && <Redirect to={`/splyt/ocr/${checkId}`}/>}
    </div>
  );
}
