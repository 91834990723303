import React from 'react';
import * as mixpanel from 'mixpanel-browser';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function NameDialog({
  open,
  submitFunc,
  val,
  setVal,
  clearNameFormData,
}) {
  
  const handleSubmit = (val) => {
    mixpanel.people.set({ "$name": val });
    submitFunc()
  }

  const form = (
    <TextField
      margin="dense"
      id="name"
      label="Your name"
      placeholder="Enter name"
      type="email"
      fullWidth
      value={val}
      onChange={(e) => setVal(e.target.value)}
      inputProps={{
        autoComplete: 'off'
      }}
    />
  );

  const title = 'Hey 👋🏼 What\'s your name?';

  return (
    <div>
      <Dialog open={open} onClose={clearNameFormData} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
        <DialogContent>
          { form }
        </DialogContent>
        <DialogActions>
          <Button disabled={val.length === 0} onClick={() => {handleSubmit(val)}} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
