/** cSpell:ignore phonenumber */
import 'react-international-phone/style.css';
import { InputAdornment, MenuItem, Select, TextField, TextFieldProps, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { CountryIso2, defaultCountries, FlagEmoji, getCountry, parseCountry, usePhoneInput } from 'react-international-phone';
import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';

type PhoneNumberInputProps = Omit<TextFieldProps, 'onChange'> & {
  value: string,
  onChange: (args: { phone: string, isValid: boolean }) => void,
}

export default function PhoneNumberInput({
  value,
  onChange,
  ...restProps
}: PhoneNumberInputProps) {
  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'us',
    value,
    countries: defaultCountries,
    onChange(data) {
      const parsedNumber = parsePhoneNumber(data.phone);

      const normalizedNumber = data.phone.replaceAll(/\D/g, '');
      const country = getCountry({ field: 'iso2', value: data.country });
      if (country) {
        const parsedWithSetCountry = parsePhoneNumber(`+${normalizedNumber}`);
        const parsedAsUSNationalNumber = parsePhoneNumber(normalizedNumber, { regionCode: 'US' });
        if (parsedWithSetCountry.valid || !parsedAsUSNationalNumber.valid) {
          onChange({
            phone: data.phone,
            isValid: parsedWithSetCountry.valid || isDevNumber(parsedWithSetCountry),
          });
        } else {
          onChange({
            phone: parsedAsUSNationalNumber.number.e164,
            isValid: parsedAsUSNationalNumber.valid || isDevNumber(parsedAsUSNationalNumber),
          });
        }
      } else {
        onChange({
          phone: data.phone,
          isValid: parsedNumber.valid || isDevNumber(parsedNumber),
        });
      }
    },
  });

  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      color="primary"
      label="Phone Number"
      placeholder="Phone number"
      value={phone}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      autoComplete="tel"
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{ marginRight: '2px' }}
            className={classes.adornment}
          >
            <Select
              MenuProps={{
                style: { height: '300px', width: '360px', top: '10px' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => <FlagEmoji iso2={value as CountryIso2} style={{ display: 'flex' }} />}
            >
              { defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagEmoji
                      iso2={country.iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography>{country.name}&nbsp;</Typography>
                    <Typography>+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  adornment: {
    '&>.MuiInput-underline:before': {
      border: 'none',
    },
  }
}));

function isDevNumber(phone: ParsedPhoneNumber) {
  // @ts-ignore Not bothering with this
  // eslint-disable-next-line no-undef
  return !!(process.env.REACT_APP_ENVIRONMENT === 'staging'
		&& phone.number?.significant?.startsWith('420')
		&& phone.number.significant.length === 10);
}