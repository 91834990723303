import React from 'react';
import ItemsList from "./ItemsList";
import ItemsListWithLock from "./ItemsListWithLock";
import PropTypes from 'prop-types';


const ItemsListFactory = ({showLockedItem, ...rest}) => {
  return showLockedItem ? <ItemsListWithLock {...rest} /> : <ItemsList {...rest} />
}

const { bool} = PropTypes;

ItemsListFactory.propTypes = {
  showLockedItem: bool,
};

export default ItemsListFactory;