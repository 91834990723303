import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import DisplayItem from '../Item/DisplayItem';
import { guestShape, selectedItemsShape, itemShape } from '../../../utils/propTypesShapes';
import { primaryBorder } from '../../../style/colors';
import { sortItemsByType } from './utils';

const propTypes = {
  myItemsIds: PropTypes.arrayOf(PropTypes.string),
  selectedItems: selectedItemsShape,
  guests: PropTypes.objectOf(guestShape),
  itemsById: PropTypes.objectOf(itemShape),
  myId: PropTypes.string,
  isShowingSuggestions: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
  list: {
    'listStyleType': 'none',
    'borderBottom': primaryBorder,
    '& li:not(last-of-type)': {
      'borderTop': primaryBorder,
    },
    'padding': 0,
  },
}));

const SummaryItemList = (props) => {
  const {
    myItemsIds,
    selectedItems,
    guests = {},
    itemsById = [],
    myId,
    isShowingSuggestions,
  } = props;
  const classes = useStyles(props);

  return (
    <ul className={classes.list}>
      { myItemsIds.sort((a, b) => sortItemsByType(itemsById[a], itemsById[b])).map((id, i) => {
        const selectedByArray = selectedItems[id] ? selectedItems[id]
          .filter(id => id !== myId)
          .map(guestId => {
            const guest = guests[guestId];
            return guest?.name ?? '';
          }) : null;
        
        const selectedGuests = selectedItems[id] ? selectedItems[id]
          .sort(a => a === myId ? -1 : 1)  
          .map(guestId => ({
            ...guests[guestId],
            display: guestId === myId ? 'You' : null,
          })) : null;
        
        return (
          <li key={id}>
            <DisplayItem
              item={itemsById[id]}
              selectedBy={selectedByArray}
              selectedGuests={selectedGuests}
              useSuggestion={isShowingSuggestions}
            />
          </li> 
        )})
      }
    </ul>
  );
};

SummaryItemList.propTypes = propTypes;
export default SummaryItemList;
