import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Button } from "@material-ui/core";

export default function ItemsEditedWarningModal({
  open,
  items,
  close,
}: { open: boolean, items: string[], close: () => void }) {

  const title = 'Some of your items have been updated';

  return (
    <div>
      <Dialog open={open} onClose={close} aria-labelledby="form-dialog-title">
        <DialogTitle id="warning-dialog-title">{ title }</DialogTitle>
        <DialogContent>
          <Typography style={{ paddingBottom: 8}}>You might need to reselect the following items:</Typography>
          { items.map((item, i) => (<Typography key={`${item}-${i}-warning`}>* {item}</Typography>))}
        </DialogContent>
        <DialogActions>
          <Button  onClick={close} color="primary">
            Got it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
