import React, { useEffect, useMemo, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Loop as LoopIcon, Warning as WarningIcon } from "@material-ui/icons";
import { CreatePaymentIntentResponse, createPaymentIntent, updatePaymentTotalForIntent } from "../../../API/payments";
import { Button, makeStyles } from "@material-ui/core";
import PaymentSheet from "../PaymentSheet";
import { payGreen } from "../../../style/colors";
import { formatPriceStringForDisplay } from "../../../utils/NumberUtils";

type CheckoutButtonPayCardProps = {
	amount: number,
	splytId: string,
	modName: string,
	payment?: { status: 'success' | 'processing' | 'failed', amount: string }
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

export default function CheckoutButtonPayCard({
  amount,
  splytId,
  modName,
  payment,
}: CheckoutButtonPayCardProps) {
  const [ paymentIntent, setPaymentIntent ] = useState<CreatePaymentIntentResponse['data'] | null>(null);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');
  const styles = useStyles();

  useEffect(function getPaymentIntent() {
    if (!splytId || !amount) return;
    setIsLoading(true);
    createPaymentIntent(amount, splytId)
      .then(setPaymentIntent)
      .catch(e => {
        console.error(e);
        setErrorMessage(e.message || 'An unknown error occurred');
      })
      .finally(() => setIsLoading(false));
  // we don't want to re-run this effect if amount changes.
  // There is a separate call we make to update the intent
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splytId]);

  useEffect(function updatePaymentIntentWithNewTotal() {
    if (!paymentIntent) return;
		
    void (async () => {
      try {
        await updatePaymentTotalForIntent(paymentIntent.paymentId, amount);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [paymentIntent, amount]);

  const [ isPaymentSheetShown, setIsPaymentSheetShown ] = useState(false);

  const options = useMemo(() => (paymentIntent?.paymentIntent ? {
    clientSecret: paymentIntent?.paymentIntent,
  } : null), [paymentIntent?.paymentIntent]);
	
  if (payment?.status === 'success') return (
    <div className={styles.row}>
      <Button
        disabled
        fullWidth
      >
				Paid { modName } { formatPriceStringForDisplay(payment.amount, true) }
      </Button>
    </div>
  );

  if (isLoading || errorMessage) return (
    <div className={styles.row}>
      <Button
        disabled
        fullWidth
        startIcon={isLoading ? <LoopIcon /> : <WarningIcon />}
      >
				Pay { modName } { formatPriceStringForDisplay(amount, true) }
      </Button>
      { errorMessage && <div className={styles.errorMessage}>{ errorMessage }</div>}
    </div>
  );

  return (
    <div className={styles.row}>
      { options && (
        <Elements stripe={stripePromise} options={options}>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: payGreen }}
						
            onClick={() => setIsPaymentSheetShown(true)}
          >
						Pay { modName } { formatPriceStringForDisplay(amount, true) }
          </Button>
          <PaymentSheet
            isShown={isPaymentSheetShown}
            onClose={() => setIsPaymentSheetShown(false)}
          />
        </Elements>
      )}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    textAlign: 'center',
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}));
