import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from '../../services/firebase';
// import SuccessReceipt from './SuccessReceipt';
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactlessItemsList from './ContactlessItemsList';
import ContactlessTotal from './ContactlessTotal';

const TEST_URL = 'http://localhost:5000/splyt-9f6cc/us-central1/splyt'
const BASE_URL = 'https://us-central1-splyt-9f6cc.cloudfunctions.net/splyt';

const useStyles = makeStyles({
  root: {
    '& > *': {
      marginBottom: '1em',
      marginTop: '1em',
    },
    padding: [[0, 10]]
  },
  successHeader: {
    fontSize: '2em',
    textAlign: 'center',
    marginBottom: '1em',
  },
})

const ContactlessSuccess = () => {
  const [session, setSession] = useState({amount_total: '0'});
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');
  const [ isLoading, setIsLoading ] = useState(true);
  const [data, setData] = useState({});
  const [myId, setMyId] = useState(null)

  const classes = useStyles();

  useEffect(() => {
    setIsLoading(true);
    async function fetchSession() {
      setSession(
        await fetch(`${TEST_URL}/demo-checkout-session?sessionId=${sessionId}`).then((res) => {
          return res.json();
        })
      );
    }
    fetchSession();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const checkId = urlParams.get('check_id') ?? null;
    const userId = urlParams.get('user_Id') ?? null;
    setMyId(userId);
    firebase.database().ref().child(`checksById/${checkId}`).once('value').then( snapshot => {
      setData(snapshot.val())
      setIsLoading(false)
    }).catch(e => {
      console.log('ERROR ', e);
    })
  }, [sessionId]);

  const totalInfo = useMemo(() => {
    const {
      mandatoryTip,
      serviceCharge,
      subtotal,
      tax,
      tipAmount,
    } = data;

    const {amount_total} = session;
    console.log('TOTAL ', amount_total)

    const sub = Number(subtotal) / 100;
    const taxInt = (Number(tax)/100000 * sub);
    const tipInt = Number(tipAmount) /100;
    const totalInt = Number(amount_total) / 100;

    return {
      subtotal: `$${sub.toFixed(2)}`,
      tax: `$${taxInt.toFixed(2)}`,
      tipString: `$${tipInt.toFixed(2)}`,
      total: `$${totalInt.toFixed(2)}`,
    }

  },[data, session])


  return (
    <div className={classes.root}>
      <div className={classes.successHeader}>
        <span>Your payment was successful!</span>
      </div>
      <div className="sr-section completed-view">

      {isLoading ? (
        <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <ContactlessItemsList itemsById={data.itemsById} itemIds={data.itemIds}/>
          <ContactlessTotal totalInfo={totalInfo}/>
        </>
      )}
      </div>
    </div>
  );
};

export default ContactlessSuccess;
