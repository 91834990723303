/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import Avatar from '../ocrComponents/guestAvatars/Avatar';
import { Typography } from '@material-ui/core';
import { buttonPressed } from '../../API/mixpanel';

const emojis = ['avocado', 'banana', 'eggplant', 'lollipop', 'meat', 'mushroom', 'sushi', 'taco'];

function getShuffledEmojis(array: string[]) {
  const randomInt = Math.floor(Math.random() * emojis.length);
  const emoji = array[randomInt];
  array.splice(randomInt, 1);
  array.unshift(emoji)
  return array;
}

const shuffledEmojis = getShuffledEmojis(emojis)


export default function SelectYourOwnAvatar({ select, selectedEmoji }:{
  select: (emoji: string | null | undefined) => void,
  selectedEmoji: string | null | undefined,
}) {

  useEffect(() => {
    select(shuffledEmojis[0]);
  }, [select])
  
  return (
    <div style={{
      paddingBottom: 16,
      width: '100%',
      overflowX: 'scroll',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Typography style={{
        fontSize: '12px',
        color: '#8C8A88'
      }}>CHOOSE YOUR EMOJI</Typography>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }}>
        {shuffledEmojis.map((emoji, i) => (
          <div
            key={`select-ur-own-emoji-${i}`}
            onClick={() => {
              buttonPressed({
                buttonName: 'selectEmoji',
                source: 'SelectYourOwnAvatar',
                meta: {
                  emoji,
                } 
              })
              select(emoji);
            }}
          >
            <Avatar 
              avatarSize={3}
              containerSize={4}
              isSelected={selectedEmoji === emoji}
              showText={false}
              emoji={emoji}
            />
          </div>
        ))}
      </div>
    </div>
  )
}