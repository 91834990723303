import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";


const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: [[ '1em' ]],
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginBottom: '.5em'
    }
  },
  restaurantName: {
    fontSize: '1.5em',
  },
  row: {
    marginBottom: '.5em',
  },
  nameAndPriceRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  extrasRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.5em',
    marginLeft: '.5em',
  },
});

const formatPrice = price => {
  const isNeg = price[0] === '-';
  const str = isNeg ? price.substring(1) : price;
  return isNeg ? `(-$${(Number(str)/ 100).toFixed(2)})`: `$${(Number(str)/ 100).toFixed(2)}`
}


export default function ContactlessItemsList({itemsById, itemIds}) {
  const classes = useStyles();

  return (
    <div>
      {
        itemIds.length > 0 && itemIds.map( itemId => {
          const { name, qty, price, extras, extras: { description = [], charge = [] } } = itemsById[itemId];
          return (
            <div key={uuidv4()} className={classes.row}>
              <div className={classes.nameAndPriceRow}>
                <span>{name}<span>({qty})</span></span>
                <span>{formatPrice(price)}</span>
              </div>
              {
                !!extras && (
                  <div>
                    { description.length > 0 && description.map(desc => {
                      return <div className={classes.extrasRow} key={uuidv4()}>{desc}</div>
                    })}
                    { charge.length > 0 && charge.map(({chargeDescription, price: chargePrice}) => {
                      return <div key={uuidv4()} className={classes.extrasRow}>
                        <span>{chargeDescription}</span><span>{formatPrice(chargePrice)}</span>
                      </div>
                    })}
                  </div>
                )
              }
          </div>
          )})
      }
    </div>
  );
}
