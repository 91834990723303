import React from 'react';
import firebase from '../../../services/firebase';
import { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import Adornments, { Adornment } from '../Adornments/Adornments';

const SIZE = {
  extraLarge: 120, // typically profile page icons
  large: 102, // typically profile cards
  medium: 56, // typically on profile and feed lists
  small: 44, // typically on lists of profile snippets
  extraSmall: 34, // same case as small, but when space is really tight
  extraExtraSmall: 26, // for tab icons
}
export type SIZE_TYPE = keyof typeof SIZE;
const FONT_SIZE = {
  extraLarge: 12,
  large: 12,
  medium: 12,
  small: 12,
  extraSmall: 12,
  extraExtraSmall: 12,
}

type AvatarProps = {
	size: SIZE_TYPE,
	uid: string,
	name: string,
	defaultImage: string,
	adornments?: Adornment[],
	showName?: boolean,
	textColor?: string,
	isGhost?: boolean
};

const Avatar = ({
  size,
  uid,
  name,
  defaultImage,
  adornments,
  showName = false,
  textColor,
  isGhost = false,
}: AvatarProps) => {
  const [ useFallback, setUseFallback ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ profileImageUrl, setProfileImageUrl ] = useState<string>();

  useEffect(function getUserProfilePicUrl(){
    const path = `profilePics/${uid}`;
    firebase.storage().ref(path).getDownloadURL()
      .then(setProfileImageUrl)
      .catch(() => defaultImage && setUseFallback(true))
      .finally(() => setIsLoading(false));
  }, [uid, defaultImage]);
	
  const sizeNumber = SIZE[size];

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center',
      opacity: isGhost ? .5 : 1,
    }}>
      <Adornments adornments={adornments}>
        <div style={{
          overflow: 'hidden',
          width: sizeNumber,
          height: sizeNumber,
          borderRadius: sizeNumber / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          { isLoading ? (
            <CircularProgress />
          ) : (
            <img 
              src={useFallback ? defaultImage : profileImageUrl}
              alt={`${name}'s profile`}
              style={{ width: sizeNumber, height: sizeNumber }}
              onError={() => {
                setUseFallback(true);
              }}
            />
          )}
        </div>
      </Adornments>
      { showName && (
        <Typography style={{ color: textColor, fontSize: FONT_SIZE[size], textAlign: 'center' }}>
          { (name || "").split(' ')[0] }
        </Typography>
      )}
    </div>
  );
};

export default Avatar;
