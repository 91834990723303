import React, { useMemo } from "react";
import AvatarV2, { SIZE_TYPE } from "./AvatarV2";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Adornment } from "../Adornments/Adornments";
export type { SIZE_TYPE };

type GuestAvatarsProps = {
  guests: ({
		id?: string,
		name: string,
		avatar: string,
		contactId?: string,
		isGhost?: boolean
	} | null) [] | null,
  textColor?: string,
	compact?: boolean,
	size: SIZE_TYPE,
	orderBoost?: string[],
	adornmentsByUser?: Record<string, Adornment[]>,
  openGuestList?: () => void,
};

export default function GuestAvatars({
  guests,
  compact = false,
  size,
  textColor,
  adornmentsByUser,
  orderBoost,
  openGuestList,
}: GuestAvatarsProps){
  const classes = useStyles();
  const guestList = useMemo(() => {
    return Object.values(guests ?? {}).map(guest => ({
      ...guest,
      avatar: guest?.avatar,
    })).sort((a, b) => {
      if (orderBoost) {
        const aIndex = orderBoost.indexOf(a?.id || '1');
        const bIndex = orderBoost.indexOf(b?.id || '1');
        if (aIndex !== -1 && bIndex !== -1) {
          return aIndex - bIndex;
        } else if (aIndex !== -1) {
          return -1;
        } else if (bIndex !== -1) {
          return 1;
        }
      }
      return a?.id?.localeCompare(b?.id || '') || 0;
    });
  }, [guests, orderBoost]);
  return (
    <div 
      className={clsx([classes.root, compact && classes.compact])}
      onClick={() => openGuestList?.()}
      role={openGuestList ? "button" : undefined}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          openGuestList?.();
        }
      }}
    >
      { guestList?.map(guest => (
        <AvatarV2
          key={guest?.id ?? '1'} 
          uid={guest?.id ?? '1'}
          name={guest?.name ?? 'test'} 
          defaultImage={guest?.avatar ? `/emojis/${guest.avatar}.png` : `/emojis/avocado.png`} 
          showName={!compact}
          textColor={textColor}
          size={size}
          adornments={adornmentsByUser?.[guest.id || '1']}
          isGhost={guest?.isGhost || false}
        />
      ))}
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    gap: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  compact: {
    gap: 0,
    '&>*:not(:first-child)' : {
      marginLeft: theme.spacing(-2),
    }
  },
}));
