import React, { useContext } from 'react';
import { Redirect } from 'react-router';
// import behindLogin from '../authComponents/BehindLogin';
import AuthContext from '../context/AuthContext';

const CurrentUserPlacesContainer = () => {
  const { myId, isAuthenticating } = useContext(AuthContext);
  if (isAuthenticating) return null;
  return myId ? (
    <Redirect to={`/places/${myId}`} />
  ) : (
    <Redirect to={`/upload`} />
  );
};

export default CurrentUserPlacesContainer;
// export default behindLogin(CurrentUserPlacesContainer);
