import React, { useEffect, useState } from "react";
import CountdownBanner from './Countdown/CountdownBanner';
import SummaryDownloadBanner from './SummaryDownloadBanner';
import PropTypes from 'prop-types';

const propTypes = { countdownEndDate: PropTypes.number || null };

export default function SplytDownloadBanner({ countdownEndDate }) {
  const [showCountdown, setShowCountdown] = useState(false);

  useEffect(() => {
    const show = countdownEndDate && countdownEndDate > Date.now();
    setShowCountdown(show)
  }, [countdownEndDate])

  return showCountdown ? (
    <CountdownBanner countdownEndDate={countdownEndDate}/>
  ) : (
    <SummaryDownloadBanner />
  );
}

SplytDownloadBanner.propTypes = propTypes;