import React, { useRef, useEffect } from 'react';
import { Input } from '@material-ui/core';

const NameInput = ({ name, handleChange }) => {
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus()
  }, [])
  return (
    <Input
      value={name}
      onChange={(e) => handleChange(e.target.value)}
      inputProps={{ 'aria-label': 'description', 'ref': inputRef }} />
  )
}

export default NameInput;
