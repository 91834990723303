import React, {useState, useRef, useEffect, useMemo, useCallback, useContext} from 'react';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PaymentDialog from '../uploadComponents/PaymentDialog';
import NameDialog from '../NameDialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { setUserPaymentInfo } from '../../API/users';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AuthContext from "../../context/AuthContext";
import clsx from 'clsx';
import firebase from '../../services/firebase';
import { borderGrey } from '../../style/colors'
import * as analytics from '../../API/mixpanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  borderBottom: {
    width: '100%',
    borderBottom: `1px solid ${borderGrey}`,
    marginBottom: theme.spacing(2),
  },
  toolBar: {
    minHeight: '3rem',
  },
  menuButton: {
    height: '2rem',
    marginRight: theme.spacing(2),
    position: 'absolute',
  },
  titleWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  linkUnstyle: {
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit',
      cursor: 'inherit',
    }
  }
}));

export default function Header({
  isAnon,
  isLoggedIn,
  userName,
  handleLogout,
  showSignIn,
  hasMenu = false,
  hasBorderBottom = false,

}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [ isShowingPaymentDialog, setIsShowingPaymentDialog ] = useState(false);
  const [ paymentVal, setPaymentVal ] = useState({
    venmo: '',
    cashApp: '',
  });
  const [nameDialogConfig, setNameDialogConfig] = useState({open: false, ref: null, tempId: null});
  const [nameDialogVal, setNameDialogVal] = useState('');

  const location = useLocation();
  const { myId: userId, user, userFromDb, profile }  = useContext(AuthContext);

  const handleClick = (event) => {
    analytics.clickedHamburgerMenu()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLoginLogoutClick = useCallback(() => {
    if (isAnon || !isLoggedIn) {
      showSignIn();
    }
    handleLogout();
    showSignIn()
  }, [isAnon, isLoggedIn, handleLogout, showSignIn])


  const handleUpdatePaymentClick = useCallback(() => {
    handleClose();
    setIsShowingPaymentDialog(true);
  }, [handleClose])

  const isAnonOrLoggedOut = useMemo(() => {
    return !user || user?.isAnonymous
  }, [user])

  const logInText = useMemo(() => {
    return isAnonOrLoggedOut ? 'login' : 'logout'
  }, [isAnonOrLoggedOut])

  // const handleLogout = useCallback(() => {
  //   setIsShowingSignIn(true);
  //   signOut();
  // }, [])

  useEffect(() => {
    const venmo = userFromDb?.paymentInfo?.venmo ?? '';
    const cashApp = userFromDb?.paymentInfo?.cashApp ?? '';

    if(venmo.length || cashApp.length) {
      setPaymentVal({
        venmo,
        cashApp,
      })
    }
  }, [setPaymentVal, userFromDb])

  const handlePaymentValChange = useCallback((field, val) => {
    !val.includes('@') && setPaymentVal( state => ({
      ...state,
      [field]: val,
    }))
  }, [setPaymentVal])

  const checkId = useMemo(() => {
    if(location.pathname.includes('ocr')) {
      return location.pathname.split('/').pop()
    }

    return null;

  }, [location])

  const handleSubmitPaymentInfo = useCallback(() => {
    user?.uid && setUserPaymentInfo(user.uid, paymentVal, checkId)
    setIsShowingPaymentDialog(false);
  }, [setIsShowingPaymentDialog, user, paymentVal, checkId]);

  const clearNameFormData = useCallback(() => {
    setNameDialogConfig({open: false, ref: null, tempId: null});
  }, [setNameDialogConfig]);

  const updateUserName = useCallback(() => {
    firebase.firestore().collection('users').doc(userId).set({ name: nameDialogVal }, { merge: true }).then(clearNameFormData);
  }, [ userId, nameDialogVal, clearNameFormData]);

  const handleUpdateUserName = useCallback(() => {
    const userName = profile?.name ?? '';
    setNameDialogVal(userName);
    setNameDialogConfig({open: true, ref: null, tempId: userId});
    handleClose();
  }, [setNameDialogVal, profile?.name, userId, handleClose]);


  return (
    <div className={clsx(classes.root, hasBorderBottom && classes.borderBottom)}>
      <AppBar position="static" elevation={0} color="tertiary">
        <Toolbar classes={{root: classes.toolBar}}>
          {hasMenu && (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem disabled={isAnonOrLoggedOut} onClick={() => { handleUpdateUserName(); analytics.clickedUpdateUsername();  }}>Update User Name</MenuItem>
                <MenuItem
                  disabled={isAnonOrLoggedOut}
                  onClick={() => { handleUpdatePaymentClick(); analytics.clickedUpdatePaymentsFromHeader()}}
                >
                  Update Payment Info
                </MenuItem>
                <MenuItem  className={classes.linkUnstyle} onClick={() => {}}>
                  <Link to={`/places/${userId}`}>My Splyts</Link>
                </MenuItem>
              </Menu></>
          )}
          <div className={classes.titleWrapper}>
            <Link className={clsx(classes.title, classes.linkUnstyle)} to={`/places/${userId}`}>
              {/* The design calls for a super bold weight that exceeds 900, so an image is used */}
              <img alt="Splyt" src="/splyt-super-bold.png" width="80" />
            </Link>
          </div>
          {/* { isAnonOrLoggedOut ? (
            <Button onClick={() => handleLoginLogoutClick} color="inherit">{ logInText }</Button>
          ) : (
            <Typography>
                Welcome {userName}
            </Typography>
          )} */}
        </Toolbar>
      </AppBar>
      <PaymentDialog
        isSignedIn={!!user}
        open={isShowingPaymentDialog}
        paymentValues={paymentVal}
        setPaymentInfo={(field, val) => handlePaymentValChange(field, val)}
        submitFunc={handleSubmitPaymentInfo}
        onClose={() => {setIsShowingPaymentDialog(false)}}
      />
      <NameDialog 
        open={nameDialogConfig.open}
        submitFunc={updateUserName} 
        dbRef={nameDialogConfig.ref}
        val={nameDialogVal} 
        setVal={setNameDialogVal}
        tempId={nameDialogConfig.tempId}
        clearNameFormData={clearNameFormData}
        setGuests={() => {}}
        setMe={() => {}}
      />
    </div>
  );
}

Header.propTypes = {

}

