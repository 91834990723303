import React, { useEffect, useState } from 'react';
import { Dialog, TextField, DialogContent, DialogTitle, makeStyles, Button, CircularProgress, FormHelperText } from '@material-ui/core';
import PhoneNumberInput from './PhoneNumberInput';
import firebase from '../../services/firebase';
import { isObject } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Theme } from '@material-ui/core';
import SelectYourOwnAvatar from './SelectYourOwnAvatar';

const { auth } = firebase;

type Form = { name: string, phone: string, mode: 'login' | 'signup' };
type AskPhoneNumberModalProps = {
	isOpen?: boolean,
	title: string,
	subtitle: string,
	form: Form,
  isModSignup: boolean,
  selectedEmoji?: string | null | undefined,
  isModSubscriber?: boolean,
	onUpdateForm: (form: Form) => void,
	onContinue: (confirmation: firebase.auth.ConfirmationResult) => void,
  selectEmoji?: (emoji: string | null | undefined) => void,
	recaptcha: firebase.auth.RecaptchaVerifier | undefined,
};

export default function AskPhoneNumberModal({
  isOpen = false,
  title,
  subtitle,
  form,
  isModSignup,
  selectedEmoji,
  isModSubscriber = false,
  onUpdateForm,
  onContinue,
  selectEmoji = (emoji) => {},
  recaptcha,
}: AskPhoneNumberModalProps) {
  const classes = useStyles({ isModSubscriber });
  const [ isProcessing, setIsProcessing ] = useState(false);
  const [ isValidPhone, setIsValidPhone ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState('');

  useEffect(function trackSeenLoginPage() {
    if (!isOpen) return;

    mixpanel.track('Seen Web Login Page');
  }, [isOpen]);

  function updateForm(key: keyof Form, value: string) {
    let newForm = { ...form, [key]: value };
    onUpdateForm(newForm);
  }

  async function initiateSignup() {
    if (isProcessing) return;
    if (!recaptcha) throw new Error('Recaptcha initialization failed');
    setIsProcessing(true);
    auth().useDeviceLanguage();

    try {
      const confirmResult = await auth().signInWithPhoneNumber(form.phone, recaptcha);
      onContinue(confirmResult);
    } catch (err) {
      if (isAuthError(err)) {
        // we cane check against err.code here if need be for custom stuff
        setErrorMessage(err.message);
      } else if (err instanceof Error) {
        setErrorMessage(err.message);
      } else {
        setErrorMessage('Something went wrong');
      }
    }
    setIsProcessing(false);
  }
  return (
    <Dialog
      fullScreen
      open={isOpen}
      aria-labelledby="sign-up-title"
      style={{zIndex: 100000000}}
      classes={{ paper: classes.signup }}
    >
      <DialogTitle style={{paddingBottom: 0 }}id="sign-up-title">{ title }</DialogTitle>
      <DialogContent>
        {!isModSignup && isModSubscriber && <SelectYourOwnAvatar select={selectEmoji} selectedEmoji={selectedEmoji} />}
        <div className={classes.inputWrapper}>
          { form.mode === 'signup' ? (
            <TextField
              label="Your Name"
              variant="outlined"
              autoComplete="name"
              value={form.name}
              onChange={(e) => updateForm('name', e.target.value)}
              InputLabelProps={{ focused: true, shrink: true }}
            />
          ) : null}
          <PhoneNumberInput
            value={form.phone}
            onChange={({ phone, isValid }) => {
              updateForm('phone', phone);
              setIsValidPhone(isValid);
              setErrorMessage('');
            }}
            helperText="Strictly for group updates. No spam."
            onBlur={() => !isValidPhone ? setErrorMessage('Please enter a valid phone number') : null}
          />
          <FormHelperText error>{ errorMessage }</FormHelperText>
          <Button
            fullWidth
            id="continue-signup"
            onClick={initiateSignup}
            disabled={!isValidPhone || (form.mode === 'signup' && !form.name)}
            startIcon={isProcessing ? <CircularProgress style={{ color: 'white' }} size={20} /> : null}
            variant="contained"
            color="primary"
          >
						Continue
          </Button>
          <Button
            variant="text"
            fullWidth
            onClick={() => onUpdateForm({
              ...form, 
              mode: form.mode === 'login' ? 'signup' : 'login'
            })}
          >
            { form.mode === 'signup' ? 'login' : 'sign up' }
          </Button>
          <a href="https://www.instagram.com/splyt.co/" target="blank" style={{textDecoration: 'none', color: 'white', alignSelf: 'center',}}>
            <div
              style={{
                backgroundImage: 'url(/instagram.png)', /* Replace with the actual path to your image */
                backgroundSize: 'cover', /* Adjust this property based on your image dimensions */
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                width: 175,
                height: 30,
                color: 'white',
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                borderRadius: 4,
              }}>
              <img style={{width: 14, height: 14, paddingRight: 6}}src="/instagram-logo-white.png" alt="Instagram logo" />
              Give Feedback
            </div>
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
}

// firebase.auth.Error isn't exposed in the typescript definitions
// so this is the only way to check for the error type
function isAuthError(err: unknown): err is { name: string, code: string, message: string } {
  return isObject(err)
		&& 'code' in err
		&& typeof err.code === 'string' 
		&& 'message' in err
		&& typeof err.message === 'string'
}

const useStyles = makeStyles<Theme, { isModSubscriber: boolean}>((theme) => ({
  signup: props => ({
    maxHeight: '70vh',
    alignSelf: 'flex-end',
    zIndex: 1000000000,
    backgroundImage: props.isModSubscriber ? 'url(/background-gradient7.jpeg)' : '', /* Replace with the actual path to your image */
    backgroundSize: 'cover', /* Adjust this property based on your image dimensions */
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  }),
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));
