import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import DrawerModal from "./DrawerModal";
import LightBlueButton from "../ThemedComponents/ThemedButtons/LightBlueButton";
import { darkGreen } from "../../style/colors";

type SplytItemsInstructionModalProps = {
	isOpen: boolean,
	onClose: () => void,
}

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    textAlign: 'center',
    fontSize: '3rem',
  },
  title: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
  },
  desc: {
    color: darkGreen,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    '&>span': {
      fontStyle: 'italic',
    },
  },
  disclaimer: {
    '&>span': {
      fontWeight: 700,
    }
  },
  video: {
    width: '100%'
  },
  wrapper: {
    gap: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function SplytItemsInstructionModal({
  isOpen,
  onClose,
}: SplytItemsInstructionModalProps) {
  const classes = useStyles();

  return (
    <DrawerModal
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={classes.wrapper}>
        <Typography align="center" className={classes.title} component="h2">
					Did you share a plate with someone?
        </Typography>
        <Typography align="center" className={classes.desc} component="p">
					You can tap already claimed items to <Typography component="span">splyt</Typography> the costs
        </Typography>
        <video
          className={classes.video}
          autoPlay
          playsInline
          muted
          loop
          disablePictureInPicture
          controlsList="noplay notimeline nofullscreen nodownload noremoteplayback noplaybackrate"
          // fallback for safari
          // eslint-disable-next-line react/no-unknown-property 
          x-webkit-airplay="deny"
        >
          <source src="/share-items.mp4" type="video/mp4" />
        </video>
        <Typography align="center" className={classes.disclaimer}>
          <Typography component="span">Tax &amp; tip</Typography> are shared according to your total
        </Typography>
        <LightBlueButton
          text="Got it"
          handleClick={onClose}
        />
      </div>
    </DrawerModal>
  );
}
