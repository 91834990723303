import React, {useRef} from "react";
import ItemEdit from './ItemEdit';
import Item from './ItemsList/Item/Item';
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import AddCircle from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    padding: [[ '1em' ]],
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginBottom: '2em'
    }
  },
  restaurantName: {
    fontSize: '1.5em',
  },
  row: {
    margin: [['1em', 0]],
    paddingBottom: '.5em',
    borderBottom: '1px solid black',
  },
  nameAndPriceRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  extrasRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '.5em',
    marginLeft: '.5em',
  },
  addIcon: {
    color: 'green',
    paddingLeft: '0px',
  },
});

const formatPriceWithoutQuantity = (price) => {
  const isNeg = price[0] === '-';
  const str = isNeg ? price.substring(1) : price;
  const num = Number(str) / 100;
  return isNeg ? `(-$${num.toFixed(2)})`: `$${num.toFixed(2)}`
}

const formatPrice = (price, qty = 1) => {
  const isNeg = price[0] === '-';
  const str = isNeg ? price.substring(1) : price;
  const num = (Number(str) * Number(qty)) / 100;
  return isNeg ? `(-$${num.toFixed(2)})`: `$${num.toFixed(2)}`
}


export default function ItemsList({
    itemsById,
    itemIds,
    editingIds = [],
    confirmDeleteIds=[],
    handleItemDeleteTap,
    handleItemTap,
    saveItemEdit,
    addItem,
    deleteItem,
    mergeAsExtra,
    handleFocus,
    handleBlur,
    editingById,
    addExtraCharge,
  }) {
    const classes = useStyles();
  return (
    <div>
      {
        itemIds.length > 0 && itemIds.map( (itemId, i) => {
          return <Item 
            key={itemId}
            i={i}
            item={itemsById[itemId]}
            handleTap={handleItemTap}
            confirmDelete={confirmDeleteIds.includes(itemId)}
            handleItemDeleteTap={() => handleItemDeleteTap(itemId)}
            deleteItem={() => deleteItem(itemId)}
            saveEdit={(e) => saveItemEdit(e, itemId)}
            mergeAsExtra={mergeAsExtra}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            isEditing={Object.keys(editingById).includes(itemId)}
            editingById={editingById}
            handleChange={saveItemEdit}
            addExtraCharge={addExtraCharge}
          />
 
        })
      }
        <IconButton
          tap-id="deleteIcon"
          size="small"
          aria-label="delete"
          classes={{root: classes.addIcon}}
          onClick={addItem}
        >
          <AddCircle fontSize="small"/>
          Add Item
        </IconButton>
    </div>
  );
}