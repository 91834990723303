import React from 'react';
import BaseLoadingMask from './BaseLoadingMask';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from '../ocrComponents/Item/DisplayItem';

const useLoadingStyles = makeStyles(theme => ({
  quantityText: {
    width: '.75rem',
    height: '1rem',
    margin: [[ 0, theme.spacing(1) ]],
  },
  itemNameText: {
    height: '1rem',
    maxWidth: '8rem',
  },
  priceText: {
    height: '1rem',
    width: '4rem',
  },
}));

const ItemLoadingMask = (props) => {
  const classes = useStyles(props);
  const loadingClasses = useLoadingStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <BaseLoadingMask classes={{ root: loadingClasses.quantityText }} />
        <div className={classes.rightSide}>
          <div className={classes.namePriceRow}>
            <BaseLoadingMask classes={{ root: loadingClasses.itemNameText }} />
            <BaseLoadingMask classes={{ root: loadingClasses.priceText }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemLoadingMask;
