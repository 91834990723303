import firebase from "../services/firebase";

const endpoints = firebase.functions();

type MoneyWholeUnits = number;
export type CreatePaymentIntentResponse = {
	data: {
		ephemeralKey: string,
		paymentIntent: string,
		paymentId: string,
		customer: string,
	},
};

export async function createPaymentIntent(amount: MoneyWholeUnits, splytId: string) {
  const res = await endpoints.httpsCallable('createStripePaymentIntent')({
		amount: +amount.toFixed(0),
		splytId,
	}) as CreatePaymentIntentResponse;
  return res.data;
}

type UpdatePaymentIntentResponse = {
	data: {
		status: string,
	}
};

export async function updatePaymentTotalForIntent(intentId: string, amount: number) {
	const res = await endpoints.httpsCallable('updateStripePaymentIntent')({
		intentId,
		amount: +amount.toFixed(0),
	}) as UpdatePaymentIntentResponse;
	return res.data;
}
