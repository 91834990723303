import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import BaseLoadingMask from '../../loadingMasks/BaseLoadingMask';
import { darkBlue } from '../../../style/colors';
import { formatPriceStringForDisplay } from '../../../utils/NumberUtils';

const propTypes = {
  label: PropTypes.string,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalsLineItem: {
    ...theme.customType.mediumText,
  },
  totalsLinePrice: {
    ...theme.customType.mediumText,
    ...theme.customType.semiBold,
    color: darkBlue,
  },
}));

const useLoadingStyles = makeStyles(theme => ({
  root: {
    width: '30%',
    height: '1rem',
    marginBottom: theme.spacing(1),
  },
}));

const TotalItem = ({
  label,
  isLoading,
  value,
}) => {
  const classes = useStyles();
  const loadingClasses = useLoadingStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.totalsLineItem} component="span">{ label }</Typography>
      { isLoading ? (
        <BaseLoadingMask classes={loadingClasses} />
      ) : (
        <Typography className={classes.totalsLinePrice} component="span">{ `$${formatPriceStringForDisplay(value || '')}`}</Typography>
      )}
    </div>
  );
};

TotalItem.propTypes = propTypes;
export default TotalItem;
