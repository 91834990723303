import React from 'react';
import { Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {ReactComponent as SelectButton} from '../../../../assets/images/selectButton.svg'
import {ReactComponent as SelectButtonChecked} from '../../../../assets/images/selectButtonChecked.svg'
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2rem',
    height: '2rem',
  },
  icon: {
    height: '100%'
  }
}));

const ShareButtonIcon = ({ isSelected, isDisabled }) => {
  const classes = useStyles()
  return (
    <Icon classes={{root: classes.root}}>
      { isDisabled ? <></> : isSelected ? <SelectButtonChecked classes={{root: classes.icon}}/> : <SelectButton classes={{root: classes.icon}}/> }
    </Icon>
  )
}

ShareButtonIcon.propTypes = {
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default ShareButtonIcon