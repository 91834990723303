import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TotalItem from '../../SummaryModal/TotalItem/TotalItem';
import { Typography, makeStyles, Drawer, Box } from '@material-ui/core';


const propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: {

  },
  box: {
    padding: theme.spacing(2),
  }
}));

const CheckInfoDrawer = ({
  open,
  toggleDrawer,
  totalInfo,
  checkTipDollarValue,
  itemizedFeesAndDiscounts = [],
  checkTax,
  checkSubTotal,
}) => {
  const classes = useStyles();

  const tipPercent = isNaN(Number(totalInfo?.tipPercent))
		? (Number(totalInfo?.tip) / Number(totalInfo?.subtotal) * 100).toFixed(0)
		: Number(totalInfo?.tipPercent);

	const items = useMemo(() => {
		const result = [
			{ label: 'Subtotal', value: totalInfo?.subtotal ?? '' },
			{ label: 'Tax', value: totalInfo?.tax ?? '' },
			{ label: `Tip (${tipPercent}%)`, value: totalInfo?.tip ?? '' },
		];
		if (itemizedFeesAndDiscounts?.length > 0) {
			result.push(...itemizedFeesAndDiscounts);
		}
		result.push({ label: 'Check Grand Total', value: totalInfo?.total });
		return result;
	}, [totalInfo, itemizedFeesAndDiscounts]);

  const calculatedCheckTotal = useMemo(() => {
    return Number(checkSubTotal) 
    + Number(checkTax)
    + Number(checkTipDollarValue);
  }, [checkSubTotal, checkTax, checkTipDollarValue])

  return (
    <Drawer
      anchor={'bottom'}
      open={open}
      onClose={toggleDrawer(false)}
    >
      <Box
        sx={{ width: 'auto' }}
        role="presentation"
        onKeyDown={toggleDrawer(false)}
        classes={{root: classes.box}}
      >
        <Typography className={classes.totalsTitle} variant="h4" component="h4">Check Totals</Typography>
        { items.map(({label, value}) => (
          <TotalItem key={label} label={label} value={value} />
        ))}
      </Box>          
    </Drawer>
  );
};

CheckInfoDrawer.propTypes = propTypes;
export default CheckInfoDrawer;
