import React, { useState, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogTitle, DialogContentText, Slide, makeStyles, DialogActions } from "@material-ui/core";
import { isFunction } from 'lodash';
import { payGreen, blue, primaryBorder } from '../../../style/colors';
import { formatPriceStringForDisplay } from '../../../utils/NumberUtils';
import { updatePaymentForSplyt, updateNudgeOnPaymentActivity, nudgePaymentStatus } from '../../../API/checks';
import { setUserCountdownEndDate } from '../../../API/users';
import AuthContext from '../../../context/AuthContext';
import { buttonPressed } from '../../../API/mixpanel';

const PAYMENT_LINK_TYPE_MAP = {
  venmoDeepLink: { label: 'Venmo', event: 'venmo' },
  cashAppDeepLink: { label: 'CashApp', event: 'cashApp' },
};

const REVERSE_PAYMENT_LINK_TYPE_MAP = {
  [PAYMENT_LINK_TYPE_MAP.venmoDeepLink.event]: 'venmoDeepLink',
  [PAYMENT_LINK_TYPE_MAP.cashAppDeepLink.event]: 'cashAppDeepLink',
};

const propTypes = {
  splytId: PropTypes.string,
  handleClick: PropTypes.func,
  paymentLinks: PropTypes.shape({
    venmoDeepLink: PropTypes.string,
    cashAppDeepLink: PropTypes.string,
  }),
  modName: PropTypes.string,
  disabled: PropTypes.bool,
  total: PropTypes.string,
  didConfirmPayment: PropTypes.bool,
  shouldShowConfirmPaymentModalOnReturnToSummary: PropTypes.shape({
    show: PropTypes.bool,
    method: PropTypes.string,
  }),
  countdownEndDate: PropTypes.number || null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  list: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '30px', // explicit
    background: '#fff',
    left: 0,
    transform: `translateY(calc(-100% - ${theme.spacing(4)}px))`,
    filter: 'drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.25))',
    width: '100%',
  },
  listItem: {
    listStyle: 'none',
    '&:not(:last-child)': { borderBottom: primaryBorder, },
  }
}));

const usePayButtonStyles = makeStyles(theme => ({
  root: ({ areOptionsShown }) => ({
    backgroundColor: areOptionsShown ? blue : payGreen,
    color: '#fff',
    textTransform: 'none',
    padding: theme.spacing(2),
    lineHeight: 1.5,
    fontWeight: 'bold',
    fontSize: '1rem',
    borderRadius: '15px', // explicit

    '@media (hover: none)': { backgroundColor: areOptionsShown ? blue : payGreen },
    '&:hover': { backgroundColor: areOptionsShown ? blue : payGreen },
  }),
}));

const usePayOptionButtonStyles = makeStyles(theme => ({
  root: {
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: blue,
    textTransform: 'none',
    fontSize: '1rem',
    padding: theme.spacing(2),
  },
}));

const CheckoutButton = ({
  splytId,
  handleClick,
  paymentLinks,
  modName,
  disabled,
  total,
  didConfirmPayment = false,
  shouldShowConfirmPaymentModalOnReturnToSummary,
  countdownEndDate,
}) => {
  const [ areOptionsShown, setAreOptionsShown ] = useState(false);
  const [ showPrePaymentMessage, setShowPrePaymentMessage ] = useState(false);
  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const { myId } = useContext(AuthContext);

  const [ paymentSelected, setPaymentSelected ] = useState(undefined);

  useEffect(() => {
    const { show = false } = shouldShowConfirmPaymentModalOnReturnToSummary;
    if (show) setShowConfirmation(true)
  }, [shouldShowConfirmPaymentModalOnReturnToSummary, setShowConfirmation])
  const pay = useCallback((eventType, link) => {
    if (!isFunction(handleClick)) return;

    handleClick(eventType);
    setPaymentSelected(eventType);
    setShowPrePaymentMessage(true);
  }, [handleClick]);

  async function confirmPayment() {
    if (!myId) return;

    buttonPressed({ buttonName: 'confirmed did pay' });
    await updatePaymentForSplyt(splytId, myId, {
      status: "success",
      amount: total,
      method: shouldShowConfirmPaymentModalOnReturnToSummary?.method ?? 'venmo'
    });
    setShowConfirmation(false);
  }

  async function rejectPayment() {
    if (!myId) return;
		
    buttonPressed({ buttonName: 'confirmed did not pay' });
    await updatePaymentForSplyt(splytId, myId, undefined);
    setShowConfirmation(false);
  }

  const links = Object.entries(paymentLinks).filter(([type, link]) => !!link);
  function payOrTogglePayDialog() {
    if (links.length > 1) {
      setAreOptionsShown(isOpen => !isOpen);
    } else {
      pay(PAYMENT_LINK_TYPE_MAP[links[0][0]].event, links[0][1]);
    }
  }

  const classes = useStyles();
  const payButtonClasses = usePayButtonStyles({ areOptionsShown });
  const payOptionButtonClasses = usePayOptionButtonStyles();

  if (!myId) return null;

  return (
    <div className={classes.root}>
      <Slide in={areOptionsShown} direction="up">
        <div>
          <ul className={classes.list}>
            { links.map(([type, link]) => (
              <li key={type} className={classes.listItem}>
                <Button
                  fullWidth
                  classes={payOptionButtonClasses}
                  onClick={() => pay(PAYMENT_LINK_TYPE_MAP[type].event, link)}
                >
                  { PAYMENT_LINK_TYPE_MAP[type].label }
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </Slide>
      <Button
        fullWidth
        onClick={payOrTogglePayDialog}
        classes={payButtonClasses}
        disabled={disabled}
      >
        { areOptionsShown ? 'Cancel' : didConfirmPayment ? `Pay again` : `Pay ${modName} $${formatPriceStringForDisplay(total)}`}
      </Button>
      <Dialog
        open={!!showPrePaymentMessage}
        onClose={() => {
          setShowPrePaymentMessage(false);
          setPaymentSelected(undefined);
        }}
      >
        <DialogTitle>
					You will now be redirected to {paymentSelected}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
						Please <b>return to this window</b> after paying to let your friend know you&apos;ve paid.
          </DialogContentText>
          <DialogActions>
            <Button
              href={paymentLinks[REVERSE_PAYMENT_LINK_TYPE_MAP[paymentSelected]]}
              target="splyt-payment"
              onClick={async () => {
                if (!isFunction(handleClick) || !myId) return false;
								
                handleClick(paymentSelected);
                void updateNudgeOnPaymentActivity(myId, splytId, nudgePaymentStatus.UNCONFIRMED);
                await updatePaymentForSplyt(splytId, myId, { status: "processing", amount: total, method: paymentSelected });
                setShowPrePaymentMessage(false);
                setShowConfirmation(true);
              }}
            >
							Ok
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={!!showConfirmation}
        onClose={() => {
          setShowConfirmation(false);
          setPaymentSelected(undefined);
        }}
      >
        <DialogTitle>
					Did you send ${formatPriceStringForDisplay(total)} to your friend?
        </DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={() => {
              rejectPayment();
              void updateNudgeOnPaymentActivity(myId, splytId, nudgePaymentStatus.DID_NOT_PAY);
            }}>
							No
            </Button>
            <Button onClick={() => {
              void confirmPayment('confirmed')
              void updateNudgeOnPaymentActivity(myId, splytId, nudgePaymentStatus.CONFIRMED);
              if (!countdownEndDate) {
                void setUserCountdownEndDate(myId)
              }
            }}>
							Yes
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  )
}

CheckoutButton.propTypes = propTypes;

export default CheckoutButton;
