import React, { useRef, useEffect } from 'react';
import NameInput from './EditFields/NameInput';
import QtyInput from './EditFields/QtyInput';
import PriceInput from './EditFields/PriceInput';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { Button, Input } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import { formatPriceStringForDisplay } from '../../../VerifyContainer';


const useStyles = makeStyles({
  root: {
    position: 'relative',
    color: '#1EA7FD',
    width: '100%',
    // overflow: 'hidden',
  },
  innerContainer: {
    height: '37px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    flex: '0 0 65%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  maxWidth30: {
    maxWidth: '30%'
  },
  qty: {
    flex: '1 0 auto',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '40px',
  },
  price: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteIcon: {
    color: 'red',
    paddingLeft: '0px',
  },
  deleteButton: {
    position: 'absolute',
    right: '-105px'
  },
  blueUnderline: {
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textUnderlineOffset: '1px'
  },
  slideLeft: {
    transition: 'transform, 1s',
    transform: 'translateX(-88px)',
  },
});

const TopLine = ({
  itemId,
  name,
  qty = 1,
  price,
  confirmDelete,
  handleDeleteTap,
  handleChange,
  deleteItem,
  isEditing,
  handleTap,
  editField,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, confirmDelete && classes.slideLeft)}>
      <div className={classes.innerContainer}>
        <div className={clsx(classes.name, editField === 'price' && classes.maxWidth30)}>
          <IconButton
            tap-id="deleteIcon"
            size="small"
            aria-label="delete"
            classes={{root: classes.deleteIcon}}
            onClick={handleDeleteTap}
          >
            <RemoveCircle fontSize="small"/>
          </IconButton>
          {isEditing && editField === 'name'
            ? <NameInput
              name={name}
              handleChange={(val) => handleChange(val, itemId, 'name')}
            />
            : (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <span 
                className={clsx(classes.blueUnderline)}
                onClick={(e) => handleTap(e, itemId, 'name')}
              >
                {name.toUpperCase()}
              </span>
            )
          }
        </div>
        <div className={classes.qty}>
          {
            isEditing && editField === 'qty'
              ? (
                <QtyInput 
                  qty={qty}
                  handleChange={(val) => handleChange(val, itemId, 'qty')}
                />
              ) : (
                <button
                  className={clsx(classes.blueUnderline)}
                  onClick={(e) => handleTap(e, itemId, 'qty')}
                >
                  {qty.length === 0 ? '0': qty}
                </button>
              )}

        </div>
        <div className={clsx(classes.price)}>
          {
            isEditing && editField === 'price'
              ? (
                <PriceInput 
                  // The user action leading up to this showing is clear intent of modifying the price
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={true}
                  price={price}
                  handleChange={(val) => handleChange(val, itemId, 'price')}
                />
              ) : (
                <button className={clsx(classes.blueUnderline)} onClick={(e) => handleTap(e, itemId, 'price')}>
                  {formatPriceStringForDisplay(price)}
                </button>
              )}
        </div>
        <div className={clsx(classes.deleteButton)}>
          <Button variant="contained" color="secondary" onClick={deleteItem}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TopLine