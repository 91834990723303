import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { Button, Dialog, DialogActions, DialogContent, Slide } from "@material-ui/core";
import { payGreen } from "../../style/colors";

type PaymentSheetProps = {
	isShown: boolean,
	onClose: () => void,
};
const TRANSITION_PROPS = { direction: 'up' } as const;

export default function PaymentSheet({
  isShown,
  onClose,
}: PaymentSheetProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [ errorMessage, setErrorMessage ] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/${window.location.pathname}`,
      },
      redirect: 'if_required',
    });

    if (error) {
      console.log(error);
      setErrorMessage(error.message || 'An unknown error occurred');
      return;
    }

    onClose();
  };

  return (
    <Dialog
      open={isShown}
      onClose={onClose}
      fullWidth
      TransitionComponent={Slide}
      /** @ts-expect-error This is correct */
      TransitionProps={TRANSITION_PROPS}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <PaymentElement
            onChange={() => setErrorMessage('')}
            options={{
              paymentMethodOrder: ['apple_pay','google_pay','cashapp', 'card'],
              layout: {
                type: 'accordion',
                radios: false,
              },
            }}
          />
          { errorMessage && <div style={{ color: 'red' }}>{ errorMessage }</div>}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            type="submit"
            style={{ backgroundColor: payGreen }}
            variant="contained"
            disabled={!stripe}
          >
						Complete Payment
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}