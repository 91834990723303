import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: [['1px', 'solid', 'grey']],
    borderTop:[['1px', 'solid', 'grey']],
    width: '100%',
    zIndex: 100,
    backgroundColor: 'white',
    maxWidth: '500px',
    height: '2.3em',
  },
});

const VerifyHeader = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <Button>Cancel</Button> */}
      <h3>Review items and add tip</h3>
      {/* <Button>Continue</Button> */}
    </div>
  )
}

export default VerifyHeader
