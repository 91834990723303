/** @todo remove and fix linting issues for a11y */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { Redirect } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { scanReceipt, saveOCRData } from '../API/ocrData';
import { setUserPaymentInfo, setHasSeenInstructions } from '../API/users';
import { resizeImage } from '../utils';
import PaymentDialog from './uploadComponents/PaymentDialog';
import LoadingDialog from './uploadComponents/VerifyLoadingDialog';
import SignUpModal from './authComponents/SignUpModal';
import AuthContext from "../context/AuthContext";
import LightBlueButton from './ThemedComponents/ThemedButtons/LightBlueButton';
import UploadInstructionModal from './InstructionModal/UploadInstructionModal';
import * as analytics from '../API/mixpanel'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
  },
  buttonRoot: {
    width: '90%',
    marginBottom: theme.spacing(2),
  },
  cancelButton: {
    marginTop: theme.spacing(2)
  },
}));

const IMAGE_STATE = {
  EMPTY: 1,
  PROCESSING: 2,
  LOADED: 3,
};


const Upload = () => {
  const classes = useStyles();
  const uploadedImage = useRef(null);
  const imageUploader = useRef(null);
  const { isSignedIn, user, userFromDb, isAuthenticating }  = useContext(AuthContext);

  const [ imageState, setImageState ] = useState(IMAGE_STATE.EMPTY);
  const [ paymentVal, setPaymentValState ] = useState({
    venmo: '',
    cashApp: '',
  });
  const [ ocrData, setOcrData ] = useState(null);
  const [ verifyLoading, setVerifyLoading ] = useState(false);
  const [ verifyComplete, setVerifyComplete ] = useState(false);
  const [ isShowingPaymentDialog, setIsShowingPaymentDialog ] = useState(false);
  const [ confirmedPaymentInfo, setConfirmedPaymentInfo ] = useState(false);
  const [ areInstructionsOpen, setAreInstructionsOpen ] = useState(false);

  useEffect(() => {
    const venmo = userFromDb?.paymentInfo?.venmo ?? '';
    const cashApp = userFromDb?.paymentInfo?.cashApp ?? '';

    if(venmo.length || cashApp.length) {
      setPaymentValState({
        venmo,
        cashApp,
      })
    }
  }, [setPaymentValState, userFromDb])

  useEffect(() => {
    const hasSeenInstructions = userFromDb?.seenInstructions?.upload ?? false;
    setAreInstructionsOpen(isSignedIn && !isAuthenticating && !hasSeenInstructions);
  }, [isSignedIn, isAuthenticating, userFromDb]);

  const handleCloseInstructions = useCallback(() => {
    setAreInstructionsOpen(false);
    setHasSeenInstructions(user.uid, 'upload', true);
    analytics.confirmedUploadInstructions();
  }, [user]);

  const setPaymentVal = useCallback((field, val) => {
    console.log('FIELD ', field)
    !val.includes('@') && setPaymentValState( state => ({
      ...state,
      [field]: val,
    }))
  }, [setPaymentValState])

  const handleImageUpload = e => {
    const file = e.target.files[0];
    if (file) {
      setImageState(IMAGE_STATE.PROCESSING);
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      
      reader.onload = async (e) => {
        current.src = e.target.result;
        current.id = uuidv4();
        
        try {
          current.src = await resizeImage(1000, current.src);
        } catch (err) {
          console.error('Failed to resize image, falling back to using the full image', err);
        }

        setImageState(IMAGE_STATE.LOADED);
      }
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitPaymentInfo = useCallback(() => {
    user?.uid && setUserPaymentInfo(user.uid, paymentVal)
    setConfirmedPaymentInfo(true)
    setIsShowingPaymentDialog(false);
  }, [setConfirmedPaymentInfo, setIsShowingPaymentDialog, user, paymentVal]);

  useEffect(() => {
    if (!!ocrData && confirmedPaymentInfo) {
      saveOCRData(uploadedImage?.current?.id ?? uuidv4(), {
        ...ocrData,
        userPaymentInfo: paymentVal,
        moderatorId: user?.uid ?? 'noId',
        itemsAlreadyPaidFor: { initial: 'initial'}
      })
        .then(() => setVerifyComplete(true))
        .catch(error => {
          console.log('ERROR OCR ', error);
        });
    }
  }, [ocrData, confirmedPaymentInfo, paymentVal, user]);

  const redirectToCheck = (id) => <Redirect to={`/verify/${id}`}/>;

  useEffect(() => {
    const id = uploadedImage?.current?.id ?? 'noImage';
    if(verifyComplete === true) {
      redirectToCheck(id)
    }
  }, [verifyComplete] );

  const submitReceipt = useCallback(() => {
    analytics.clickedUploadReceipt();
    setVerifyLoading(true);
    mixpanel.track("opened payment dialog ");
    setIsShowingPaymentDialog(true);

    scanReceipt(uploadedImage?.current?.id ?? uuidv4(), uploadedImage.current.src)
      .then(res => {
        mixpanel.track("receipt succesfully uploaded", {"receipt": res.body});
        setOcrData(res.body);
      }).catch(error => console.log('ERROR', error))

  }, [setVerifyLoading, setIsShowingPaymentDialog, setOcrData])

  const handleTakeAPictureClick = () => {
    analytics.clickedTakeAPicture();
    imageUploader.current.click()
  };

  const handleRemovePicClick = () => {
    mixpanel.track("clicked remove picture ", {});
    imageUploader.current.click()
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        height: '100%',
        maxWidth: '500px',
        paddingBottom: '2rem',
      }}
    >

      <span style={{fontSize: '2em'}} aria-label="camera-emoji" role="img">📸</span>
      <h3>Take a picture of your receipt</h3>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={imageUploader}
        style={{ display: "none" }}
      />
      <div
        onClick={() => { imageState === IMAGE_STATE.EMPTY && handleTakeAPictureClick()}}
        style={{
          height: "400px",
          width: "300px",
          backgroundColor: '#d8d8d8',
          border: "1px #979797",
          borderRadius: "12px",
          margin: "2rem",
          position: 'relative',
        }}
      >
        <img
          ref={uploadedImage}
          alt="upload your receipt"
          style={{
            display: imageState === IMAGE_STATE.LOADED ? 'block' : 'none',
            width: "100%",
            height: "100%",
          }}
        />
        { imageState === IMAGE_STATE.PROCESSING && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
          }}>
            <CircularProgress />
          </div>
        )}
      </div>
      <div className={classes.buttonContainer}>
        {
          imageState === IMAGE_STATE.LOADED 
            ? (
              <>
                <LightBlueButton  text={'Upload Receipt'} handleClick={submitReceipt}/>
                <Button
                  classes={{root: classes.cancelButton}}
                  color="secondary" variant="contained"
                  fullWidth={true}
                  onClick={handleRemovePicClick}>
                    Remove and Try again
                </Button>
              </>
            ) 
            : <LightBlueButton  text="Take a picture" handleClick={handleTakeAPictureClick}/>
        }
      </div>
      <PaymentDialog
        isSignedIn={isSignedIn}
        open={isShowingPaymentDialog}
        paymentValues={paymentVal}
        setPaymentInfo={(field, val) => setPaymentVal(field, val)}
        submitFunc={handleSubmitPaymentInfo}
      />
      <UploadInstructionModal
        isOpen={areInstructionsOpen}
        onOpen={() => setAreInstructionsOpen(true)}
        onClose={handleCloseInstructions}
      />
      <LoadingDialog open={!isShowingPaymentDialog && verifyLoading} />
      <SignUpModal open={!isSignedIn} isModSignup={true}/>
      { verifyComplete && <Redirect to={`/verify/${uploadedImage?.current?.id}`}/>}
    </div>
  )
}

export default Upload;
