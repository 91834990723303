import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { darkGreen } from '../../../style/colors';

const propTypes = {
  createDate: PropTypes.number,
  updateDate: PropTypes.number,
};

function formatDate(timestamp) {
  let x = new Date(timestamp);
  return `${x.getMonth() + 1} / ${x.getDate()} / ${x.getFullYear()}`;
}
const CONSTANTS = {
  HOT: {
    emojiAlt: 'Beach',
    emoji: '🏖️',
    title: 'Sit back & relax',
    desc: () => 'You\'re done! Your friends will be prompted to pay you when they\'re done selecting.'
  },
  COLD:  {
    emojiAlt: 'Check Mark',
    emoji: '✔️',
    title: 'This splyt is complete',
    desc: (createDate) => createDate && `This splyt was created on ${formatDate(createDate)}`,
  },
};

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    textAlign: 'center',
    fontSize: '3rem',
    marginTop: theme.spacing(2),
  },
  completionMessage: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  desc: {
    ...theme.customType.mediumText,
    color: darkGreen,
    marginBottom: theme.spacing(4),
  },
}));

const THRESHOLD = 1000 * 60 * 60 * 24; // 24 hours
const ModeratorCompletionText = ({
  createDate,
  updateDate,
}) => {
  const classes = useStyles();
  const text = !updateDate || Date.now() > updateDate + THRESHOLD ? CONSTANTS.COLD : CONSTANTS.HOT;

  return (
    <>
      <span className={classes.icon} role="img" aria-label={`${text.emojiAlt} Emoji`}>
        { text.emoji }
      </span>
      <Typography align="center" className={classes.completionMessage} component="h3">
        { text.title }
      </Typography>
      <Typography align="center" className={classes.desc} component="p">
        { text.desc(createDate) }
      </Typography>
    </>
  );
};

ModeratorCompletionText.propTypes = propTypes;
export default ModeratorCompletionText;
