import React from 'react';
import TipButton from './TipButton';
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';


const useStyles = makeStyles({
  buttonsRow: {
    marginBottom: '1em',
    display: 'flex',
    '& .MuiButton-root': {
      width: '100%',
    }
  },
  buttonContainer: {
    'flex': [[1, 1, 0]],
  },
  textField: {
    width: '14em',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const TipButtons = ({handleTipButtonClick, tipPercent, dollarAmounts, calculatedTipAmount}) => {
  const classes = useStyles()
  return (
    <div> 
      <div className={classes.buttonsRow}>
        {['18', '20', '22', 'custom'].map((percent => {
          const dollarAmount = percent === 'custom' ? calculatedTipAmount : dollarAmounts[percent];
          const tipDollarAmountString = (Number(dollarAmount)/100).toFixed(2).toString()
          const isActive = tipPercent === percent;
          const label = percent === 'custom' ? 'custom' : `${percent}%`;

          return (
            <div 
              className={classes.buttonContainer}
              key={percent}>
              <TipButton
                tipDollarAmountString={tipDollarAmountString}
                label={label}
                extra={tipPercent}
                isActive={isActive}
                handleClick={() => handleTipButtonClick(percent)}
                calculatedTipAmount={calculatedTipAmount}
              />
            </div>
          )
        }))}
      </div>
    </div>
  )
}

TipButtons.propTypes = {
  handleTipButtonClick: PropTypes.func,
  tipPercent: PropTypes.string,
  dollarAmounts: PropTypes.shape({
    '18': PropTypes.string,
    '20': PropTypes.string,
    '22': PropTypes.string,
  }),
  calculatedTipAmount: PropTypes.string,
};

export default TipButtons;