import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'firebase/compat/app-check';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (process.env.REACT_APP_IS_LOCAL === 'true') {
  // eslint-disable-next-line no-restricted-globals
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

firebase.initializeApp(config);
const appCheck = firebase.appCheck();
appCheck.activate(process.env.REACT_APP_APP_CHECK_TOKEN, true);

appCheck.onTokenChanged((token) => {
  if (token) {
    console.log('Token changed, got a new token: ', token);
  }
}, (error) => {
  console.error('Error fetching a new token: ', error);
});

export default firebase;
