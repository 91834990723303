import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { darkGreen } from '../../../style/colors';

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: {
    display: 'block',
    textAlign: 'center',
    fontSize: '3rem',
  },
  title: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&>span': {
      fontSize: '1.125rem',
      fontWeight: 900,
      fontStyle: 'italic',
    },
  },
  desc: {
    ...theme.customType.mediumText,
    color: darkGreen,
    marginBottom: theme.spacing(4),
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    '&>span': {
      ...theme.customType.mediumText,
      fontWeight: 700,
      fontStyle: 'italic',
    },
  },
}));

const EmptyPlacesList = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <span className={classes.icon} role="img" aria-label="Card Index Divider Emoji">🗂️</span>
      <Typography align="center" className={classes.title} component="h2">
        You haven&apos;t splyt any orders
      </Typography>
      <Typography align="center" className={classes.desc} component="p">
        Get started by <Link className={classes.desc} to="/upload">creating a splyt</Link>
      </Typography>
    </div>
  );
};

export default EmptyPlacesList;
