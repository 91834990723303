import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginBottom: '1em',
  },
  labelsColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  infoColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '1.5em',
  },
  label: {
    fontWeight: 700,
    marginRight: '.5em',
  },
  total: {
    fontSize: '1.5em',
  }
});

export default function ContactlessTotal({ totalInfo: {
  subtotal, tax, tipString, total,
}}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.labelsColumn}>
        <span className={classes.label}>subtotal:</span>
        <span className={classes.label}>tax</span>
        <span className={classes.label}>tip:</span>
        <span className={classes.total}>total:</span>
      </div>
      <div className={classes.infoColumn}>
        <span>{subtotal}</span>
        <span>{tax}</span>
        <span>{tipString}</span>
        <span className={classes.total}>{total}</span>
      </div>
    </div>
  );
}
