import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { darkGrey, borderGrey } from '../../../style/colors'

const useStyles = makeStyles((theme) => ({
  root: () => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${borderGrey}`,
  }),
  date: {
    ...theme.customType.smallText,
    ...theme.customType.semiBold,
    marginBottom: theme.spacing(1),
    color: darkGrey,
  },
  restaurantName: {
    marginBottom: theme.spacing(1),
  },
  guestCount: {
    ...theme.customType.mediumText,
  }
}));

const formatDate = (date) => {
  let options = { month: 'long', day: 'numeric', timeZone: 'EST' };
  const dateObj = new Date(date);
  return dateObj.toLocaleString('en-US', options)
}

const Place = ({ restaurantName, date, guestCount, checkId }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography className={classes.date}>{formatDate(date)}</Typography>
      <Typography variant="h4" className={classes.restaurantName}>{restaurantName ?? 'Restaurant'}</Typography>
      { guestCount > 0 && <Typography className={classes.guestCount}>{`splyt with ${guestCount} ${guestCount > 1 ? 'others' : 'other'}`}</Typography> }    
    </div>
  )
}

export const placeShape = {
  restaurantName: PropTypes.string,
  date: PropTypes.number,
  guestCount: PropTypes.number,
  checkId: PropTypes.string,
};

Place.propTypes = placeShape

export default Place