import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import { submittedPaymentInfo } from '../../API/mixpanel';

export default function PaymentDialog({
  open, 
  paymentValues: {
    cashApp,
    venmo,
  },
  setPaymentInfo,
  submitFunc,
  onClose,
}) {

  const handleSubmit = (cashApp, venmo) => {
    submittedPaymentInfo({ venmo, cashApp });
    submitFunc();
  }

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">How will people pay you back?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { !venmo.length || !cashApp.length ? 'Enter your Venmo I.D. or Cashtag.' : 'Please confirm your payment information' }
          </DialogContentText>
          <TextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            margin="dense"
            id="venmoInput"
            label="Venmo I.D."
            type="email"
            fullWidth
            value={venmo}
            onChange={(e) => setPaymentInfo('venmo', e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">@</InputAdornment>,
              autoComplete: 'off',
            }}
          />
          <TextField
            margin="dense"
            id="cashAppInput"
            label="$Cashtag"
            type="email"
            fullWidth
            value={cashApp}
            onChange={(e) => setPaymentInfo('cashApp', e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              autoComplete: 'off',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit(cashApp, venmo)} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const { bool, shape, string, func } = PropTypes;

PaymentDialog.propTypes = {
  open: bool,
  paymentValues: shape({
    cashApp: string,
    venmo: string,
  }),
  setPaymentInfo: func,
  submitFunc: func,
  onClose: func,
};