import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import DrawerModal from './DrawerModal';
import StepText from './StepText';
import { darkGreen } from '../../style/colors';
import LightBlueButton from '../ThemedComponents/ThemedButtons/LightBlueButton';

const propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

const useMainClasses = makeStyles(theme => ({
  title: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  desc: {
    ...theme.customType.mediumText,
    color: darkGreen,
    marginBottom: theme.spacing(4),
  },
  splytIcon: {
    margin: 'auto',
    display: 'block',
    // explicitly do not scale image with size
    width: '32px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const useStepTextStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: '1.5rem',
    flex: '0 0 15%',
  },
  title: {
    ...theme.customType.mediumText,
    ...theme.customType.semiBold,
  },
  desc: {
    ...theme.customType.mediumText,
    color: darkGreen,
  },
}));

const InstructionModal = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  const mainClasses = useMainClasses();
  const stepClasses = useStepTextStyles();

  return (
    <DrawerModal
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <img className={mainClasses.splytIcon} src="/splyt-secondary-icon.png" alt="Splyt Logo" />
      <Typography align="center" className={mainClasses.title} component="h2">
        The fastest way to splyt the restaurant bill.
      </Typography>
      <Typography align="center" className={mainClasses.desc}>
        Wanna create a splyt? Here&apos;s how it works:
      </Typography>
    
      <StepText
        classes={stepClasses}
        icon="📸"
        title="Upload"
        titleComponent="h3"
        description="Take a picture of your receipt"
      />
      <StepText
        classes={stepClasses}
        icon="🕵️‍♀️"
        title="Review"
        titleComponent="h3"
        description="Make sure we read the items correctly"
      />
      <StepText
        classes={stepClasses}
        icon="🎉"
        title="Invite your party"
        titleComponent="h3"
        description="Invite your friends to select their items &amp; pay you back. They don't have to have an account or download the app"
      />
    
      <LightBlueButton
        text="Get Started"
        handleClick={onClose}
      />
    </DrawerModal>
  );
};

InstructionModal.propTypes = propTypes;
export default InstructionModal;
