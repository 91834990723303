import React from 'react';
import PropTypes from 'prop-types';
import {
  SwipeableDrawer,
  Box,
  makeStyles,
} from '@material-ui/core';
import { lightGrey } from '../../style/colors';

const propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

const useRootStyles = makeStyles(theme => ({
  paper: {
    // explicitly set with px to not scale with zoom
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
}));

const useMainClasses = makeStyles(theme => ({
  wrapper: {
    '&::before': {
      content: '" "',
      position: 'absolute',
      top: theme.spacing(2),
      // explicitly set with px to not scale with zoom
      borderRadius: '2px',
      height: '4px',
      width: '32px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: lightGrey,
    }
  },
}));

const DrawerModal = ({
  isOpen,
  onClose,
  onOpen,
  children,
}) => {
  const rootClasses = useRootStyles();
  const mainClasses = useMainClasses();

  return (
    <SwipeableDrawer
      classes={rootClasses}
      disableSwipeToOpen
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box px={2} py={6} className={mainClasses.wrapper}>
        { children }
      </Box>
    </SwipeableDrawer>
  );
};

DrawerModal.propTypes = propTypes;
export default DrawerModal;
