import React, { useContext, useMemo, useCallback } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { CircularProgress, Button, makeStyles } from '@material-ui/core';
import PlacesList from '../components/placesComponents/PlacesList/PlacesList';
import AuthContext from "../context/AuthContext";
import SummaryModal, { useSummaryModalWithHistory } from '../components/SummaryModal/SummaryModal';
import { getUserSplyts } from "../API/splytV2";
import { getCheck } from '../API/checks';
import * as analytics from '../API/mixpanel';
import EmptyPlacesList from '../components/placesComponents/EmptyPlacesList/EmptyPlacesList';
import LightBlueButton from '../components/ThemedComponents/ThemedButtons/LightBlueButton';

const useStyles = makeStyles(theme => ({
  floatBottom: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    padding: [[ theme.spacing(4), theme.spacing(3) ]],
    textAlign: 'center',
  },
}));

const useCreateSplytStyles = makeStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '3.5',
    borderRadius: '15px', // explicit
    fontWeight: 700,
    padding: [[ 0, theme.spacing(4) ]],
  }
}));

const PlacesContainer = () => {
  const classes = useStyles();
  const createSplytClasses = useCreateSplytStyles();

  const { userId } = useParams();
  const [ data, setData ] = useState([]);
  const [ isFetching, setIsFetching ] = useState(false);
  const [ checkData, setCheckData ] = useState();
  const { isSummaryOpen, openSummaryModal, closeSummaryModal } = useSummaryModalWithHistory();
  const [ isCheckLoading, setIsCheckLoading ] = useState(false);

  const { myId, profile } = useContext(AuthContext);

  useEffect(function loadSplyts() {
    setIsFetching(true);
    analytics.landedOnSummaryPage();
    getUserSplyts(userId).then(splyts => {
      setData(splyts);
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setIsFetching(false);
    });
  }, [userId, setIsFetching, setData]);

  const showPlaceSummary = useCallback((checkId) => {
    if (locationState?.checkId !== checkId) {
      setCheckData({ checkId, restaurantInfo: data?.[checkId]?.checkMetaData?.restaurantInfo });
      setIsCheckLoading(true);
      openSummaryModal({ id: checkId });
      getCheck(checkId)
        .then(data => setCheckData({ checkId, ...data }))
        .catch(err => {
          console.error(`Failed to open check`, err);
          closeSummaryModal();
        })
        .finally(() => setIsCheckLoading(false));
    } else {
      openSummaryModal({ id: checkId });
    }
    // Does not recognize optional chaining
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkData?.checkId, closeSummaryModal, data, openSummaryModal]);

  const { state: locationState } = useLocation();

  useEffect(() => {
    if (isSummaryOpen && locationState?.id !== checkData?.checkId && locationState?.id) showPlaceSummary(locationState.id);
    // Does not recognize optional chaining
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState?.id, isSummaryOpen, showPlaceSummary, checkData?.checkId]);

  const [ subTotal, tipDollarValue, myTaxAmount, myTotal ] = useMemo(() => {
    const { totalInfo, itemIds, itemsById, selections } = checkData || {};
    if (totalInfo && itemIds) {
      const myItemsIds = itemIds?.filter(itemId => (
        (selections?.[itemId] ?? []).includes(myId)
      )) ?? [];

      const mySelections = myItemsIds.reduce((agg, itemId) => {
        if( selections[itemId].includes(myId)) {
          const item = itemsById[itemId];
          const { price } = item;
          let extrasTotal = 0;
          const chargeIds = item?.extras?.chargeIds ?? null;

          if ( !!chargeIds && chargeIds.length > 0 ) {
            const chargesById = item.extras.chargesById;
            extrasTotal = chargeIds.reduce((acc, chargeId) => {
              const chargePriceString = chargesById[chargeId].chargePrice;
              const chargePrice = Number(chargePriceString)
              return acc + chargePrice;
            }, 0)
          }

          const totalPriceString = (Number(price) + extrasTotal).toString();

          return [
            ...agg,
            {
              itemId,
              price: totalPriceString,
              numberOfPeopleWhoSelected: selections[itemId].length,
            }
          ]
        }
        return agg;
      }, []);

      let subtotal = mySelections ?
        mySelections.reduce((total, {price, numberOfPeopleWhoSelected}) => {
          return total + ( (Number(price)) /numberOfPeopleWhoSelected );
        }, 0)
        : null;

      subtotal = subtotal ? subtotal.toString() : '000';
      
      const { subtotal: sub, tip, tax } = totalInfo;
      const subInt = Number(sub);
      const tipInt = Number(tip);
      const mySubInt = Number(subtotal)
      const subPercent = tipInt / subInt;
      const myTip = mySubInt < 0 ? '000' : (subPercent * mySubInt).toFixed(2).toString();
      
      const taxInt = Number(tax);
      const taxPercent = taxInt / subInt;
      const myTaxTotal = mySubInt < 0 ? '000' : Math.round((taxPercent * mySubInt)).toString();

      let myTotal = Number(subtotal) < 0 ? '000' : Number(subtotal) + Number(myTip) + Number(myTaxTotal);
      myTotal = (myTotal / 100).toFixed(2).toString();

      return [ subtotal, myTip, myTaxTotal, myTotal ];
    } else {
      return [ '000', '000', '000', '0.00' ];
    }
  }, [checkData, myId]);
  
  const sortedPlaces = data.sort((a, b) => b.createDate - a.createDate);

  return (
    <div>
      {isFetching
        ? (
          <div style={{ width: '100%', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CircularProgress />
          </div>
        ) : ( data && (
          <>
            { isFetching ? null : sortedPlaces.length > 0 ? (
              <PlacesList
                data={sortedPlaces}
                onPlaceClick={(checkId) => {
                  showPlaceSummary(checkId);
                  analytics.clickedPreviousSplyt();
                }}
              />
            ) : (
              <EmptyPlacesList />
            )}
            <div className={classes.floatBottom}>
              <LightBlueButton
                fullWidth={false}
                classes={createSplytClasses}
                component={Link}
                to="/upload"
                text="+ Create splyt"
              />
            </div>
            <SummaryModal
              isOpen={isSummaryOpen}
              onClose={closeSummaryModal}
              isLoading={isCheckLoading}
              restaurantName={checkData?.restaurantInfo?.restaurantName}
              itemIds={checkData?.itemIds}
              itemsById={checkData?.itemsById}
              selections={checkData?.selections}
              myId={myId}
              guests={checkData?.guests}
              checkTipDollarValue={checkData?.totalInfo?.tip}
              tipDollarValue={tipDollarValue}
              checkTax={checkData?.totalInfo?.tax}
              tax={myTaxAmount}
              totalInfo={checkData?.totalInfo}
              subTotal={subTotal}
              countdownEndDate={profile?.countdownEndDate ?? null}
              tipPercent={checkData?.totalInfo?.tipPercent}
              checkSubTotal={checkData?.totalInfo?.subtotal}
              checkTotal={checkData?.totalInfo?.total}
              total={myTotal}
              showFullCheckDetails={checkData?.moderatorId === myId}
              isShowingSuggestions={checkData?.isShowingSuggestions}
              action={!isCheckLoading && isSummaryOpen && (
                <Button
                  fullWidth
                  component={Link}
                  to={`/splyt/ocr/${checkData.checkId}`}
                  variant="contained"
                  color="primary"
                >
                  View More
                </Button>
              )}
            />
          </>
        ))}
    </div>
  )
};

export default PlacesContainer