import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { isFunction } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { basicPlurify } from '../../utils';
import { darkGreen, lightGrey, primaryBorder } from '../../style/colors';
import { moneyType } from '../../utils/propTypesShapes';
import SummaryItemList from '../ocrComponents/ItemsList/SummaryItemList';
import BaseLoadingMask from '../loadingMasks/BaseLoadingMask';
import ItemLoadingMask from '../loadingMasks/ItemLoadingMask';
import ScreenReaderLoadingIndicator from '../loadingMasks/ScreenReaderLoadingIndicator';
import TotalItem from './TotalItem/TotalItem';
import SplytDownloadBanner from '../SplytDownloadBanner';


const propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  restaurantName: PropTypes.string,
  itemIds: PropTypes.arrayOf(PropTypes.string),
  itemsById: PropTypes.object,
  // map of selections and who selected it
  selections: PropTypes.object,
  myId: PropTypes.string,
  guests: PropTypes.object,
  checkTipDollarValue: moneyType,
  tipDollarValue: moneyType,
  tipPercent: moneyType,
  checkTax: moneyType,
  tax: moneyType,
  checkSubTotal: moneyType,
  subTotal: moneyType,
  checkTotal: moneyType,
  total: moneyType,
  action: PropTypes.node,
  additionalContent: PropTypes.bool,
  secondaryHeader: PropTypes.node,
  showFullCheckDetails: PropTypes.bool,
  myTotal: PropTypes.string,
  myPercentageOfSubtotal: PropTypes.string,
  itemizedFeesAndDiscounts: PropTypes.arrayOf(PropTypes.object),
  totalInfo: PropTypes.object,
  isShowingSuggestions: PropTypes.bool,
  countdownEndDate: PropTypes.number || null,
};

const TRANSITION_PROPS = { direction: 'up' };
const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: '.2em',
    top: '.2em',
    color: 'grey',
    zIndex: 100,
  },
  subtitle: {
    color: darkGreen,
    marginBottom: theme.spacing(1),
  },
  backgroundBlock: {
    backgroundColor: lightGrey,
    border: primaryBorder,
    height: theme.spacing(1),
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  totalsTitle: {
    borderBottom: primaryBorder,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  grandTotal: {
    ...theme.customType.mediumText,
    fontWeight: 900,
  },
  actions: {
    borderTop: primaryBorder,
    display: 'flex',
    justifyContent: 'stretch',
    boxShadow: '0px -10px 20px rgb(0 0 0 / 15%)',
  },
  checkout: {
    flex: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
}));

const useLoadingClasses = makeStyles(theme => ({
  titleSize: {
    width: '50%',
    height: '1.5rem',
  },
  normalTextSize: {
    width: '30%',
    height: '1rem',
    marginBottom: theme.spacing(1),
  },
  item: {
    borderTop: primaryBorder,
    borderBottom: primaryBorder,
    marginBottom: theme.spacing(2),
  },
}));

const SummaryModal = ({
  isOpen,
  myTotal,
  myPercentageOfSubtotal,
  itemizedFeesAndDiscounts,
  totalInfo,
  isLoading,
  onClose,
  restaurantName,
  itemIds,
  itemsById,
  guests,
  selections,
  myId,
  checkSubTotal,
  subTotal,
  checkTipDollarValue,
  tipDollarValue,
  tipPercent,
  checkTax,
  tax,
  additionalContent,
  action,
  checkTotal,
  total,
  isShowingSuggestions,
  secondaryHeader,
  showFullCheckDetails,
  countdownEndDate,
}) => {
  const classes = useStyles();
  const loadingClasses = useLoadingClasses();

  const myItemsIds = itemIds?.filter(itemId => (
    (selections?.[itemId] ?? []).includes(myId)
  )) ?? [];

  const tipPercentValue = useMemo(() => {
    return isNaN(Number(totalInfo?.tipPercent))
      ? +((Number(totalInfo?.tip) / Number(totalInfo?.subtotal?? '0') * 100).toFixed(0))
      : Number(totalInfo.tipPercent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalInfo?.subtotal, totalInfo?.tip, totalInfo?.tipPercent])


  /* calculating here to populate old checks
    this can be removed at some point in the future
  */
  const calculatedCheckTotal = useMemo(() => {
    return totalInfo?.subtotal ? (Number(totalInfo.subtotal) 
        + Number(totalInfo.tax)
        + Number(totalInfo.tip)
        + Number(totalInfo?.fees?.feeAndDiscountTotal ?? 0)).toString() : '0';
  }, [totalInfo]);

  const myRows = [
    ['Subtotal', subTotal],
    ['Tax', tax],
    [`Tip (${tipPercentValue}%)`, tipDollarValue],
  ];
  if (itemizedFeesAndDiscounts && itemizedFeesAndDiscounts?.length > 0) {
    for (let i = 0; i < itemizedFeesAndDiscounts.length; i++) {
      const myPortionOfItemizedFeeOrDiscount = (Number(itemizedFeesAndDiscounts[i].value) * myPercentageOfSubtotal).toFixed(2).toString();
      const row = [itemizedFeesAndDiscounts[i].label, myPortionOfItemizedFeeOrDiscount];
      myRows.push(row);
    }
  }
  myRows.push(['Your Total', myTotal]);

  const checkTotalRows = [
    ['Subtotal', checkSubTotal],
    ['Tax', checkTax],
    [`Tip (${tipPercentValue}%)`, checkTipDollarValue],
  ];
  if (itemizedFeesAndDiscounts && itemizedFeesAndDiscounts?.length > 0) {
    for (let i = 0; i < itemizedFeesAndDiscounts.length; i++) {
      const row = [itemizedFeesAndDiscounts[i].label, itemizedFeesAndDiscounts[i].value];
      checkTotalRows.push(row);
    }
  }
  checkTotalRows.push(['Check Grand Total', calculatedCheckTotal]);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Slide}
      TransitionProps={TRANSITION_PROPS}
      aria-live="polite"
      aria-busy={isLoading}
      // style={{ width: '100vw' }}
    >
      { isLoading && <ScreenReaderLoadingIndicator /> }

      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle disableTypography>
        <Typography variant="h4" component="h2" align="center">{ restaurantName }</Typography>
      </DialogTitle>
      <SplytDownloadBanner countdownEndDate={countdownEndDate}/>

      <DialogContent className={classes.content}>
        { secondaryHeader }
        { isLoading ? (
          <>
            <BaseLoadingMask classes={{ root: loadingClasses.titleSize }} />
            <BaseLoadingMask classes={{ root: loadingClasses.normalTextSize }} />
            <ItemLoadingMask classes={{ root: loadingClasses.item }} />
          </>
        ) : (
          <>
            <Typography variant="h4" component="h3">{ guests?.[myId]?.name } (You)</Typography>
            <Typography variant="subtitle2" className={classes.subtitle}>{ myItemsIds.length } { basicPlurify('item', myItemsIds.length) }</Typography>
            <SummaryItemList
              classes={{ list: classes.list }}
              myItemsIds={myItemsIds}
              itemsById={itemsById}
              guests={guests}
              selectedItems={selections}
              myId={myId}
              isShowingSuggestions={isShowingSuggestions}
            />
          </>
        )}
        
        <div className={classes.backgroundBlock}/>
        
        <Typography className={classes.totalsTitle} variant="h4" component="h3">Totals</Typography>
        { showFullCheckDetails && (
          <>
            <Typography className={classes.totalsTitle} variant="subtitle1" component="h4">Check Totals</Typography>
            { checkTotalRows.map(([label, value]) => (
              <TotalItem key={label} isLoading={isLoading} label={label} value={value} />
            ))}

            {/* no need for an additional header for the next section if only one section is showing */}
            <Typography className={classes.totalsTitle} variant="subtitle1" component="h4">Your Totals</Typography>
          </>
        )}
        { myRows.map(([label, value]) => (
          <TotalItem key={label} isLoading={isLoading} label={label} value={value} />
        ))}
        { additionalContent }
      </DialogContent>
      { action && (
        <DialogActions classes={{ root: classes.actions}}>
          { action }
        </DialogActions>
      )}
    </Dialog>
  );
};


export function useSummaryModalWithHistory(initiallyOpen = false, onOpen, onClose) {
  const [ isSummaryOpen, setIsSummaryOpen ] = useState(initiallyOpen);
  const location = useLocation();
  const { state, ...rest } = location;

  useEffect(() => {
    setIsSummaryOpen(state?.isSummaryOpen ?? initiallyOpen);
  }, [initiallyOpen, state]);

  const history = useHistory();
  const openSummaryModal = useCallback((otherState) => {
    history.push(rest, { ...(otherState || {}), isSummaryOpen: true });
    isFunction(onOpen) && onOpen();
  }, [onOpen, history, rest]);

  const closeSummaryModal = useCallback(() => {
    history.push(rest, { isSummaryOpen: false });
    isFunction(onClose) && onClose();
  }, [onClose, history, rest]);

  return { isSummaryOpen, openSummaryModal, closeSummaryModal };
}

SummaryModal.propTypes = propTypes;
export default SummaryModal;
