import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Button } from '@material-ui/core';
import { darkGreen } from '../../../style/colors';

const propTypes = {
  total: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: '.875rem',
    lineHeight: '1.5rem',
  },
  button: {
    width: '100%',
    backgroundColor: 'green',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: '1.5rem',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: theme.spacing(2),
    backgroundColor: darkGreen,
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(2),
    fontSize: '1rem',
  },
}));

const CheckInfoButton = ({
  isModerator,
  receipientName,
  numSelected,
  total,
  onClick,
  ...buttonProps
}) => {
  const classes = useStyles();

  // let selectedIcon;
  // if (numSelected) {
  //   selectedIcon = (
  //     <div className={classes.icon}>{ numSelected }</div>
  //   );
  // }

  return (
    <Button
      variant="contained"
      color="primary"
      className="checkInfoButton"
      classes={{
        root: classes.button,
      }}
      onClick={onClick}>
      <Typography className={classes.subtitle} component="div">
        See check total, tax and tip
      </Typography>
    </Button>
  );
};

CheckInfoButton.propTypes = propTypes;
export default CheckInfoButton;
