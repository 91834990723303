import React, { useState, useCallback } from 'react';
import { TextField, Divider, Button, IconButton } from '@material-ui/core';
import firebase from '../services/firebase';
import { v4 as uuidv4 } from "uuid";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
      '& > *': {
          marginBottom: '1em',
      },
      '& .MuiDivider-root' : {
          backgroundColor: 'black'
      },
  },
  buttonRow: {
      display: 'flex',
      justifyContent: 'space-around',
  },
  submitButton: {
      backgroundColor: 'green',
  },
  clearRow: {
      display: 'flex',
      marginBottom: 0,
      justifyContent: 'flex-end',
  },
  clearButton: {
      color: 'red',
  },

});

const OrderEntry = () => {
    const classes = useStyles();
    const [ check, setCheck ] = useState({
        restaurantId: uuidv4(),
        checkId: uuidv4(),
        restaurantName: '',
        tax: undefined,
        didCheckout: false,
        mandatoryTip: undefined,
        serviceCharge: undefined,
        itemsById: {},
        itemIds: [],
        currency: "USD",
        guests : {
            "initial" : "Initial"
        },
        selections : {
         "initial" : "initial"
        },
        idsReadyToCheckout: ["initial"],
    })
    const [error, setError ] = useState();
    const [success, setSuccess] = useState(false);

    const setField = useCallback((e, field, itemId) => {
        const { target: { value } } = e;
        if(field === 'name' || field === 'price' || field === 'qty') {
            setCheck(state => {
                    return {
                        ...state,
                        itemsById: {
                            ...state.itemsById,
                            [itemId]: {
                                ...state.itemsById[itemId],
                                [field]: value,
                            }
                        }
                    }
                }
            )
        } else {
            setCheck(state => ({...state, [field]: value}))
        }
    }, [setCheck ])

    const removeItem = useCallback((id) => {
        setCheck(state => {
            const filteredIds = state.itemIds.filter( itemId => itemId !==id);
            return {
                ...state,
                itemsById: filteredIds.reduce((result, id) => ({
                    ...result,
                    [id]: {
                        ...state.itemsById[id]
                    }
                }), {}),
                itemIds: filteredIds,
            }

        })
     },[setCheck])

    const addItem = useCallback(() => {
        const newId = uuidv4();
        setCheck(state => ({
            ...state,
            itemsById: {
                ...state.itemsById,
                [newId]: {
                    price: '',
                    name: '',
                    qty: '',
                }
            },
            itemIds: [ ...state.itemIds, newId ]
        }))
    }, [setCheck])

    const renderItems = useCallback(() => {
        return check.itemIds.length > 0 && check.itemIds.map( id => {
             return (
                 <React.Fragment key={id}>
                     <div className={classes.clearRow}>
                        <IconButton aria-label="delete" className={classes.clearButton} onClick={() => removeItem(id)}>
                            <ClearIcon />
                        </IconButton>
                     </div>
                     <TextField id="outlined-basic" fullWidth label="Item Description" variant="outlined" onChange={(e) => setField(e, 'name', id)} />
                     <TextField id="outlined-basic" label="Price" variant="outlined" onChange={(e) => setField(e, 'price', id)} />
                     <TextField id="outlined-basic" label="Qty" variant="outlined" onChange={(e) => setField(e, 'qty', id)} />

                     <Divider variant="middle" />
                 </React.Fragment>
             )
         })
     }, [ check.itemIds, setField, classes, removeItem])

    const handleSubmit = useCallback(() => {
        const items = check.itemIds.reduce((resultObj, id) => {
            for( let i = 0; i < Number(check.itemsById[id].qty); i++) {
                const uid = uuidv4();
                resultObj = {
                    itemIds: [
                        ...resultObj.itemIds,
                        uid,
                    ],
                    itemsById: {
                        ...resultObj.itemsById,
                        [uid]: {
                            ...check.itemsById[id],
                            qty: 1,
                        }
                    },
                }
            }
            return resultObj;
        }, {itemIds: [], itemsById: {}})
        firebase.database().ref('checksById/' + check.checkId).set({...check, ...items}, (error) => {
            if(error) {
                setError(error)
            } else {
                setSuccess(true);
            }
        });
    }, [check])

    return (
        <>
         { success ? (
             <h5> successfully uploaded.  See the check at http://splyt-9f6cc.web.app/{check.checkId}</h5>
         ) : (
             <form className={classes.root} noValidate autoComplete="off">
             <h3>Order Entry</h3>
             <h3> DO NOT INCLUDE DECIMALS</h3>
             <p>$10.00 would be 1000</p>
             <h5>Restaurant Info</h5>
             <TextField id="outlined-basic" fullWidth label="Restaurant Name" variant="outlined" value={check.restaurantName || ''} onChange={(e) => setField(e, 'restaurantName')} />
             <Divider variant="middle" />
             <TextField id="outlined-basic" label="Tax, last 3 are decimals" variant="outlined" value={check.tax || ''} onChange={(e) => setField(e, 'tax')} />
             <Divider variant="middle" />
             <TextField id="outlined-basic" label="Mandatory tip" variant="outlined" value={check.mandatoryTip || ''} onChange={(e) => setField(e, 'mandatoryTip')} />
             <Divider variant="middle" />
             <TextField id="outlined-basic" label="Service Charge" variant="outlined" value={check.serviceCharge || ''} onChange={(e) => setField(e, 'serviceCharge')} />
             <Divider variant="middle" />
             <h5> check Items</h5>
             {renderItems()}
             
            <div className={classes.buttonRow}>
             <Button
                     variant="contained"
                     color="primary"
                     className={classes.button}
                     startIcon={<AddIcon />}
                     onClick={addItem}
                 >
                     Add Item
                 </Button>
 
                 <Button
                     variant="contained"
                     className={classes.submitButton}
                     onClick={handleSubmit}
                 >
                     Submit
                 </Button>
             
            </div>
         </form>
         )}
        </>
    )
}

export default OrderEntry
