import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import { darkGreen, white, purple } from '../../../style/colors'
import PropTypes from 'prop-types';
import firebase from '../../../services/firebase';

const useStyles = makeStyles( theme => ({
  root: ({ containerSize }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: `${containerSize}rem`,
    height: `${containerSize}rem`,
    justifyContent: 'center',
    whiteSpace: 'nowrap'
  }),
  avatar: props => ({
    borderRadius: '50%',
    width: `${props.avatarSize}rem`,
    height: `${props.avatarSize}rem`,
    border: props.isSelected ? `3px solid ${purple}` : '',
    backgroundImage: props.isLoading ? undefined: `url(${props.url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: props.size,
    backgroundClip: 'border-box',
    backgroundColor: white,
  }),
  foo: {

  },
  name: ({ containerSize, isSelected }) => ({
    color: `${isSelected ? white : darkGreen}`,
    ...theme.customType.smallText,
    width: `${containerSize}rem`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: `ellipsis`,
    textAlign: 'center',
    // marginRight: '-5px',
  })
}));

const Avatar = ({
  id,
  name = 'guest',
  emoji = 'banana',
  showText = true,
  avatarSize,
  containerSize,
  isSelected = false,
  isContact = false,
}) => { 
  const [ useFallback, setUseFallback ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [ profileImageUrl, setProfileImageUrl ] = useState();
  useEffect(function getProfileImageUrl() {
    if (!id) {
      setIsLoading(false);
      setUseFallback(true);
      return;
    }
    const path = `profilePics/${id}`;
    firebase.storage().ref(path).getDownloadURL().then((url) => {
      setProfileImageUrl(url);
      setIsLoading(false);
    }).catch(err => {
      console.error(err);
      setUseFallback(true);
      setIsLoading(false);
    });
  }, [id]);

  const classes = useStyles({
    url: useFallback ? `/emojis/${emoji}.png` : profileImageUrl,
    isLoading,
    avatarSize,
    containerSize,
    isSelected,
    opacity: isContact ? .5 : 1,
    size: useFallback ? '65%' : 'cover',
  }); 
  return (
    <div className={classes.root}>
      <span className={classes.avatar} />
      {showText && (
        <Typography className={classes.name}>{name}</Typography>
      )}
    </div>
  )
}

const { string, bool, number } = PropTypes;

Avatar.propTypes = {
  id: string,
  name: string,
  emoji: string,
  showText: bool,
  avatarSize: number,
  containerSize: number,
  isSelected: bool,
  isContact: bool,
}

export default Avatar;