import React, { useMemo } from "react";
import ItemsList from './ItemsList/ItemsListFactory';
import InstructionsHeader from '../InstructionsHeader';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
  },
  instructions: {
    padding: [[ 10, 5 ]],
    fontStyle: 'italic',
    borderTop: '1px solid gray',
    '& div:first-of-type': {
      marginBottom: '.5em',
    }
  },
  sticky: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'white',
    padding: [[ 0,'1em' ]],
    border: '1px solid black',
    paddingTop: '0.5em'
  },
  stickyTop: {
    minHeight: '.5em',
    width: '100%',
    opacity: '.5',
    clear: 'both',
  },
  checkTotal: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1em',
  },
  subTotal: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em',
  },
  tax: {
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em',
  },
  tip:{
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.2em',
  },
  total:{
    marginBottom: '1em',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '1.4em',
    fontWeight: 700,
  },
  tipLabel: {
    fontWeight: 600,
    marginBottom: '.3em',
  },
  tipButtons: {
    marginBottom: '1em',
    display: 'flex',
    '& .MuiButton-root': {
      'flex-grow': 1,
    }
  },
  active: {
    'background-color': 'blue',
  },
  closeButton: {
    display: 'flex',
    '& .MuiButton-root': {
      'flex-grow': 1,
    }
  },
  paddingContainer: {
    padding: [[ 0,'1em' ]],
  },
});


export default function Receipt({
  data: { itemsById, itemIds, restaurantName, didCheckout = false },
  tax,
  myId,
  id,
  total,
  myItemsIds,
  checkSubTotal,
  handleClickItem,
  isTotalMet,
  selectedItems,
  guests,
  subTotal,
  handleSelectTipPercent,
  setisFetching,
  handleClickCustomTip,
  handleInputCustomTip,
  tipDollarValue,
  tip: { isCustom, amount: tipAmount },
  handleReadyToCheckoutClick,
  numberOfPeopleStillSelecting,
  amIReadyToCheckOut,
  itemsNotSelected,
  checkOutLoading,
  checkoutError,
  handleCheckout,
  itemsAlreadyPaidFor,
  isSuggestionsShowing,
  isModerator,
  tipFromCheck,
  showSubtotals = true,
}) {
  const classes = useStyles();

  const sharedArray = useMemo(() => {
    return myItemsIds.filter(id =>
      selectedItems[id].length > 1
    );
  }, [selectedItems, myItemsIds]);

  return (
    <div className={classes.root}>
      <div>
        <InstructionsHeader didCheckout={didCheckout} />
        {itemIds.length && (
          <ItemsList
            ids={itemIds}
            selectedItems={selectedItems}
            guests={guests}
            itemsById={itemsById}
            myItemsIds={myItemsIds}
            handleClickItem={handleClickItem}
            myId={myId}
            sharedArray={sharedArray}
            didCheckout={didCheckout}
            isSuggestionsShowing={isSuggestionsShowing}
            itemsAlreadyPaidFor={itemsAlreadyPaidFor}
            showLockedItem={false}
          /> 
        )}
        <div className={classes.stickyTop}></div>
        { showSubtotals && (
          <div className={classes.sticky}>
            <div className={classes.checkTotal}>
              <span>check subtotal:</span><span>${`${(Number(checkSubTotal) / 100 ).toFixed(2)}`}</span>
            </div>
            {isModerator && (<div className={classes.checkTotal}>
              <span>total tip:</span><span>${`${(Number(tipFromCheck) / 100 ).toFixed(2)}`}</span>
            </div>)}
            <div className={clsx(classes.subTotal)}>
              <span>your subtotal:</span><span>${`${(Number(subTotal) / 100 ).toFixed(2)}`}</span>
            </div> 
          </div>
        )}
      </div>
      { showSubtotals && (
        <div className={classes.paddingContainer}>
          <div className={classes.tax}>
            <span>tax:</span><span>${`${(Number(tax) / 100 ).toFixed(2)}`}</span>
          </div>
          <div className={classes.tip}>
            <span>tip:</span><span>${`${(Number(tipDollarValue) / 100 ).toFixed(2)}`}</span>
          </div>
          {/* <button onClick={handleClickCustomTip}>custom</button>
          {isCustom && <input value={tipAmount} onChange={handleInputCustomTip} />} */}
          <div className={classes.total}>
            <span>Total:</span><span>${total}</span>
          </div>
          <div >{checkoutError}</div>
        </div>
      )}
    </div>
  );
}

Receipt.propTypes = {
  selectedItems: PropTypes.shape({
    id: PropTypes.arrayOf(PropTypes.string),
  }),
};
