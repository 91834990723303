import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import { formatPriceStringForDisplay } from '../VerifyContainer';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '1em',
    paddingBottom: '1em',
    background: 'linear-gradient(78deg, #e0d2fb 10%, #e4eeb7 99%)',
    maxWidth: '500px',
  },
  marginBottom: {
    marginBottom: '1em',
  },
  ital: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 800,
  }
});

const TopSummary = ({numberOfItems, total}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.marginBottom}><span className={clsx(classes.ital, classes.bold)}>splyt</span> detected <span className={clsx(classes.bold)}>{numberOfItems}</span> items</p>
      <p>total plus tax <span className={clsx(classes.bold)}>{formatPriceStringForDisplay(total)}</span></p>
    </div>
  )
}

export default TopSummary
