import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  instructions: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'start',
    padding: [[ 10, 5 ]],
    // borderTop: '1px solid gray',
  },
  tap: {
    fontWeight: '700',
    fontSize: '1.8em',
    marginBottom: '5px',
  },
  tapToChoose: {
    marginBottom: '15px',
    color: 'gray',
    fontSize: '0.9em',
    width: '100%',
    textAlign: 'center'
  },
  sub: {
    fontSize: '1em',
    fontStyle: 'italic',
    marginBottom: '10px',
  },
  allreadyCheckedOut: {
    fontSize: '1.5em',
    textAlign: 'center',
    padding: [[ 10, 5 ]],
    fontStyle: 'italic',
    borderTop: '1px solid gray',
    '& div:first-of-type': {
      marginBottom: '.5em',
    }
  },
  paddingContainer: {
    padding: [[ 0,'0.7em' ]],
  },
});

const InstructionsHeader = ({ didCheckout }) => {
  const classes = useStyles();
  return (
    <div className={classes.paddingContainer}>
      { didCheckout ? (
        <div className={classes.allreadyCheckedOut}>
          Your party has already checked out.  Please return to the payment screen.
        </div>
      ) : (
        <div className ={classes.instructions}>
          {/* <div className={classes.tap}>Select your items</div> */}
          <span className={classes.tapToChoose}>Tap all your items, including shared items.</span>
        </div>
      )}
    </div>
  )
}

export default InstructionsHeader;
